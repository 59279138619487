import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

import AdminCharts from './AdminCharts';
import AdminProPage from './AdminProPage';
import AdminConsPage from './AdminConsPage';
import AdminDownPage from './AdminDownPage';
import AdminGallery from './AdminGallery';

export default class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      categories: [{id:'testa'},{id:'testb'}], //Cookies.get('language') || 'EN'
      subcategories:[{id:'tests'}],
      products:[{id:'none'}],
      cons_categories: [{id:'testc'},{id:'testd'}],
      cons_subcategories:[{id:'teste'}],
      cons_products:[{id:'none'}],
      cons_productsku:[{id:'none'}],
      downloads:[{
        'id': 'Easystar',
        'display_en':true,
        'display_fr':true,
        'name_en': 'Easystar Dispenser Brochure',
        'name_fr': 'Brochure Easystar',
        'image_en': '../img/downloads/en/EasySTAR dispenser_FR_web 2019.jpg',
        'image_fr': '../img/downloads/en/EasySTAR dispenser_FR_web 2019.jpg',
        'pdf_en': '../downloads/EasySTAR dispenser_EN_web 2019.pdf',
        'pdf_fr': '../downloads/EasySTAR dispenser_FR_web 2019.pdf'
      }],
      galleryImages: [{id: 'none'}],
      freeze: false
      //cat_sel: ''
    };
    this._getProCats = this._getProCats.bind(this);
    this._getSubCats = this._getSubCats.bind(this);
    this._getProProducts = this._getProProducts.bind(this);
    this._getConsCats = this._getConsCats.bind(this);
    this._getConsSubCats = this._getConsSubCats.bind(this);
    this._getConsProducts = this._getConsProducts.bind(this);
    this._getConsProductsku = this._getConsProductsku.bind(this);
    this._getDownloads = this._getDownloads.bind(this);
    this._getGalleryImages = this._getGalleryImages.bind(this);
    this._ClickTab = this._ClickTab.bind(this);
    this._ClickConsTab = this._ClickConsTab.bind(this);
    this._ClickDownTab = this._ClickDownTab.bind(this);
    this._ClickGalleryTab = this._ClickGalleryTab.bind(this);
    this._getFreezeMode = this._getFreezeMode.bind(this);
    this._changeFreeze = this._changeFreeze.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this._getFreezeMode();
  }
  _getFreezeMode() {
    var self = this;
    console.log('Getting freeze_mode');
    $.get('/freeze_mode',function(data,status,xhr){
      console.log('/freeze_mode status: ' + status);
      self.setState({freeze: data.mode});
    },'json');
  }
  _changeFreeze() {
    var self = this;
    console.log('Setting Freeze Mode');

    var newmode = false;
    if (self.state.freeze === false){
      newmode = true;
    }

    var sendData = {
      mode: newmode
    }

   $.ajax({
      type: "POST",
      contentType: "application/json",
      url: '/freeze_mode',
      data: JSON.stringify(sendData),
      dataType : 'json',
      cache: false,
      timeout: 30000,
      success: function (data) {
          // alert("SUCCESS : " + data);
          self.setState({freeze: data.mode});
      },
      error: function (err) {
          alert("ERROR : " + JSON.stringify(err));
      }
    });

  }
  _getProCats(){
    var self = this;
    console.log('Getting Pro Category List');
    $.get('/data/procats',function(data,status,xhr){
      console.log('Pro Cat status: ' + status);
      if (data.length > 0) {
        self.setState({categories: data});
      }
    },'json');
  }

  _getSubCats(){
    var self = this;
    console.log('Getting Pro Sub Category List');
    $.get('/data/prosubcats',function(data,status,xhr){
      console.log('Pro SubCat status: ' + status);
      if (data.length > 0) {
        self.setState({subcategories: data});
      }
    },'json');
  }

  _getProProducts(){
    var self = this;
    console.log('Getting Pro Product List');
    $.get('/data/proproducts',function(data,status,xhr){
      console.log('Pro Product status: ' + status);
      if (data.length > 0) {
        self.setState({products: data});
      }
    },'json');
  }

  _getConsCats(){
    var self = this;
    console.log('Getting Cons Category List');
    $.get('/data/conscats',function(data,status,xhr){
      console.log('Cons Cat status: ' + status);
      if (data.length > 0) {
        self.setState({cons_categories: data});
      }
    },'json');
  }

  _getConsSubCats(){
    var self = this;
    console.log('Getting Cons Sub-Category List');
    $.get('/data/conssubcats',function(data,status,xhr){
      console.log('Cons Sub-Cat status: ' + status);
      if (data.length > 0) {
        self.setState({cons_subcategories: data});
      }
    },'json');
  }

  _getConsProducts(){
    var self = this;
    console.log('Getting Cons Product List');
    $.get('/data/consproducts',function(data,status,xhr){
      console.log('Cons Product status: ' + status);
      if (data.length > 0) {
        self.setState({cons_products: data});
      }
    },'json');
  }
  _getConsProductsku(){
    var self = this;
    console.log('Getting Cons Product SKU List');
    $.get('/data/consproductsku',function(data,status,xhr){
      console.log('Cons Product SKU status: ' + status);
      if (data.length > 0) {
        self.setState({cons_productsku: data});
      }
    },'json');
  }
  _getDownloads() {
    var self = this;
    console.log('Getting Downloads List');
    $.get('/data/downloads',function(data,status,xhr){
      console.log('Get Downloads status: ' + status);
      console.log('Downloads Data: ' + JSON.stringify(data));
      if (data.length > 0) {
        self.setState({downloads: data});
      }
    },'json');
  }
  _getGalleryImages() {
    var self = this;
    console.log('Getting Gallery List');
    $.get('/data/gallery',function(data, status, xhr){
      console.log('Get Gallery status: ' + status);
      console.log('Gallery Data: ' + JSON.stringify(data));
      if (data.length > 0) {
        console.log('Update Gallery Image List');
        self.setState({galleryImages: data});
      }
    },'json');
  }
  _ClickTab(){
    this._getProCats();
    this._getSubCats();
    this._getProProducts();
  }
  _ClickConsTab(){
    this._getConsCats();
    this._getConsSubCats();
    this._getConsProducts();
    this._getConsProductsku();
  }
  _ClickDownTab(){
    this._getDownloads();
  }
  _ClickGalleryTab(){
    // this._getGalleryImages();
  }
  render() {
    return (
      <div>
          <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         marginTop:'-170px'}}>
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>ADMIN</h1>
        </div>
        <div class='d-flex justify-content-around'>
          <form method='post' action='/auth/logout'>
              <button type='submit' class='btn btn-primary'>Log Out</button>
          </form>
          <a class='btn btn-primary' href='/admin/password'>Change Pass</a>
          <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" className="custom-control-input" id="freezeWarn" onClick={this._changeFreeze} checked={this.state.freeze === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="freezeWarn">Freeze Warning</label>
          </div>
        </div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="charts-tab" data-toggle="tab" href="#charts" role="tab" aria-controls="charts" aria-selected="true">Charts</a>
            </li>
            <li className="nav-item" onClick={this._ClickTab}>
                <a className="nav-link" id="professional-tab" data-toggle="tab" href="#professional" role="tab" aria-controls="professional" aria-selected="false">Professional</a>
            </li>
            <li className="nav-item" onClick={this._ClickConsTab}>
                <a className="nav-link" id="consumers-tab" data-toggle="tab" href="#consumers" role="tab" aria-controls="consumer" aria-selected="false">Consumer</a>
            </li>
            <li className="nav-item" onClick={this._ClickDownTab}>
                <a className="nav-link" id="downloads-tab" data-toggle="tab" href="#downloads" role="tab" aria-controls="downloads" aria-selected="false">Downloads</a>
            </li>
            <li className="nav-item" onClick={this._ClickGalleryTab}>
                <a className="nav-link" id="gallery-tab" data-toggle="tab" href="#gallery" role="tab" aria-controls="gallery" aria-selected="false">Gallery</a>
            </li>
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="charts" role="tabpanel" aria-labelledby="charts-tab"><AdminCharts/></div>
            <div className="tab-pane fade" id="professional" role="tabpanel" aria-labelledby="professional-tab"><AdminProPage categories={this.state.categories} subcategories={this.state.subcategories} products={this.state.products}/></div>
            <div className="tab-pane fade" id="consumers" role="tabpanel" aria-labelledby="consumers-tab"><AdminConsPage categories={this.state.cons_categories} subcategories={this.state.cons_subcategories} products={this.state.cons_products} sku={this.state.cons_productsku}/></div>
            <div className="tab-pane fade" id="downloads" role="tabpanel" aria-labelledby="downloads-tab"><AdminDownPage downloads={this.state.downloads}/></div>
            <div className="tab-pane fade" id="gallery" role="tabpanel" aria-labelledby="gallery-tab"><AdminGallery galleryImages={this.state.galleryImages}/></div>
        </div>
      </div>
    )
    }
  };