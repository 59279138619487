import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminConsCreate from './AdminConsCreate';
import AdminConsEdit from './AdminConsEdit';
import AdminConsSearch from './AdminConsSearch';

export default class AdminConsPage extends React.Component {

  render() {
    return (
      <div className='card text-left'>
          <h5 className="card-header">Admin Consumer Products</h5>
          <div className='card-body'>
            <ul className="nav nav-pills mb-3" id="pills-tabc" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="pills-createc-tab" data-toggle="pill" href="#pills-createc" role="tab" aria-controls="pills-createp" aria-selected="true">Create</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pills-editc-tab" data-toggle="pill" href="#pills-editc" role="tab" aria-controls="pills-editp" aria-selected="false">Edit</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pills-searchc-tab" data-toggle="pill" href="#pills-searchc" role="tab" aria-controls="pills-searchp" aria-selected="false">Search</a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabcContent">
                <div className="tab-pane fade show active" id="pills-createc" role="tabpanel" aria-labelledby="pills-createc-tab"><AdminConsCreate {...this.props} /></div>
                <div className="tab-pane fade" id="pills-editc" role="tabpanel" aria-labelledby="pills-editc-tab"><AdminConsEdit {...this.props} /></div>
                <div className="tab-pane fade" id="pills-searchc" role="tabpanel" aria-labelledby="pills-searchc-tab"><AdminConsSearch {...this.props} /></div>
            </div>
          </div>
      </div>
    )
    }
  };