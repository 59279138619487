import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class ShippingPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
  //  var self = this;
    return (
      <div>
      <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         marginTop:'-170px'}}>
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Shipping and Return Policies':"Politiques d'expédition et de retour"}</h1>
      </div>
      <div className='card bg-white border-0 rounded-0'>
       { this.props.lang === 'EN' ? <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Return Policy</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        Refunds are given within 30 days of purchase, less shipping and handling charges. Refunds will be credited only after Interstar receives and accepts products. All returned items will void the free shipping offer. All items must be in original, unopened packaging. Customers must contact our Customer Service Department at 800-567-1857 or <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> to receive a Return Authorization Number. Products returned without authorization will not be accepted. A restocking fee may apply to some orders. 
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Damage Claims</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        In the unlikely event a product is damaged during transport, please contact our Customer Service Department within 48 hours of delivery at 800-567-1857 or <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> to receive a Return Authorization Number. Products returned without authorization will not be accepted.
        </p>
        <h6 id='temperatureEN' className='temperatureEN text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Extreme Temperatures<i className="ml-3 fas fa-snowflake" style={{color: '#0a69ba'}} data-toggle="tooltip" data-placement="top" title="Freeze Danger"></i></h6>
        <p className='temperatureEN text-left card-text mb-2' style={{fontSize:'17px'}}>
        While Interstar takes every measure possible to guard against products freezing in cold weather, there is still a chance they may freeze. Since we cannot guarantee liquid products will not freeze, ordering these products in cold weather is at the risk of the customer.
        </p>
        {/* (self.props.country === 'CA')||(self.props.country === 'XX') ? <div>
        <h5 className='card-title font-weight-bold mt-5'>For Online Purchases (In Canada Only)</h5>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-2' style={{fontSize:'17px'}}>Free Shipping On Most Orders Over $250</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        We offer free shipping for online purchases to select locations in Canada on orders over $250 (before taxes). Shipping charges will apply if your order is less than $250, contains heavy or oversized items, and/or is being shipping to a location that does not qualify for free shipping, particularly remote locations. All qualifying purchases will be delivered by standard shipping and are subject to Interstar’s standard shipping restrictions. This offer is available within Canada only, excluding the Territories (Yukon, NWT and Nunavut) or select remote locations where market-established rural delivery fees may apply.  Please contact our Customer Service Department at 800-567-1857 or <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> to receive an accurate shipping quote for excluded locations or non-qualifying orders. Offer may be modified or cancelled at any time without notice.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Flat Rate Shipping</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        Flat rate shipping for online purchases of $19.95 is available for most orders under $250. Items purchased that do not qualify for flat rate shipping are subject to standard shipping charges. All qualifying purchases will receive standard ground shipping and are subject to Interstar’s standard shipping restrictions. Flat rate shipping is available for delivery within Canada only, but is not available for delivery to the Territories (Yukon, NWT and Nunavut) or select remote locations. Market-established rural delivery fees may apply to your location. Please contact our Customer Service Department at 800-567-1857 or <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> to receive an accurate shipping quote. Offer does not apply to the purchase of previous orders or certain items containing weight and/or size shipping restrictions. Offer subject to change without notice.
        </p>
      </div> : '' */}
      </div> : <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>

        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Politique de retour:</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        Les remboursements sont effectués dans les 30 jours suivants l'achat, moins les frais d'expédition et de manutention. Tous les articles retournés annuleront l'offre de livraison gratuite. Tous les articles doivent être dans leur emballage original, non ouvert. Les clients doivent contacter notre service clientèle au 800-567-1857 ou <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> pour recevoir un numéro d'autorisation de retour. Les produits retournés sans autorisation ne seront pas acceptés. Des frais de réapprovisionnement peuvent s'appliquer à certaines commandes. 
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Réclamations de dommages:</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        Dans le cas peu probable où un produit serait endommagé pendant le transport, veuillez contacter notre service clientèle dans les 48 heures suivant la livraison au 800-567-1857 ou <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> pour recevoir un numéro d'autorisation de retour. Les produits retournés sans autorisation ne seront pas acceptés.
        </p>
        <h6 className='temperatureEN text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Températures extrêmes:<i className="ml-3 fas fa-snowflake" style={{color: '#0a69ba'}} data-toggle="tooltip" data-placement="top" title="Freeze Danger"></i></h6>
        <p className='temperatureEN text-left card-text mb-2' style={{fontSize:'17px'}}>
        Bien que Interstar prenne toutes les mesures possibles pour empêcher les produits de geler par temps froid, il y a encore une chance qu'ils gèlent. Comme nous ne pouvons pas garantir que les produits liquides ne gèlent pas, la commande de ces produits par temps froid est aux risques et périls du client.
        </p>
        {/* (self.props.country === 'CA')||(self.props.country === 'XX') ? <div>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Livraison gratuite pour la plupart des commandes supérieures à 250 $:</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        Nous offrons la livraison gratuite à certains endroits au Canada pour les commandes de plus de 250 $ (avant taxes). Des frais d'expédition s'appliqueront si votre commande est inférieure à 250 $, s’il contient des articles lourds ou surdimensionnés et/ou s’il est destiné pour un endroit qui ne donne pas droit à la livraison gratuite, en particulier dans les endroits éloignés. Tous les achats éligibles seront livrés par expédition standard et sont soumis aux restrictions d’expédition standards d’Interstar. Cette offre est disponible au Canada seulement, à l'exclusion des territoires (Yukon, Territoires du N-O et Nunavut) ou de certains endroits éloignés où des frais de livraison en milieu rural établis peuvent s'appliquer. Veuillez contacter notre service clientèle au 800-567-1857 ou <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> pour recevoir un devis de livraison précis pour les destinations exclues ou les commandes non éligibles. Cette offre peut être modifiée ou annulée à tout moment sans préavis.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Expédition à taux fixe:</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        Une livraison forfaitaire de 19,95 $ est disponible pour la plupart des commandes de moins de 250 $. Les articles achetés qui ne sont pas admissibles à l'expédition forfaitaire sont soumis aux frais d'expédition standard. Tous les achats éligibles recevront une livraison terrestre standard et sont soumis aux restrictions d’expédition standard d’Interstar. Les frais de livraison forfaitaires ne sont disponibles qu'au Canada, mais pas dans les territoires (Yukon, Territoires du N-O et Nunavut) ou dans des régions éloignées. Des frais de livraison rurale établis par le marché peuvent s'appliquer à votre emplacement. Veuillez contacter notre service clientèle au 800-567-1857 ou <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a> pour recevoir un devis d'expédition précis. Cette offre ne s'applique pas à l'achat de commandes précédentes ni à certains articles contenant des restrictions d'expédition de poids et/ou de format. L'offre peut changer sans notification.
        </p>
    </div> : ''*/}
          </div> }
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;