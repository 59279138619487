// src/components/ChartComponent.js
import React from 'react';
import $ from 'jquery';


function Confirm(title, msg, $true, $false, $link) { /*change*/
    var $content =  "<div class='dialog-ovelay'>" +
                    "<div class='dialog'><header>" +
                     " <h2> " + title + " </h2> " +
             //        "<i class='navbar-text fas fa-times'></i>" +
                 "</header>" +
                 "<div class='dialog-msg'>" +
                     " <p> " + msg + " </p> " +
                 "</div>" +
                 "<footer>" +
                     "<div class='controls'>" +
                     " <button class='btn btn-primary doAction'>" + $true + "</button> " +
                     " <button class='btn btn-light cancelAction'>" + $false + "</button> " +
                     "</div>" +
                 "</footer>" +
              "</div>" +
            "</div>";
     $('body').prepend($content);
  $('.doAction').click(function () {
    window.open($link, "_blank"); /*new*/
    $(this).parents('.dialog-ovelay').fadeOut(500, function () {
      $(this).remove();
    });
  });
  $('.cancelAction, .fa-close').click(function () {
    $(this).parents('.dialog-ovelay').fadeOut(500, function () {
      $(this).remove();
    });
  });
  
  }



export default class ChartComponent extends React.Component {

  constructor(props) {
    super(props);
    this._onClickC = this._onClickC.bind(this);
    this._onClickCF = this._onClickCF.bind(this);
  }

    _onClickC(e){
        if (this.props.disclaimer) {
          e.preventDefault();
          var target = e.currentTarget.getAttribute('href');
          Confirm('Disclaimer', 'These color charts are only a guideline, and CANNOT be used to make your final color selection. Computer screens and printers vary greatly and do not accurately represent the colors that will be obtained. We strongly recommend creating a sample with the materials you will use to be sure the color meets your expectations. Please contact our Customer Service Department at info@interstar-paf.com or an Interstar sales representative for information on seeing an Interstar sample kit or obtaining samples.', 'OK', 'Close', target);
        }

       // if (!window.confirm('These color charts are only a guideline, and CANNOT be used to make your final color selection. Computer screens and printers vary greatly and do not accurately represent the colors that will be obtained. We strongly recommend creating a sample with the materials you will use to be sure the color meets your expectations. Please contact our Customer Service Department at info@interstar-paf.com or an Interstar sales representative for information on seeing an Interstar sample kit or obtaining samples.')) {e.preventDefault();}
    }

    _onClickCF(e){
      if (this.props.disclaimer) {
        e.preventDefault();
        var target = e.currentTarget.getAttribute('href');
        Confirm('Important', 'Étant donné que la couleur du béton peut être affectée par de nombreux facteurs, il faut utiliser cette charte de couleurs uniquement à titre indicatif. Elle offre un apercu des couleurs pouvant être obtenues. Veuillez consulter le coffret de couleurs pour béton préparé d’Interstar pour une représentation plus précise de nos couleurs pour béton. Une maquette fait avec vos matériaux et vos techniques de finition est le meilleur moyen de déterminer si la couleur désirée peut être obtenue.', 'OK', 'Fermer', target);
      }
    }
  render() {
      if ((this.props.lang === 'EN') && (this.props.display_en)) {
        return (
            <div className='col-sm-6 col-md-4 col-xl-3 mt-4 px-2'>
                <a href={'./downloads/charts/en/' + this.props.pdf_en} target='_blank' rel='noopener noreferrer' onClick={this._onClickC}>
                <img className='img-fluid' style={{minWidth:'100px', maxWidth:'140px'}} src={'./img/charts_home/en/' + this.props.image_en} alt={this.props.id}/>
                </a>
                <hr className='my-0 border-0'></hr>
                <strong className='col-sm-12' style={{fontSize:'17px'}}>{this.props.name_en}</strong>
            </div>
         );
      } else if ((this.props.lang === 'FR') && (this.props.display_fr)) {
        return (
            <div className='col-sm-6 col-md-4 col-xl-3 mt-4 px-2'>
                <a href={'./downloads/charts/fr/' + this.props.pdf_fr} target='_blank' rel='noopener noreferrer' onClick={this._onClickCF}>
                <img className='img-fluid' style={{minWidth:'100px', maxWidth:'140px'}} src={'./img/charts_home/fr/' + this.props.image_fr} alt={this.props.id}/>
                </a>
                <hr className='my-0 border-0'></hr>
                <strong className='col-sm-12' style={{fontSize:'17px'}}>{this.props.name_fr}</strong>
            </div>
         );
      } else {
          return null;
      }
    
  }
}