import React from 'react';
//import LazyLoad from 'react-lazyload';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
export default class SmallGallery extends React.Component {
// const SmallGallery = () => {
    _scroll_ele() {
      return function(){
        window.scrollBy(0, -1);
        setTimeout(function(){
          window.scrollBy(0, 1);
        },5);
      }
    }
    render() {
    return (
      <div>
        <div id="smGallery" className="carousel" data-interval="0" data-ride="carousel">
              <div className="carousel-inner">
                  { 
                  this.props.galleryImages.map(function(thisimage, idxGM){
                    return <div className={"carousel-item " + (idxGM === 0 ? "active" : "")}>
                               <img className ='img-fluid' src={'../img/gallery/' + thisimage.image} alt='smgalleyimage'></img>
                            </div>
                  })
                 }


                {/*
                <div className="carousel-item active">
                  <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/CoreyCo Concrete Tampa.jpg' alt='smgalleyimage'></img>
                  </LazyLoad>
                </div>               
                <div className="carousel-item">
                  <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Anapharm-QC 2.jpg' alt='smgalleyimage'></img>
                      </LazyLoad>
                </div> 
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Arturro.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div> 
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Black Curb 3_0.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Bloomerstudio_360 State Street_2_small.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Calgary wooden Concrete 2_0.jpeg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/CH and SR in FL.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/colorhardener.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/driveway-a.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Florida Patio.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/brownmulch.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Block.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/block2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/BPDL_Jeux1.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/BPDL_Jeux2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Customer Pool.jpeg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/DCP_1028.jpeg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/ETS.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/20150903_125250.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/seaport hotel -1745373799_9d77721daa_o.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Tremca_DSC_2096.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/stamped-colored-concrete-driveway-2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/stamped-colored-concrete-walkway.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/colored-concrete-sink.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                */}
              </div>
              <a className="carousel-control-prev" href="#smGallery" role="button" data-slide="prev" onClick={this._scroll_ele()}>
                <span className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#smGallery" role="button" data-slide="next" onClick={this._scroll_ele()}>
                <span className="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
      </div>
    )
              }
  }

  //export default SmallGallery;