import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminProProductCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          categories: [{id:'test1'},{id:'test2'}], //Cookies.get('language') || 'EN'
          cat_sel: ''
        };
        this._getProCats = this._getProCats.bind(this);
        this._onClickSel = this._onClickSel.bind(this);
      }
    
      componentDidMount() {
        this._getProCats();
      }
    
      _getProCats(){
     /*   var self = this;
        console.log('Pro Category List');
        $.get('/data/procats',function(data,status,xhr){
          console.log('status: ' + status);
          if (data.length > 0) {
            self.setState({categories: data});
          }
        },'json'); */
      }

      _onClickSel(catname){
        var self = this;
        return function(){
          if (catname === 'none') {
            self.setState({cat_sel : ''});
          } else {
            self.setState({cat_sel : catname});
          }
        }
      }

      _onSubmit(e) {
        e.preventDefault();
        
        var form = $('#createproproduct')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#createproproduct')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
        }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Professional Product</h5>
        <form id='createproproduct' action='/admin/createproproduct' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1p" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control form-control-sm" id="InputENp" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control form-control-sm" id="InputFRp" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea name='desc_en' className="form-control form-control-sm" id="InputDescEN"  placeholder="Enter English Description" rows="3" required></textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea name='desc_fr' className="form-control form-control-sm" id="InputDescFR"  placeholder="Enter French Description" rows="3" required></textarea>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input className="form-check-input" type="radio" name="group" id="exampleRadios1" value="category" checked/>
          <label className="form-check-label font-weight-bold" for="exampleRadios1">
            Category
          </label>
          
        </div>
        <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input className="form-check-input" type="radio" name="group" id="exampleRadios2" value="subcat"/>
          <label className="form-check-label font-weight-bold" for="exampleRadios2">
            Sub-Category
          </label>
          
        </div>
        <select name='subcat' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.subcategories.map(thiscat=>{
                return <option>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_en' className="custom-control-input" id="visiblepEN"/>
            <label className="custom-control-label" htmlFor="visiblepEN">Show Eng</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_fr' className="custom-control-input" id="visiblepFR"/>
            <label className="custom-control-label" htmlFor="visiblepFR">Show Frn</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCAN"/>
            <label className="custom-control-label" htmlFor="visiblepCAN">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_us' className="custom-control-input" id="visiblepUS"/>
            <label className="custom-control-label" htmlFor="visiblepUS">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGL"/>
            <label className="custom-control-label" htmlFor="visiblepGL">Show Global</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_chart' className="custom-control-input" id="show_chart"/>
            <label className="custom-control-label" htmlFor="show_chart">Show Chart</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_broc' className="custom-control-input" id="show_broc"/>
            <label className="custom-control-label" htmlFor="show_broc">Show Brochure</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_inst' className="custom-control-input" id="show_inst"/>
            <label className="custom-control-label" htmlFor="show_inst">Show Instructions</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_info' className="custom-control-input" id="show_info"/>
            <label className="custom-control-label" htmlFor="show_info">Show Info</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_over' className="custom-control-input" id="show_over"/>
            <label className="custom-control-label" htmlFor="show_over">Show Overview</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_chartfr' className="custom-control-input" id="show_chartfr"/>
            <label className="custom-control-label" htmlFor="show_chartfr">Show Fr.Chart</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_brocfr' className="custom-control-input" id="show_brocfr"/>
            <label className="custom-control-label" htmlFor="show_brocfr">Show Fr.Brochure</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_instfr' className="custom-control-input" id="show_instfr"/>
            <label className="custom-control-label" htmlFor="show_instfr">Show Fr.Instructions</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_infofr' className="custom-control-input" id="show_infofr"/>
            <label className="custom-control-label" htmlFor="show_infofr">Show Fr.Info</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_overfr' className="custom-control-input" id="show_overfr"/>
            <label className="custom-control-label" htmlFor="show_overfr">Show Fr.Overview</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='disclaimer' className="custom-control-input" id="disclaimerP"/>
            <label className="custom-control-label" htmlFor="disclaimerP">Chart Disclaimer</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control form-control-sm" id="InputID2" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='chart_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='chart_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Brochure</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='brochure_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Brochure</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='brochure_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Instructions</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='instructions_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Instructions</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='instructions_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Product Info</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='productinfo_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Product Info</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='productinfo_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Overview</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='overview_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Overview</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='overview_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };