import React from 'react';
import $ from 'jquery';

export default class AdminConsProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          prod_id: '',
            prod_name_en: '',
            prod_name_fr: '',
            prod_desc_en: '',
            prod_desc_fr: '',
            prod_membertype: '',
            prod_memberof: '',
            prod_show_en: true,
            prod_show_fr: true,
            prod_show_ca: true,
            prod_show_us: true,
            prod_show_gl: true,
            prod_freeze: true,
            prod_hazard: true,
            prod_eco: true,
            prod_order: 0,
            prod_show_f1: false,
            prod_disclaimer: false,
            prod_show_f2: false,
            prod_show_f3: false,
            prod_name_f1: '',
            prod_name_f2: '',
            prod_name_f3: '',
            prod_file1: '',
            prod_file2: '',
            prod_file3: '',
            prod_show_f1_fr: false,
            prod_show_f2_fr: false,
            prod_show_f3_fr: false,
            prod_name_f1_fr: '',
            prod_name_f2_fr: '',
            prod_name_f3_fr: '',
            prod_file1_fr: '',
            prod_file2_fr: '',
            prod_file3_fr: ''

        };
        this._toggleLock = this._toggleLock.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);

        this._onChangeNameEn = this._onChangeNameEn.bind(this);
        this._onChangeNameFr = this._onChangeNameFr.bind(this);
        this._onChangeDescEn = this._onChangeDescEn.bind(this);
        this._onChangeDescFr = this._onChangeDescFr.bind(this);
        this._setmembertype = this._setmembertype.bind(this);
        this._ChangeOrder = this._ChangeOrder.bind(this);
        this._toggle_show_en = this._toggle_show_en.bind(this);
        this._toggle_show_fr = this._toggle_show_fr.bind(this);
        this._toggle_show_ca = this._toggle_show_ca.bind(this);
        this._toggle_show_us = this._toggle_show_us.bind(this);
        this._toggle_show_gl = this._toggle_show_gl.bind(this);
        this._toggle_freeze = this._toggle_freeze.bind(this);
        this._toggle_hazard = this._toggle_hazard.bind(this);
        this._toggle_eco = this._toggle_eco.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._toggle_disclaimer = this._toggle_disclaimer.bind(this);
        this._onClickShowF1 = this._onClickShowF1.bind(this);
        this._onClickShowF2 = this._onClickShowF2.bind(this);
        this._onClickShowF3 = this._onClickShowF3.bind(this);
        this._onChangeNameF1 = this._onChangeNameF1.bind(this);
        this._onChangeNameF2 = this._onChangeNameF2.bind(this);
        this._onChangeNameF3 = this._onChangeNameF3.bind(this);
        this._onClickShowF1_fr = this._onClickShowF1_fr.bind(this);
        this._onClickShowF2_fr = this._onClickShowF2_fr.bind(this);
        this._onClickShowF3_fr = this._onClickShowF3_fr.bind(this);
        this._onChangeNameF1_fr = this._onChangeNameF1_fr.bind(this);
        this._onChangeNameF2_fr = this._onChangeNameF2_fr.bind(this);
        this._onChangeNameF3_fr = this._onChangeNameF3_fr.bind(this);

      }
      componentDidMount(){
     /*   var idsplice = this.props.thisprod.id.split(' ');
        $(document).ready(function(){
            $(".show").click(function(){
                $("ProProdModal" + idsplice[0]).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            });
        }); */
      }
      _toggleLock(){
        var self = this;
        let newlock = !this.state.locked;
        if (this.state.locked) {
            self.setState({
                locked: newlock,
                prod_id: self.props.thisprod.id,
                prod_name_en: self.props.thisprod.name_en,
                prod_name_fr: self.props.thisprod.name_fr,
                prod_desc_en: self.props.thisprod.desc_en,
                prod_desc_fr: self.props.thisprod.desc_fr,
                prod_membertype: self.props.thisprod.membertype,
                prod_memberof: self.props.thisprod.memberof,
                prod_show_en: self.props.thisprod.show_en,
                prod_show_fr: self.props.thisprod.show_fr,
                prod_show_ca: self.props.thisprod.show_ca,
                prod_show_us: self.props.thisprod.show_us,
                prod_show_gl: self.props.thisprod.show_gl,
                prod_freeze: self.props.thisprod.freeze,
                prod_hazard: self.props.thisprod.hazard,
                prod_eco: self.props.thisprod.eco,
                prod_order: self.props.thisprod.order,
                prod_disclaimer: self.props.thisprod.disclaimer,
                prod_show_f1: self.props.thisprod.show_f1,
              prod_show_f2: self.props.thisprod.show_f2,
              prod_show_f3: self.props.thisprod.show_f3,
              prod_name_f1: self.props.thisprod.name_f1,
              prod_name_f2: self.props.thisprod.name_f2,
              prod_name_f3: self.props.thisprod.name_f3,
              prod_file1: self.props.thisprod.file1,
              prod_file2: self.props.thisprod.file2,
              prod_file3: self.props.thisprod.file3,
              prod_show_f1_fr: self.props.thisprod.show_f1_fr,
              prod_show_f2_fr: self.props.thisprod.show_f2_fr,
              prod_show_f3_fr: self.props.thisprod.show_f3_fr,
              prod_name_f1_fr: self.props.thisprod.name_f1_fr,
              prod_name_f2_fr: self.props.thisprod.name_f2_fr,
              prod_name_f3_fr: self.props.thisprod.name_f3_fr,
              prod_file1_fr: self.props.thisprod.file1_fr,
              prod_file2_fr: self.props.thisprod.file2_fr,
              prod_file3_fr: self.props.thisprod.file3_fr,

            });
        } else {
            this.setState({locked:newlock});
        }
      }
      _lockItem(){

      }
      _saveItem(){

      }
      _ChangeOrder(e){
        var str = e.target.value;
        this.setState({prod_order: str});
      }

      _onChangeNameEn(e){
        var str = e.target.value;
        this.setState({prod_name_en: str});
      }

      _onChangeNameFr(e){
        var str = e.target.value;
        this.setState({prod_name_fr: str});
      }

      _onChangeDescEn(e){
        var str = e.target.value;
        this.setState({prod_desc_en: str});
      }

      _onChangeDescFr(e){
        var str = e.target.value;
        this.setState({prod_desc_fr: str});
      }

      _setmembertype(type) {
        var self = this;
        return function(){
          console.log('Set membertype: ' + type);
          self.setState({prod_membertype: type});
        }
      }
      _toggle_show_en(){
        let newstate = !this.state.prod_show_en;
        this.setState({prod_show_en: newstate});
      }
      _toggle_show_fr(){
        let newstate = !this.state.prod_show_fr;
        this.setState({prod_show_fr: newstate});
      }
      _toggle_show_ca(){
        let newstate = !this.state.prod_show_ca;
        this.setState({prod_show_ca: newstate});
      }
      _toggle_show_us(){
        let newstate = !this.state.prod_show_us;
        this.setState({prod_show_us: newstate});
      }
      _toggle_show_gl(){
        let newstate = !this.state.prod_show_gl;
        this.setState({prod_show_gl: newstate});
      }
      _toggle_freeze(){
        let newstate = !this.state.prod_freeze;
        this.setState({prod_freeze: newstate});
      }
      _toggle_hazard(){
        let newstate = !this.state.prod_hazard;
        this.setState({prod_hazard: newstate});
      }
      _toggle_eco(){
        let newstate = !this.state.prod_eco;
        this.setState({prod_eco: newstate});
      }
      _toggle_disclaimer(){
        var newstate = !this.state.prod_disclaimer;
        this.setState({prod_disclaimer:newstate});
      }
      _onClickShowF1(){
        var newstate = !this.state.prod_show_f1;
        this.setState({prod_show_f1:newstate});
      }
      _onClickShowF2(){
        var newstate = !this.state.prod_show_f2;
        this.setState({prod_show_f2:newstate});
      }
      _onClickShowF3(){
        var newstate = !this.state.prod_show_f3;
        this.setState({prod_show_f3:newstate});
      }
      _onChangeNameF1(e){
        var str = e.target.value;
        this.setState({prod_name_f1: str});
      }
      _onChangeNameF2(e){
        var str = e.target.value;
        this.setState({prod_name_f2: str});
      }
      _onChangeNameF3(e){
        var str = e.target.value;
        this.setState({prod_name_f3: str});
      }
      _onClickShowF1_fr(){
        var newstate = !this.state.prod_show_f1_fr;
        this.setState({prod_show_f1_fr:newstate});
      }
      _onClickShowF2_fr(){
        var newstate = !this.state.prod_show_f2_fr;
        this.setState({prod_show_f2_fr:newstate});
      }
      _onClickShowF3_fr(){
        var newstate = !this.state.prod_show_f3_fr;
        this.setState({prod_show_f3_fr:newstate});
      }
      _onChangeNameF1_fr(e){
        var str = e.target.value;
        this.setState({prod_name_f1_fr: str});
      }
      _onChangeNameF2_fr(e){
        var str = e.target.value;
        this.setState({prod_name_f2_fr: str});
      }
      _onChangeNameF3_fr(e){
        var str = e.target.value;
        this.setState({prod_name_f3_fr: str});
      }
      _onSubmit(e) {
        var self = this;
        e.preventDefault();
        
        var form = $('#editconsproductm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#editconsproductm' + self.props.thisprod.id)[0].reset();
            self._toggleLock();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
      }
      _onSubmitF1Form(e) {
        e.preventDefault();
        var form = $('#editconsproductF1m')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF2Form(e) {
        e.preventDefault();
        var form = $('#editconsproductF2m')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF3Form(e) {
        e.preventDefault();
        var form = $('#editconsproductF3m')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF1Form_fr(e) {
        e.preventDefault();
        var form = $('#editconsproductF1_frm')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF2Form_fr(e) {
        e.preventDefault();
        var form = $('#editconsproductF2_frm')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF3Form_fr(e) {
        e.preventDefault();
        var form = $('#editconsproductF3_frm')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

    render(){
        var idsplice = this.props.thisprod.id.split(' ');
        var self = this;
        return (
                <div className="modal fade" id={"ConsProdModal" + idsplice[0]} tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.thisprod.id}</h5> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-control custom-switch custom-control-inline">
                                <input onClick={this._toggleLock} type="checkbox" className="custom-control-input" id={"prod_lockc"+ idsplice[0]} checked={this.state.locked === true ? 'checked' : ''}/>
                                <label className="custom-control-label" htmlFor={"prod_lockc"+ idsplice[0]}>{this.state.locked ? 'Unlock' : 'Lock'}</label>
                            </div>
                            {this.state.locked ? <div>
                                <form>
                                    <div className="form-group row mb-1">
                                        <label for={"nameEnc" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">English Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"nameEnc" + idsplice[0]} value={this.props.thisprod.name_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"nameFrc" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">French Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"nameFrc" + idsplice[0]} value={this.props.thisprod.name_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"EngDescppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">English Desc: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"EngDescppec" + idsplice[0]} value={this.props.thisprod.desc_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"FrnDescppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">French Desc: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"FrnDescppec" + idsplice[0]} value={this.props.thisprod.desc_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"groupPc" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Group: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"groupPc" + idsplice[0]} value={this.props.thisprod.memberof}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showENppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Eng: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showENppec" + idsplice[0]} value={this.props.thisprod.show_en ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showFRppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Frn: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showFRppec" + idsplice[0]} value={this.props.thisprod.show_fr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showCAppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Can: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showCAppec" + idsplice[0]} value={this.props.thisprod.show_ca ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showUSppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible US: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showUSppec" + idsplice[0]} value={this.props.thisprod.show_us ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showGLppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible GL: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showGLppec" + idsplice[0]} value={this.props.thisprod.show_gl ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"freezeppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Freeze: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"freezeppec" + idsplice[0]} value={this.props.thisprod.freeze ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"hazardppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Hazard: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"hazardppec" + idsplice[0]} value={this.props.thisprod.hazard ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"ecoppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Eco Friendly: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"ecoppec" + idsplice[0]} value={this.props.thisprod.eco ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"Disclaim" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Disclaimer: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"Disclaim" + idsplice[0]} value={this.props.thisprod.disclaimer ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"f1ppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">File1: </label>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"f1ppec" + idsplice[0]} value={this.props.thisprod.show_f1 ? 'True': 'False'}/>
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"n1ppec" + idsplice[0]} value={this.props.thisprod.name_f1}/>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" readonly className="form-control-plaintext" id={"file1ppec" + idsplice[0]} value={this.props.thisprod.file1}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"f2ppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">File2: </label>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"f2ppec" + idsplice[0]} value={this.props.thisprod.show_f2 ? 'True': 'False'}/>
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"n2ppec" + idsplice[0]} value={this.props.thisprod.name_f2}/>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" readonly className="form-control-plaintext" id={"file2ppec" + idsplice[0]} value={this.props.thisprod.file2}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"f3ppec" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">File3: </label>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"f3ppec" + idsplice[0]} value={this.props.thisprod.show_f3 ? 'True': 'False'}/>
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"n3ppec" + idsplice[0]} value={this.props.thisprod.name_f3}/>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" readonly className="form-control-plaintext" id={"file3ppec" + idsplice[0]} value={this.props.thisprod.file3}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"f1ppecfr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">File1 FR: </label>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"f1ppecfr" + idsplice[0]} value={this.props.thisprod.show_f1_fr ? 'True': 'False'}/>
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"n1ppecfr" + idsplice[0]} value={this.props.thisprod.name_f1_fr}/>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" readonly className="form-control-plaintext" id={"file1ppecfr" + idsplice[0]} value={this.props.thisprod.file1_fr}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"f2ppecfr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">File2 FR: </label>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"f21ppecfr" + idsplice[0]} value={this.props.thisprod.show_f2_fr ? 'True': 'False'}/>
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"n2ppecfr" + idsplice[0]} value={this.props.thisprod.name_f2_fr}/>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" readonly className="form-control-plaintext" id={"file2ppecfr" + idsplice[0]} value={this.props.thisprod.file2_fr}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"f3ppecfr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">File3 FR: </label>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"f3ppecfr" + idsplice[0]} value={this.props.thisprod.show_f3_fr ? 'True': 'False'}/>
                                        </div>
                                        <div className="col-sm-2">
                                        <input type="text" readonly className="form-control-plaintext" id={"n3ppecfr" + idsplice[0]} value={this.props.thisprod.name_f3_fr}/>
                                        </div>
                                        <div className="col-sm-5">
                                        <input type="text" readonly className="form-control-plaintext" id={"file3ppecfr" + idsplice[0]} value={this.props.thisprod.file3_fr}/>
                                        </div>
                                    </div>

                                    </form>
                            </div> : <div>
                            <form id={'editconsproductm' + self.props.thisprod.id} action='/admin/update_consproduct' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pe" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameEn} value={self.state.prod_name_en} name='name_en' type="text" className="form-control form-control-sm" id="InputENpepc" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameFr} value={self.state.prod_name_fr} name='name_fr' type="text" className="form-control form-control-sm" id="InputFRpepc" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescEn} value={self.state.prod_desc_en} name='desc_en' className="form-control form-control-sm" id="InputDescENepc"  placeholder="Enter English Description" rows="3" required></textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescFr} value={self.state.prod_desc_fr} name='desc_fr' className="form-control form-control-sm" id="InputDescFRepc"  placeholder="Enter French Description" rows="3" required></textarea>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('category')} className="form-check-input" type="radio" name="group" id="exampleRadios1epc" value="category" checked={this.state.prod_membertype === 'category' ? true : ''} /> {/**={this.state.membertype === 'category' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios1epc">
            Category
          </label>
          
        </div>
        <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option selected={thiscat.id === self.props.thisprod.memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('subcat')} className="form-check-input" type="radio" name="group" id="exampleRadios2epc" value="subcat" checked={this.state.prod_membertype === 'subcat' ? true : ''}/> {/**checked={this.state.membertype === 'subcat' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios2epc">
            Sub-Category
          </label>
          
        </div>
        <select name='subcat' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.subcategories.map(thiscat=>{
                return <option selected={thiscat.id === self.props.thisprod.memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_en} type="checkbox" name='show_en' className="custom-control-input" id="visiblepENepc" checked={this.state.prod_show_en === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepENepc">Show Eng</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_fr} type="checkbox" name='show_fr' className="custom-control-input" id="visiblepFRepc" checked={this.state.prod_show_fr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepFRepc">Show Frn</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_ca} type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANepc" checked={this.state.prod_show_ca === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepCANepc">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_us} type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSepc" checked={this.state.prod_show_us === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepUSepc">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_gl} type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLepc" checked={this.state.prod_show_gl === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepGLepc">Show Global</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_freeze} type="checkbox" name='freeze' className="custom-control-input" id="freezeepc" checked={this.state.prod_freeze === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="freezeepc">Freeze</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_hazard} type="checkbox" name='hazard' className="custom-control-input" id="hazardepc" checked={this.state.prod_hazard === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="hazardepc">Hazard</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_eco} type="checkbox" name='eco' className="custom-control-input" id="ecoepc" checked={this.state.prod_eco === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="ecoepc">Eco Friendly</label>
        </div>
        <hr className='my-1'></hr>

        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={this._ChangeOrder}type="number" name='sort' className="form-control form-control-sm" id="InputID2epc" aria-describedby="idHelp" placeholder='0' value={this.state.prod_order}/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit Updates</button>
        </form>
        <hr className='my-1'></hr>


        <form id='editconsproductF1m' action='/admin/updateConsProdFile1' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF1Form}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef1m" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF1}  type="checkbox" name='show_f1' className="custom-control-input" id="showf1em" checked={this.state.prod_show_f1 === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf1em">Show File1</label>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._toggle_disclaimer}  type="checkbox" name='disclaimer' className="custom-control-input" id="showdisc" checked={this.state.prod_disclaimer === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showdisc">Show Disclaimer</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f1' type="text" className="form-control form-control-sm" id="InputF1NameEm" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f1} onChange={this._onChangeNameF1}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file1' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file1}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF2m' action='/admin/updateConsProdFile2' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF2Form}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef2m" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF2}  type="checkbox" name='show_f2' className="custom-control-input" id="showf2em" checked={this.state.prod_show_f2 === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf2em">Show File2</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f2' type="text" className="form-control form-control-sm" id="InputF2NameEm" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f2} onChange={this._onChangeNameF2}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file2' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file2}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF3m' action='/admin/updateConsProdFile3' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF3Form}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef3m" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF3}  type="checkbox" name='show_f3' className="custom-control-input" id="showf3em" checked={this.state.prod_show_f3 === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf3em">Show File3</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f3' type="text" className="form-control form-control-sm" id="InputF3NameEm" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f3} onChange={this._onChangeNameF3}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file3' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file3}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF1_frm' action='/admin/updateConsProdFile1_fr' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF1Form_fr}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef1_frm" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF1_fr}  type="checkbox" name='show_f1_fr' className="custom-control-input" id="showf1e_frm" checked={this.state.prod_show_f1_fr === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf1e_frm">Show File1 Fr</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f1_fr' type="text" className="form-control form-control-sm" id="InputF1NameE_frm" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f1_fr} onChange={this._onChangeNameF1_fr}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file1_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file1_fr}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF2_frm' action='/admin/updateConsProdFile2_fr' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF2Form_fr}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef2_frm" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF2_fr}  type="checkbox" name='show_f2_fr' className="custom-control-input" id="showf2e_frm" checked={this.state.prod_show_f2_fr === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf2e_frm">Show File2 Fr</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f2_fr' type="text" className="form-control form-control-sm" id="InputF2NameE_frm" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f2_fr} onChange={this._onChangeNameF2_fr}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file2_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file2_fr}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF3_frm' action='/admin/updateConsProdFile3_fr' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF3Form_fr}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef3_frm" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF3_fr}  type="checkbox" name='show_f3_fr' className="custom-control-input" id="showf3e_frm" checked={this.state.prod_show_f3_fr === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf3e_frm">Show File3 Fr</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f3_fr' type="text" className="form-control form-control-sm" id="InputF3NameE_frm" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f3_fr} onChange={this._onChangeNameF3_fr}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file3_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file3_fr}</div>

        </div>
        </form>

        <hr className='my-1'></hr>


                            </div>
                              }
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
        )
    }
}