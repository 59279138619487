import React from 'react';
import $ from 'jquery';

export default class AdminProProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          prod_id: '',
            prod_name_en: '',
            prod_name_fr: '',
            prod_desc_en: '',
            prod_desc_fr: '',
            prod_membertype: '',
            prod_memberof: '',
            prod_show_en: true,
            prod_show_fr: true,
            prod_show_ca: true,
            prod_show_us: true,
            prod_show_gl: true,
            prod_show_colorchart: true,
            prod_show_brochure: true,
            prod_show_instructions: true,
            prod_show_productinfo: true,
            prod_show_overview: true,
            prod_show_colorchartfr: true,
            prod_show_brochurefr: true,
            prod_show_instructionsfr: true,
            prod_show_productinfofr: true,
            prod_show_overviewfr: true,
            prod_disclaimer: true,
            prod_order: 0,
            prod_chart_en: '',
            prod_chart_fr: '',
            prod_brochure_en: '',
            prod_brochure_fr: '',
            prod_instructions_en: '',
            prod_instructions_fr: '',
            prod_productinfo_en: '',
            prod_productinfo_fr: '',
            prod_overview_en: '',
            prod_overview_fr: ''
        };
        this._toggleLock = this._toggleLock.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);

        this._onChangeNameEn = this._onChangeNameEn.bind(this);
        this._onChangeNameFr = this._onChangeNameFr.bind(this);
        this._onChangeDescEn = this._onChangeDescEn.bind(this);
        this._onChangeDescFr = this._onChangeDescFr.bind(this);
        this._setmembertype = this._setmembertype.bind(this);
        this._ChangeOrder = this._ChangeOrder.bind(this);
        this._toggle_show_en = this._toggle_show_en.bind(this);
        this._toggle_show_fr = this._toggle_show_fr.bind(this);
        this._toggle_show_ca = this._toggle_show_ca.bind(this);
        this._toggle_show_us = this._toggle_show_us.bind(this);
        this._toggle_show_gl = this._toggle_show_gl.bind(this);
        this._toggle_show_colorchart = this._toggle_show_colorchart.bind(this);
        this._toggle_show_brochure = this._toggle_show_brochure.bind(this);
        this._toggle_show_instructions = this._toggle_show_instructions.bind(this);
        this._toggle_show_productinfo = this._toggle_show_productinfo.bind(this);
        this._toggle_show_overview = this._toggle_show_overview.bind(this);
        this._toggle_show_colorchartfr = this._toggle_show_colorchartfr.bind(this);
        this._toggle_show_brochurefr = this._toggle_show_brochurefr.bind(this);
        this._toggle_show_instructionsfr = this._toggle_show_instructionsfr.bind(this);
        this._toggle_show_productinfofr = this._toggle_show_productinfofr.bind(this);
        this._toggle_show_overviewfr = this._toggle_show_overviewfr.bind(this);
        this._toggle_disclaimer = this._toggle_disclaimer.bind(this);

        this._onSubmit = this._onSubmit.bind(this);
        this._onSubmitEnBroc = this._onSubmitEnBroc.bind(this);
        this._onSubmitFrBroc = this._onSubmitFrBroc.bind(this);
        this._onSubmitEnChart = this._onSubmitEnChart.bind(this);
        this._onSubmitFrChart = this._onSubmitFrChart.bind(this);
        this._onSubmitEnInfo = this._onSubmitEnInfo.bind(this);
        this._onSubmitFrInfo = this._onSubmitFrInfo.bind(this);
        this._onSubmitEnInst = this._onSubmitEnInst.bind(this);
        this._onSubmitFrInst = this._onSubmitFrInst.bind(this);
        this._onSubmitEnOver = this._onSubmitFrOver.bind(this);

      }
      componentDidMount(){
     /*   var idsplice = this.props.thisprod.id.split(' ');
        $(document).ready(function(){
            $(".show").click(function(){
                $("ProProdModal" + idsplice[0]).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            });
        }); */
      }
      _toggleLock(){
        var self = this;
        let newlock = !this.state.locked;
        if (this.state.locked) {
            self.setState({
                locked: newlock,
                prod_id: self.props.thisprod.id,
                prod_name_en: self.props.thisprod.name_en,
                prod_name_fr: self.props.thisprod.name_fr,
                prod_desc_en: self.props.thisprod.desc_en,
                prod_desc_fr: self.props.thisprod.desc_fr,
                prod_membertype: self.props.thisprod.membertype,
                prod_memberof: self.props.thisprod.memberof,
                prod_show_en: self.props.thisprod.show_en,
                prod_show_fr: self.props.thisprod.show_fr,
                prod_show_ca: self.props.thisprod.show_ca,
                prod_show_us: self.props.thisprod.show_us,
                prod_show_gl: self.props.thisprod.show_gl,
                prod_show_colorchart: self.props.thisprod.show_colorchart,
                prod_show_brochure: self.props.thisprod.show_brochure,
                prod_show_instructions: self.props.thisprod.show_instructions,
                prod_show_productinfo: self.props.thisprod.show_productinfo,
                prod_show_overview: self.props.thisprod.show_overview,
                prod_show_colorchartfr: self.props.thisprod.show_colorchartfr,
                prod_show_brochurefr: self.props.thisprod.show_brochurefr,
                prod_show_instructionsfr: self.props.thisprod.show_instructionsfr,
                prod_show_productinfofr: self.props.thisprod.show_productinfofr,
                prod_show_overviewfr: self.props.thisprod.show_overviewfr,
                prod_disclaimer: self.props.thisprod.prod_disclaimer,
                prod_order: self.props.thisprod.order,
                prod_chart_en: self.props.thisprod.chart_en,
                prod_chart_fr: self.props.thisprod.chart_fr,
                prod_brochure_en: self.props.thisprod.brochure_en,
                prod_brochure_fr: self.props.thisprod.brochure_fr,
                prod_instructions_en: self.props.thisprod.instructions_en,
                prod_instructions_fr: self.props.thisprod.instructions_fr,
                prod_productinfo_en: self.props.thisprod.productinfo_en,
                prod_productinfo_fr: self.props.thisprod.productinfo_fr,
                prod_overview_en: self.props.thisprod.overview_en,
                prod_overview_fr: self.props.thisprod.overview_fr
            });
        } else {
            this.setState({locked:newlock});
        }
      }
      _lockItem(){

      }
      _saveItem(){

      }
      _ChangeOrder(e){
        var str = e.target.value;
        this.setState({prod_order: str});
      }

      _onChangeNameEn(e){
        var str = e.target.value;
        this.setState({prod_name_en: str});
      }

      _onChangeNameFr(e){
        var str = e.target.value;
        this.setState({prod_name_fr: str});
      }

      _onChangeDescEn(e){
        var str = e.target.value;
        this.setState({prod_desc_en: str});
      }

      _onChangeDescFr(e){
        var str = e.target.value;
        this.setState({prod_desc_fr: str});
      }

      _setmembertype(type) {
        var self = this;
        return function(){
          console.log('Set membertype: ' + type);
          self.setState({prod_membertype: type});
        }
      }
      _toggle_show_en(){
        let newstate = !this.state.prod_show_en;
        this.setState({prod_show_en: newstate});
      }
      _toggle_show_fr(){
        let newstate = !this.state.prod_show_fr;
        this.setState({prod_show_fr: newstate});
      }
      _toggle_show_ca(){
        let newstate = !this.state.prod_show_ca;
        this.setState({prod_show_ca: newstate});
      }
      _toggle_show_us(){
        let newstate = !this.state.prod_show_us;
        this.setState({prod_show_us: newstate});
      }
      _toggle_show_gl(){
        let newstate = !this.state.prod_show_gl;
        this.setState({prod_show_gl: newstate});
      }
      _toggle_show_colorchart(){
        let newstate = !this.state.prod_show_colorchart;
        this.setState({prod_show_colorchart: newstate});
      }
      _toggle_show_brochure(){
        let newstate = !this.state.prod_show_brochure;
        this.setState({prod_show_brochure: newstate});
      }
      _toggle_show_instructions(){
        let newstate = !this.state.prod_show_instructions;
        this.setState({prod_show_instructions: newstate});
      }
      _toggle_show_productinfo(){
        let newstate = !this.state.prod_show_productinfo;
        this.setState({prod_show_productinfo: newstate});
      }
      _toggle_show_overview(){
        let newstate = !this.state.prod_show_overview;
        this.setState({prod_show_overview: newstate});
      }
      _toggle_show_colorchartfr(){
        let newstate = !this.state.prod_show_colorchartfr;
        this.setState({prod_show_colorchartfr: newstate});
      }
      _toggle_show_brochurefr(){
        let newstate = !this.state.prod_show_brochurefr;
        this.setState({prod_show_brochurefr: newstate});
      }
      _toggle_show_instructionsfr(){
        let newstate = !this.state.prod_show_instructionsfr;
        this.setState({prod_show_instructionsfr: newstate});
      }
      _toggle_show_productinfofr(){
        let newstate = !this.state.prod_show_productinfofr;
        this.setState({prod_show_productinfofr: newstate});
      }
      _toggle_show_overviewfr(){
        let newstate = !this.state.prod_show_overviewfr;
        this.setState({prod_show_overviewfr: newstate});
      }
      _toggle_disclaimer(){
        let newstate = !this.state.prod_disclaimer;
        this.setState({prod_disclaimer: newstate});
      }
      _onSubmit(e) {
        var self = this;
        e.preventDefault();
        
        var form = $('#editproproductm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#editproproductm' + self.props.thisprod.id)[0].reset();
            self._toggleLock();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
      }
      _onSubmitEnChart(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_en_chartm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrChart(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_fr_chartm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitEnBroc(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_en_brocm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrBroc(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_fr_brocm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

      _onSubmitEnInst(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_en_instm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrInst(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_fr_instm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

      _onSubmitEnInfo(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_en_infom' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrInfo(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_fr_infom' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      
      _onSubmitEnOver(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_en_overm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

      _onSubmitFrOver(e) {
        var self = this;
        e.preventDefault();
        var form = $('#editproproduct_fr_overm' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
    render(){
        var idsplice = this.props.thisprod.id.split(' ');
        var self = this;
        return (
                <div className="modal fade" id={"ProProdModal" + idsplice[0]} tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.thisprod.id}</h5> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-control custom-switch custom-control-inline">
                                <input onClick={this._toggleLock} type="checkbox" className="custom-control-input" id={"prod_lock"+ idsplice[0]} checked={this.state.locked === true ? 'checked' : ''}/>
                                <label className="custom-control-label" htmlFor={"prod_lock"+ idsplice[0]}>{this.state.locked ? 'Unlock' : 'Lock'}</label>
                            </div>
                            {this.state.locked ? <div>
                                <form>
                                    <div className="form-group row mb-1">
                                        <label for={"nameEn" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">English Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"nameEn" + idsplice[0]} value={this.props.thisprod.name_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"nameFr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">French Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"nameFr" + idsplice[0]} value={this.props.thisprod.name_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"EngDescppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">English Desc: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"EngDescppe" + idsplice[0]} value={this.props.thisprod.desc_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"FrnDescppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">French Desc: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"FrnDescppe" + idsplice[0]} value={this.props.thisprod.desc_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"groupP" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Group: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"groupP" + idsplice[0]} value={this.props.thisprod.memberof}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showENppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Eng: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showENppe" + idsplice[0]} value={this.props.thisprod.show_en ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showFRppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Frn: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showFRppe" + idsplice[0]} value={this.props.thisprod.show_fr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showCAppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Can: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showCAppe" + idsplice[0]} value={this.props.thisprod.show_ca ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showUSppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible US: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showUSppe" + idsplice[0]} value={this.props.thisprod.show_us ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showGLppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible GL: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showGLppe" + idsplice[0]} value={this.props.thisprod.show_gl ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showChppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Chart: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showChppe" + idsplice[0]} value={this.props.thisprod.show_colorchart ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showBrppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Brochure: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showBrppe" + idsplice[0]} value={this.props.thisprod.show_brochure ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showInppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Instructions: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showInppe" + idsplice[0]} value={this.props.thisprod.show_instructions ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showIfppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Info: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showIfppe" + idsplice[0]} value={this.props.thisprod.show_productinfo ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showOvppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Overview: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showOvppe" + idsplice[0]} value={this.props.thisprod.show_overview ? 'True': 'False'}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"showChppefr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Fr.Chart: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showChppefr" + idsplice[0]} value={this.props.thisprod.show_colorchartfr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showBrppefr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Fr.Brochure: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showBrppfr" + idsplice[0]} value={this.props.thisprod.show_brochurefr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showInppefr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Fr.Instructions: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showInppefr" + idsplice[0]} value={this.props.thisprod.show_instructionsfr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showIfppefr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Fr.Info: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showIfppefr" + idsplice[0]} value={this.props.thisprod.show_productinfofr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showOvppefr" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Fr.Overview: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showOvppefr" + idsplice[0]} value={this.props.thisprod.show_overviewfr ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"disclaimerM" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Show Disclaimer: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"disclaimerM" + idsplice[0]} value={this.props.thisprod.disclaimer ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileChartEnppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Eng Chart: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileChartEnppe" + idsplice[0]} value={this.props.thisprod.chart_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileChartFrppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Frn Chart: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileChartFrppe" + idsplice[0]} value={this.props.thisprod.chart_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileBrochEnppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Eng Brochure: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileBrochEnppe" + idsplice[0]} value={this.props.thisprod.brochure_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileBrochFrppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Frn Brochure: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileBrochFrppe" + idsplice[0]} value={this.props.thisprod.brochure_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileInstEnppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Eng Instructions: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileInstEnppe" + idsplice[0]} value={this.props.thisprod.instructions_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileInstFrppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Frn Instructions: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileInstFrppe" + idsplice[0]} value={this.props.thisprod.instructions_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileInfoEnppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Eng Info: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileInfoEnppe" + idsplice[0]} value={this.props.thisprod.productinfo_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileInfoFrppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Frn Info: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileInfoFrppe" + idsplice[0]} value={this.props.thisprod.productinfo_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileoverEnppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Eng Overview: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileoverEnppe" + idsplice[0]} value={this.props.thisprod.overview_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"fileoverFrppe" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Frn Overview: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"fileoverFrppe" + idsplice[0]} value={this.props.thisprod.overview_fr}/>
                                        </div>
                                    </div>
                                    </form>
                            </div> : <div>
                            <form id={'editproproductm' + self.props.thisprod.id} action='/admin/update_proproduct' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pe" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameEn} value={self.state.prod_name_en} name='name_en' type="text" className="form-control form-control-sm" id="InputENpep" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameFr} value={self.state.prod_name_fr} name='name_fr' type="text" className="form-control form-control-sm" id="InputFRpep" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescEn} value={self.state.prod_desc_en} name='desc_en' className="form-control form-control-sm" id="InputDescENep"  placeholder="Enter English Description" rows="3" required></textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescFr} value={self.state.prod_desc_fr} name='desc_fr' className="form-control form-control-sm" id="InputDescFRep"  placeholder="Enter French Description" rows="3" required></textarea>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('category')} className="form-check-input" type="radio" name="group" id="exampleRadios1ep" value="category" checked={this.state.prod_membertype === 'category' ? true : ''} /> {/**={this.state.membertype === 'category' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios1ep">
            Category
          </label>
          
        </div>
        <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option selected={thiscat.id === self.props.thisprod.memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('subcat')} className="form-check-input" type="radio" name="group" id="exampleRadios2ep" value="subcat" checked={this.state.prod_membertype === 'subcat' ? true : ''}/> {/**checked={this.state.membertype === 'subcat' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios2ep">
            Sub-Category
          </label>
          
        </div>
        <select name='subcat' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.subcategories.map(thiscat=>{
                return <option selected={thiscat.id === self.props.thisprod.memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_en} type="checkbox" name='show_en' className="custom-control-input" id="visiblepENep" checked={this.state.prod_show_en === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepENep">Show Eng</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_fr} type="checkbox" name='show_fr' className="custom-control-input" id="visiblepFRep" checked={this.state.prod_show_fr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepFRep">Show Frn</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_ca} type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANep" checked={this.state.prod_show_ca === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepCANep">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_us} type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSep" checked={this.state.prod_show_us === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepUSep">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_gl} type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLep" checked={this.state.prod_show_gl === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepGLep">Show Global</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_colorchart} type="checkbox" name='show_chart' className="custom-control-input" id="show_chartep" checked={this.state.prod_show_colorchart === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_chartep">Show Chart</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_brochure} type="checkbox" name='show_broc' className="custom-control-input" id="show_brocep" checked={this.state.prod_show_brochure === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_brocep">Show Brochure</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_instructions} type="checkbox" name='show_inst' className="custom-control-input" id="show_instep" checked={this.state.prod_show_instructions === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_instep">Show Instructions</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_productinfo} type="checkbox" name='show_info' className="custom-control-input" id="show_infoep" checked={this.state.prod_show_productinfo === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_infoep">Show Info</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_overview} type="checkbox" name='show_over' className="custom-control-input" id="show_overep" checked={this.state.prod_show_overview === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_overep">Show Overview</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_colorchartfr} type="checkbox" name='show_chartfr' className="custom-control-input" id="show_chartepfr" checked={this.state.prod_show_colorchartfr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_chartepfr">Show Fr.Chart</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_brochurefr} type="checkbox" name='show_brocfr' className="custom-control-input" id="show_brocepfr" checked={this.state.prod_show_brochurefr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_brocepfr">Show Fr.Brochure</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_instructionsfr} type="checkbox" name='show_instfr' className="custom-control-input" id="show_instepfr" checked={this.state.prod_show_instructionsfr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_instepfr">Show Fr.Instructions</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_productinfofr} type="checkbox" name='show_infofr' className="custom-control-input" id="show_infoepfr" checked={this.state.prod_show_productinfofr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_infoepfr">Show Fr.Info</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_overviewfr} type="checkbox" name='show_overfr' className="custom-control-input" id="show_overepfr" checked={this.state.prod_show_overviewfr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_overepfr">Show Fr.Overview</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_disclaimer} type="checkbox" name='disclaimer' className="custom-control-input" id="show_disclaimer" checked={this.state.prod_disclaimer === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_disclaimer">Show Disclaimer</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={this._ChangeOrder}type="number" name='sort' className="form-control form-control-sm" id="InputID2ep" aria-describedby="idHelp" placeholder='0' value={this.state.prod_order}/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit Updates</button>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_en_chartm' + self.props.thisprod.id} action='/admin/update_proproduct_en_chart' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnChart}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pecp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='chart_en' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_fr_chartm' + self.props.thisprod.id} action='/admin/update_proproduct_fr_chart' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrChart}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefcp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='chart_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_en_brocm' + self.props.thisprod.id} action='/admin/update_proproduct_en_broc' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnBroc}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pebp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Brochure</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='brochure_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_fr_brocm' + self.props.thisprod.id} action='/admin/update_proproduct_fr_broc' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrBroc}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefbp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Brochure</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='brochure_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_en_instm' + self.props.thisprod.id} action='/admin/update_proproduct_en_inst' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnInst}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peeip" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Instructions</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='instructions_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_fr_instm' + self.props.thisprod.id} action='/admin/update_proproduct_fr_inst' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrInst}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefip" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Instructions</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='instructions_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_en_infom' + self.props.thisprod.id} action='/admin/update_proproduct_en_info' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnInfo}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peepp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Product Info</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='productinfo_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_fr_infom' + self.props.thisprod.id} action='/admin/update_proproduct_fr_info' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrInfo}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefpp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Product Info</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='productinfo_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_en_overm' + self.props.thisprod.id} action='/admin/update_proproduct_en_over' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnOver}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peeop" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Overview</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='overview_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id={'editproproduct_fr_overm' + self.props.thisprod.id} action='/admin/update_proproduct_fr_over' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrOver}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefop" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Overview</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='overview_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        <hr className='my-1'></hr>
       {// <button type="submit" className="btn btn-primary">Submit</button>
        }
        </form>



                            </div>
                              }
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
        )
    }
}