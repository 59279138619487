import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class ArchitectComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div className='architectpagebg pagebgimg'>
         <div style={{height: '60px'}}></div>
         <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'60px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Architects & Designers':'Les Architectes'}</h1>
         <div style={{height: '1px'}}></div>
      </div>
      <div className='card bg-white border-0 rounded-0'>
      <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
            <div className='row justify-content-between mb-2 mt-5'>
            <span className='h6 card-title font-weight-bold col col-lg-8' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Product Information for Architects & Designers' : "Informations produit pour les architectes et les concepteurs"}</span>
           { this.props.lang === 'EN' ? <a role='button' className='btn btn-large btn-info product-button ml-auto col-lg-4' href={'./downloads/Interstar Concrete Pigment Product Specification Sheet.pdf'} target='_blank' rel='noopener noreferrer'>PRODUCT SPECIFICATION</a> : <div></div> }
            </div>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Pigments for Integrally Colored Ready Mix, Precast and Cast-in-Place Concrete' :
             "Pigments pour béton prêt à l'emploi, préfabriqué et coulé en place de couleur intégrée"}
             </p>
            <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Product Names' : "Noms de produits"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>
            {this.props.lang === 'EN' ?'Granastar® Granular Pigments for Concrete' :
             "Pigments granulaires Granastar®, pour béton"}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ?'Interstar Powder Pigments for Concrete' :
             "Pigments en poudre Interstar pour béton"}
             </p>

            

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Manufacturer' : "Le fabricant"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Interstar Pigments, Admixtures and Fibers' :
             "Pigments, adjuvants et fibres Interstar"}
             </p>
             {this.props.lang === 'EN' ? <><h6 className='card-title mb-1'>Addresses:</h6><div className='col col-md-6 text-left mb-2'>
              <h6 className='card-title mb-1'>USA:</h6>
              <div>530 Madison Avenue</div>
              <div>Junction City, Illinois  62882</div>
            </div>
              <div className='col col-md-6 text-left mb-2'>
                <h6 className='card-title mb-1'>Canada:</h6>
                <div>4255 Portland Boulevard</div>
                <div>Sherbrook, Quebec  J1L 3A5</div>
              </div></>
              : <><h6 className='card-title mb-1'>Adresse:</h6>
                <div className='col col-md-6 text-left mb-2'>
                  <div>4255, boulevard Portland</div>
                  <div>Sherbrooke, Québec J1L 3A5</div>
                </div></> }
             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Description' : "Description"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Granastar® granular pigment are pure iron oxides and are designed specifically for use in cementitious materials, from concrete products to ready mix applications. Granastar is a free-flowing, granular synthetic iron oxide which is ideal for use in automatic dispensing systems because it creates very low dust. Granastar granular pigment is certified ASTM C979 for integral coloring.' :
             "Les pigments granulaires pour le béton, Granastar®, sont spécialement formulés pour la coloration intégrale de matériaux cimentaires. Les pigments Granastar®, se dispersent facilement et rapidement dans votre gâchée de béton. Ils sont idéaux pour les dispensateurs granulaires parce qu’ils créent très peu de la poussière. Les pigments granulaires Granastar®, se conforment aux normes ASTM C979 pour la coloration intégrale du béton."}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Interstar Powder Pigments are pure iron oxide pigments designed to be used specifically in integral coloring of cementitious materials. Interstar colors are certified to ASTM C979 standard for integral coloring' :
             "Les pigments en poudre pour le béton sont faits de fer postindustriel recyclé. Ils sont spécialement formulés pour donner des couleurs vibrantes à toutes vos structures de béton. Les couleurs Interstar se conforment aux normes ASTM C979 pour la coloration intégrale du béton."}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Interstar’s pigments for integrally colored concrete are manufactured to exacting standards. All of our products undergo rigorous quality control procedures and tests to be 100% sure they meet or exceed our standards, which are the highest in the industry. ' :
             "Interstar fabrique des pigments pour le béton depuis plus de 30 ans. En particulier, nous avons mis au point un pigment granulaire pour l’utilisation dans tous les types de béton. Nous avons été les premiers à introduire le pigment granulaire sur le marché du béton préparé et nous avons transformé la façon dont les producteurs le préparent. Nous concevons et fabriquons tous nos propres distributeurs et avons des centaines de systèmes fonctionnant dans le monde entier, soutenus par près de deux décennies de fiabilité éprouvée."}
             </p>
             <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Interstar has been developing and manufacturing pigment for integrally colored concrete products for over 30 years. In particular, we have perfected granular pigment for ready mix concrete, and granular pigment dispensers. We design and manufacture all of our own dispensers, and have hundreds of systems operating worldwide, backed by nearly two decades of proven reliability.' :
             ""}
             </p>



             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Benefits' : "Avantages"}</h6>
             <p className='card-text mb-2' style={{fontSize:'17px'}}>
              <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? 'Economical' : 'Économique'}
            </span>
            {this.props.lang === 'EN' ?' - Interstar pigments are formulated to give high tinting strengths, meaning less pigment is needed to achieve the color you want.' :
             " - Les pigments Interstar sont formulés pour donner des forces de teinture élevées, ce qui signifie que moins de pigment est nécessaire pour obtenir la couleur souhaitée."}
             </p>

             <p className='card-text mb-2' style={{fontSize:'17px'}}>
              <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? 'Reliable' : 'Infaillible'}
            </span>
            {this.props.lang === 'EN' ?' – Interstar pigments deliver consistent results batch after batch.' :
             " - Les pigments Interstar fournissent des résultats cohérents lot après lot."}
             </p>

             <p className='card-text mb-2' style={{fontSize:'17px'}}>
              <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? 'Easy to Use' : 'Facile à utiliser'}
            </span>
            {this.props.lang === 'EN' ?' - All of Interstar’s pigments for ready mix concrete can be packaged in disintegrated bags that are simply placed into the ready mix truck – no extra work, mess or waste.' :
             " - tous les pigments d’Interstar pour béton préparé peuvent être emballés dans des sacs désintégrés qui sont simplement placés dans le camion prêt à l’emploi — pas de travail supplémentaire, de dégâts ou de déchets."}
             </p>

             <p className='card-text mb-2' style={{fontSize:'17px'}}>
              <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? 'Environmentally Friendly' : 'Écologique'}
            </span>
            {this.props.lang === 'EN' ?' – There is no waste. Interstar colors are 100% pigment. There are no totes that require special handling, or the need for costly circulating pumps.' :
             " - Il n’y a pas de déchets — 100 % de pigment. Il n’y a pas de bacs nécessitant une manipulation spéciale ou des pompes de circulation coûteuses"}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>
               {this.props.lang === 'EN' ?'Factors Affecting the Color of Concrete' : "Facteurs influant la couleur du béton"}</h6>
               {this.props.lang === 'EN' ? 
               <>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>Concrete is one of the most enduring building materials in the world. It has been used to construct some of history’s most iconic structures - The Colosseum, The Pantheon and Sydney Opera House – to name a few. Over the past fifty years, the use of integral color has transformed concrete from a basic building block to a decorative element.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>Integral color brings out concrete’s unique and natural beauty. Concrete is unique because no two pieces are the same. Many factors affect its appearance –mix design, finishing techniques, curing methods, color of aggregates, sand and cement, weather conditions, temperature, and the amount of water used.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>In turn, all of these things can affect its color. <span className='font-weight-bold'>Integral color is not like paint.</span> The results will not be uniform because concrete is not uniform. Integral iron oxide pigment enhances concrete’s uniqueness and is as durable as concrete, itself. It won’t fade or wear away.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>Concrete contractors and producers have learned how to manage these variables to transform dull, gray concrete into works of art. As a result, architects and designers are finding decorative concrete an attractive medium for both outdoor and indoor projects.</p>
    
               </> :
               <>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>Depuis des siècles, le béton est utilisé com me matériel de base pour la construction. II a été éprouvé à maintes reprises pour sa noblesse, sa solidité et com me l'un des matériaux les plus durables de l'histoire. Des monuments tels que le Panthéon, les pyramides d'Égypte ou l'infrastructure du Barrage Hoover sont des preuves de sa résistance et permanence.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>Originalement, le béton n'était qu'un matériel structural de base, mais au fil du temps, il est devenu un élément décoratif qui augmente la beauté, l'éclat et l'esthétisme. Quand la couleur intégrale est ajoutée au béton, les possibilités sont à la limite de la force créative. </p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>La couleur intégrale n'est pas opaque comme une peinture; les résultats obtenus ne seront pas uniformes puisque le béton lui-même n'est pas uniforme. Chaque projet de béton est unique et la couleur intégrale met en valeur les caractéristiques irrégulières.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>De nombreux facteurs influencent l'apparence du béton et ils peuvent aussi affecter la couleur, y compris mais sans s'y limiter: la conception du mélange, les techniques de finition, les méthodes de cure, la couleur des agrégats, du sable et du ciment, la quantité d'eau utilisée, les conditions météorologiques, ainsi que !'utilisation de scellant.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>Comme le béton décoratif gagne en popularité, les entrepreneurs et les producteurs de béton ont appris à gérer ces variables afin de transformer le béton gris, sans éclat, en de majestueuses réœuvres d'art de béton colore.</p>
               <p className='card-text mb-2' style={{fontSize:'17px'}}>De plus en plus, les architectes, les concepteurs et les producteurs de béton trouvent intéressant et attrayant de travailler avec le béton décoratif. En effet, du a sa force, sa beauté et son unicité, le béton est l'un des matériaux de construction les plus recherchés et utilisés dans le monde.</p>
               </>
               }
               {/*
             <p className='card-text mb-2' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Concrete is one of the most enduring building materials in the world. It has been used to construct some of history’s most iconic structures - The Colosseum, The Pantheon and Sydney Opera House – to name a few. Over the past fifty years, the use of integral color has transformed concrete from a basic building block to a decorative element.' :
             '' }
             </p>
             <p className='card-text mb-2' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Integral color brings out concrete’s unique and natural beauty. Concrete is unique because no two pieces are the same. Many factors affect its appearance –mix design; finishing techniques; curing methods, color of aggregates, sand and cement; weather conditions; temperature; amount of water used.' :
              '' }
             </p>
             {this.props.lang === 'EN' ?  <p className='card-text mb-2' style={{fontSize:'17px'}}>
             In turn, all of these things can affect its color. <span className='font-weight-bold'>Integral color is not like paint.</span> The results will not be uniform because concrete is not uniform. Integral iron oxide pigment enhances concrete’s uniqueness and is as durable as concrete, itself. It won’t fade or wear away.
             </p> : <p></p> }
             <p className='card-text mb-2' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Concrete contractors and producers have learned how to manage these variables to transform dull, gray concrete into works of art. As a result, architects and designers are finding decorative concrete an attractive medium for both outdoor and indoor projects. '
              : '' }
             </p>
                */
              }
           {/* <a href='./downloads/Factors Affecting Color_EN_web 2019.pdf' target='_blank' rel='noopener noreferrer'>Click here to see how various factors affect concrete</a>
             */}
             {this.props.lang === 'EN' ? 
              <a role='button' className='btn btn-lg btn-info product-button my-2' href='./downloads/Factors Affecting Color_EN_web 2019.pdf' target='_blank' rel='noopener noreferrer'>WHAT AFFECTS COLOR?</a> :
              <a role='button' className='btn btn-lg btn-info product-button my-2' href='./downloads/Factors Affecting Color_FR_web 2019.pdf' target='_blank' rel='noopener noreferrer'>Facteurs influençant la couleur du béton</a>}
             
             
             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?
             'Best Practices (especially for ready mix applications)' : "Les meilleures pratiques (en particulier, pour les applications de béton prépare)"}
             </h6>
             
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'Choosing a Color':'Choisir une couleur'}</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'ALWAYS pour a test sample with your mix design to be certain the desired color can be obtained.' :
              'Toujours faire un essai pour s’assurer que les résultats escomptés peuvent être obtenus.' }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'NEVER choose a color from an online color chart or from an electronic PDF. NEVER.' :
              "NE JAMAIS choisir une couleur dans une charte de la couleur en ligne ou à partir d'un PDF électronique. JAMAIS." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Professionally printed color charts should be used as a guideline ONLY.' :
              'Les chartes de la couleur imprimées par des professionnels doivent être utilisées UNIQUEMENT à titre indicatif.' }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Use an Interstar Ready Mix Color Kit to select a color.' :
              "Utilisez un kit d'échantillons de béton préparé d’Interstar pour sélectionner une couleur." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Select a color in the same lighting (e.g., indoors or outdoors) in which the concrete will be poured.' :
              "Sélectionnez une couleur dans le même éclairage (par exemple, à l'intérieur ou à l'extérieur) dans lequel le béton sera coulé." }
             </p>
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'General':'Général'}</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Always use a qualified decorative concrete contractor with experience in integrally colored concrete.' :
              "Faire appel à un contracteur qualité ayant de l’expérience dans le béton coloré intégrale." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'A test pour is highly recommended to make sure the results will meet your expectations.' :
              "Toujours faire un essai pour s’assurer que les résultats escomptés peuvent être obtenus." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Avoid projects that require several small pours.' :
              "Éviter les projets requérant plusieurs petites coulées (de la même couleur)." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Avoid pouring on different days.' :
              "Éviter d’effectuer des coulées réparties sur plusieurs jours." }
             </p>
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'Weather':'La météo'}</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Don’t pour concrete when rain (or any precipitation) is forecast within 48–72 hours.' :
              "Ne coulez pas de béton lorsque de la pluie ou des précipitations est prévue dans les 48 à 72 heures." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Don’t pour concrete when the temperature is below 50 °F (10 °C).' :
              "Ne pas couler de béton à une température inférieure à 10 °C (50 °F)." }
             </p>
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'Concrete':'Béton'}</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Use a mix design suitable for your specific application.' :
              "Utiliser un mélange à béton spécifique au type d’application." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Don’t use fly-ash or slag in the mix design. It will affect the color.' :
              "Ne pas utiliser de cendre volante ou ciment laitier. Cela altèrera la couleur." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Make sure that every truck contains the same number of yards/meters of concrete.' :
              "Veiller à ce que chaque camion contienne le même nombre de mètres de mélange à béton." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'NEVER ADD WATER to the ready mix truck on site. NEVER.' :
              "NE JAMAIS RAJOUTER D’EAU dans la bétonnière sur le chantier. JAMAIS." }
             </p>
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'Finishing':'La Finition'}</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'NEVER ADD WATER to the surface. NEVER.' :
              "Ne JAMAIS rajouter d’eau en surface. JAMAIS." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Make sure that the concrete is an even thickness throughout.' :
              "S'assurer que le béton ait une épaisseur uniforme sur toute la surface." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Finish all surfaces within the same timeframe after placing.' :
              "Après la mise en place, s’assurer que la durée de finition soit la même à tous les endroits." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Be sure the concrete is finished in the same direction when troweling.' :
              "S’assurer que la finition à la truelle soit effectuée dans le même sens." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Never finish with long-handled Fresno trowels.' :
              "Ne pas effectuer la finition avec une truelle métallique à long manche, de type Fresno." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Never use dirty or wet tools.' :
              "Toujours utiliser des outils propres et des balais neufs dans le cas d’une finition brossée." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Don’t over-finish because this may darken the surface.' :
              "Ne pas utiliser des outils souillés ou mouillés." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? '' :
              "Ne pas flatter excessivement la surface, car cela pourrait la foncer." }
             </p>
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'Curing':'Durcissement du béton'}</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Make sure that the concrete cures evenly and consistently.' :
              "S’assurer que la cure du béton s’effectue de façon constante et uniforme." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Make sure all areas dry at the same rate to avoid differences in color.' :
              "Ne pas laisser certains endroits séchés plus vites que d’autres d’éviter des variances de couleur." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Keep in mind that wind can affect the rate of curing and the final color of concrete.' :
              "" }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Keep in mind that concrete poured in shaded areas will be a different temperature than concrete poured in unshaded areas. This may result in color variations.' :
              "Des zones ombragées pourront engendrer des variations de couleur." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Don’t fog the surface with water.' :
              "Ne pas vaporiser la surface." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Don’t use wet coverings, plastic sheeting, waterproof paper or liquid membrane curing compounds.' :
              "Ne pas utiliser de revêtement humide, de bâches en plastique ou de papier imperméable comme méthode de cure." }
             </p>
             <h5 className='card-title mb-1'>{this.props.lang === 'EN' ? 'Sealing' : 'Scellage' }</h5>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Wait at least 36 hours before applying a cure & seal.' :
              "Attendez au moins 36 heures avant d'appliquer une cure chimique." }
             </p>
             <p className='card-text mb-2 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Allow the concrete to dry fully prior to application of sealer (approximately 30 days).' :
              "Laisser le béton complètement sécher avant d’appliquer un scellant (environ 30 jours)." }
             </p>
             <p className='card-text mb-3 ml-3' style={{fontSize:'17px'}}>
             {this.props.lang === 'EN' ? 'Never use a water-based sealer on stamped concrete.' :
              "N'utilisez jamais de scellant à base d'eau sur du béton estampé." }
             </p>
             
      </div>
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;