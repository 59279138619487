import React from 'react';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import colorCharts from '../data/colorCharts';
import ChartComponent from './ChartComponent.js';
import $ from 'jquery';

export default class HomeComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = { //set initial states
      colorCharts: colorCharts//Cookies.get('language') || 'EN'
    };
    this._getChartData = this._getChartData.bind(this);
    }

    componentDidMount() {
      window.scrollTo(0,0);
      this._getChartData();
    }

    _getChartData(){
      var self = this;
    //  console.log('Getting Chart Data Homepage');
      $.get('/data/charts',function(data,status,xhr){
    //    console.log('Chart Data Status: ' + status + ' Data: ' + JSON.stringify(data));
        if (data.length > 0) {
          self.setState({colorCharts: data});
        }
      },'json')  
    }
    render() {
      //var cookie_list = document.cookie;
     // var lang = cookie_list['language'] || 'FR';    //marginTop:'65vh',
      var self = this;
    return (
      <div>
        <nav style={{'zIndex': 900}} className="navbar navbar-expand navbar-light text-white bg-transparent navbar-grad-right">
          <div className="collapse navbar-collapse" id="navbarPhone">
            <ul className="navbar-nav ml-auto">
            {this.props.lang === 'EN' ? <li className="nav-item font-weight-bold">
               Contact Us: 800-567-1857 (M - F, 8 am - 5 pm EST), <a className='nav-item text-white'href='mailto:info@interstar-paf.com' style={{ cursor: 'pointer'}}>info@interstar-paf.com</a>
              </li> : <li className="nav-item font-weight-bold">
              Contactez nous: 800-567-1857  (L - V, 8 hr - 17hr EST), <a className='nav-item text-white'href='mailto:info@interstar-paf.com' style={{ cursor: 'pointer'}}>info@interstar-paf.com</a>
              </li>}
            </ul>
          </div>
        </nav>
      <div className='homepagebg pagebgimg'>
         <div style={{height: '60px'}}></div>
        <h1 className='text-white font-weight-bolder titleblock-header' style={{'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'We Are a Different Kind of Pigment Company':'Nous sommes une compagnie de pigment pour le béton différente des autres'}</h1>
        <h2 className='text-white mt-3' style={{'textShadow': '1px 1px black', fontSize:'30px', marginBottom:'60px'}}>{this.props.lang === 'EN' ? 'We Put Customers First - Plain and Simple' : 'Le client est notre priorité, tout simplement.'}</h2>
        <div style={{height: '40px'}}></div>
        </div>
        <div className='card card-white text-black rounded-0 border-0' style={{marginTop:'0px'}}>
        <div className='col-lg-8 col-xl-5 mx-auto' style={{marginTop:'-25px'}}>
          <div className='row d-flex justify-content-between'>
              <div className='col-sm-6 mb-2'>
              <NavLink to='/professionals' style={{maxWidth:'275px'}} className='btn btn-lg btn-block bg-warning text-white rounded-0 mx-auto'><i className="fas fa-chevron-circle-left fa-lg mx-2"></i>{this.props.lang === 'EN' ? '  PROFESSIONALS':'  PROFESSIONNELS'}</NavLink>
              </div>
              <div className='col-sm-6 mb-2'>
              <NavLink to='/consumers'  style={{maxWidth:'275px'}} className='btn btn-lg btn-block bg-success text-white rounded-0 mx-auto'>{this.props.lang === 'EN' ?'CONSUMERS  ' : 'CONSOMMATEURS'}<i className="fas fa-chevron-circle-right fa-lg mx-2"></i></NavLink>
              </div>
          </div>
        </div>
          <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto mt-5'>
            <h2 className='font-weight-bold'>{this.props.lang === 'EN' ? 'We Have Revolutionized Pigment for Concrete with Granastar® Granular Pigment.' : 'Nous avons révolutionné le pigment pour béton avec le pigment granulaire Granastar®.'}</h2>
            <h6 className='text-left card-title font-weight-bold mb-0 mt-5' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'THE ORIGINAL' : "L'ORIGINAL"}</h6>
            <p className='text-left card-text mb-5' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Interstar developed granular pigment and granular pigment dispensers over 20 years ago, and were the first to manufacture them for ready mix concrete. We’re the original.' : "Nous avons mis au point des dispensateurs de pigments granulaires et des pigments granulaires il y a plus de 20 ans. Nous avons été les premiers à les fabriquer pour le béton prêt à l'emploi. Nous sommes les pionniers."}</p>
            <h6 className='text-left card-title font-weight-bold mb-0 mt-5' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'PROVEN' : 'ÉPROUVÉ'}</h6>
            <p className='text-left card-text mb-5' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Our granular pigment is backed by over 20 years of tried-and-true experience. Our consistency and reliability are proven. We design and manufacture our own granular pigment dispensers, and we have more dispensers for ready mix concrete in the field than everyone else combined.' : "Notre pigment granulaire s'appuie sur plus de 20 ans d'expérience éprouvée. Notre cohérence et notre fiabilité sont éprouvées. Nous concevons et fabriquons nos propres dispensateurs de pigments granulaires et nous avons fourni plus de dispensateurs de pigment pour le béton prêt à l'emploi sur le terrain que tous les autres fabricants réunis."}</p>
            <h6 className='text-left card-title font-weight-bold mb-0 mt-5' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'RELIABLE' : "FIABLE"}</h6>
            <p className='text-left card-text mb-5' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'You can depend on us – from our time-tested and proven products to our unmatched customer service. We put customers first. This means when you call our offices, you speak to a person. When you have a question, we reply promptly. When you need something, you get it.' : "Vous pouvez compter sur nous – de nos produits éprouvés à notre service à la clientèle inégalé. Nos clients sont notre priorité : lorsque vous appelez nos bureaux vous parlez à une personne; lorsque vous avez une question nous y répondons rapidement et lorsque vous avez besoin de quelque chose vous l’obtenez."}</p>
            <h3 className='my-4 font-weight-bold' >{this.props.lang === 'EN' ?'Why go with an imitator when you can go with the originator?' : "Pourquoi choisir un imitateur quand vous pouvez choisir l’initiateur?"}</h3>
          </div>
          <div className='card text-black mt-4 border-0 rounded-0' style={{background:'#f0f0f0'}}>
            <div className='card-body col-sm-9 col-lg-9 col-xl-6 mx-auto'>
              <h3 className='font-weight-bold mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Color Charts' : 'Chartes de couleurs'}</h3>
              <p style={{fontSize:'17px'}}>
              <strong>{this.props.lang === 'EN' ? 'Disclaimer:' : 'Important:'}</strong>{this.props.lang === 'EN' ? ' These color charts are presented only as a guideline, and should not be used to make your final color selection. Computer screens and printers vary greatly and cannot accurately show the colors that will be obtained. The samples pictured here were developed in a controlled environment using materials that vary in different geographic locations. A mockup is highly recommended to make sure the colors you choose meet your expectations. To obtain a color chart or samples, please contact our Customer Service Department at ' : " Ces chartes de couleurs sont présentés à titre indicatif seulement et ne doivent pas être utilisés pour faire votre sélection finale. Les écrans de couleurs et les imprimantes varient considérablement et ne peuvent pas démontrés précisément les couleurs qui seront obtenues. Les échantillons représentés ont été développés dans un environnement contrôlé en utilisant des matériaux pouvant varier selon la région. Un essai préliminaire avec la couleur est fortement recommandé afin de s’assurer que le résultat obtenu corresponde à vos attentes. Veuillez contacter notre service à la clientèle à "}<a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a>{this.props.lang === 'EN' ? ' or contact an Interstar sales representative.' : " ou votre représentant des ventes afin d’obtenir une charte de couleur ou des échantillons."}
              </p>
              <div className='row'>
                <LazyLoad height={200} offset={100}>
                {self.state.colorCharts.map(itemData => <ChartComponent key={itemData.id} {...itemData} lang={self.props.lang}/>)}
                </LazyLoad>
              </div>
            </div>
          </div>
          <div className='card bg-white text-black mt-4 border-0 rounded-0'>
            <div className='card-body col-sm-10 col-lg-9 pt-0 mx-auto'>
              <div className='row'>
                <div className='col-xl-6 mt-4'>
                <LazyLoad height={200} offset={100} once>
                  <img className='img-fluid' style={{maxWidth:'100%'}} src='../img/home-img-22x.png' alt='about interstar'></img>
                </LazyLoad>
                </div>
                <div className='col-xl-6 text-left pt-2 my-auto'>
                <h3 className='font-weight-bold mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Interstar' : 'À propos de nous'}</h3>
                <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ? 'We have always been a forward-thinking company. We were the first to develop granular pigment for ready mix (Granastar® Granular Pigment) and to design, develop and manufacture granular pigment dispensing systems for the ready mix industry.' : "Interstar a toujours été une entreprise avant-gardiste. Nous avons été les premiers à développer des pigments granulaires pour le béton prêt à l'emploi (le pigment granulaire Granastar®) et à concevoir, développer et fabriquer des systèmes de dispensateurs de pigments granulaires pour l'industrie du béton prêt à l'emploi."}</p>
                <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ? 'While we embrace technology, we are still old school. We believe in responsive, personalized service. When you call our offices, you speak with a person, not a machine. Most orders received by 3 p.m. (ET) are shipped the same day. If you have a special request or need, we’ll bend over backwards to get you what you want.' : "Bien que nous adoptons la technologie, nous sommes toujours de la veille école. Nous croyons en un service réactif et personnalisé. Lorsque vous appelez nos bureaux, vous parlez à une personne et non à une machine. Les commandes sont expédiées le jour même de leur réception (si elles sont reçues avant 15 heures EST). Si vous avez des demandes spéciales, nous nous mettrons en quatre pour vous obtenir ce que vous voulez."}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  }

  //export default HomeComponent;