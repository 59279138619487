import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminConsSkuItem from './AdminConsSkuItem';

export default class AdminConsSkuEdit extends React.Component {
  componentDidMount() {
    
  }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Consumer SKU Edit</h5>
            <table class="table table-sm table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sort</th>
                    <th scope="col">English Title</th>
                    <th scope="col">French Title</th>
                    <th scope="col">Product</th>
                    <th scope="col">Image</th>
                    <th scope="col">Show Can</th>
                    <th scope="col">Show US</th>
                    <th scope="col">Show Global</th>
                    <th scope="col">Sale Can</th>
                    <th scope="col">Sale US</th>
                    <th scope="col">Sale Global</th>
                    <th scope="col">Price CAD</th>
                    <th scope="col">Price USD</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                    {
                        this.props.sku.map(thissku => 
                            <AdminConsSkuItem products={self.props.products} sku={thissku} />    
                        )
                    }
                </tbody>
            </table>


      </div>
    )
    }
  };