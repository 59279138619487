import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import { useTracking } from './useTracking'

import './App.css';
import HomeComponent from './components/HomeComponent';
import AboutComponent from './components/AboutComponent';
import ProfessionalPage from './components/ProfessionalPage';
import ConsumerPage from './components/ConsumerPage';
import ArchitectComponent from './components/ArchitectComponent';
import ServicesPage from './components/ServicesPage';
import MarketingPage from './components/MarketingPage';
import GalleryPage from './components/GalleryPage';
import FAQPage from './components/FAQPage';
import DownloadsPage from './components/DownloadsPage';
import ContactPage from './components/ContactPage';
import ColorCharts from './components/ColorChartPage';
import LegalPage from './components/LegalPage';
import ShippingPage from './components/ShippingPage';
import PrivacyPage from './components/PrivacyPage';
import NotFoundComponent from './components/NotFoundComponent';
import AdminPage from './components/AdminPage';
import ProdInfoPage from './components/ProdInfoPage';
import ScrollToTop from './components/ScrollToTop';
import Cookies from 'js-cookie';

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      lang: Cookies.get('language') || 'EN',
      country: Cookies.get('country') || 'XX'
    };
    this._setLanguage = this._setLanguage.bind(this);
  }

  componentDidMount(){
  //  useTracking('UA-38414084-3');
  //  setTimeout(function(){
  //    var cookie_list = document.cookie;
    var lang = Cookies.get('language'); //cookie_list['language']; //document.cookie['language'];
    var country = Cookies.get('country');
   // console.log('Document.cookie[language]: ' + lang);
    this.setState({lang: lang, country: country});
  //  },3000);


  }
  componentDidUpdate() {
    window.scrollTo(0,0);
}
  _setLanguage(lang){
    var self = this;
    return function(){
      if (self.state.lang === 'EN') {
      //  console.log('1');
        document.cookie = 'language=FR; max-age=1209600000'
        self.setState({lang:'FR'});
      } else {
      //  console.log('2');
        document.cookie = 'language=EN; max-age=1209600000'
        self.setState({lang:'EN'});
      }
      //console.log('setting language to: ' + lang);
      //document.cookie = 'language=' + lang;
      //self.setState({lang:lang});
     // self.forceUpdate();
    }
  }
  render() {
    //useTracking('UA-38414084-3');
  //var cookie_list = document.cookie;
  var lang = 'EN';
  if (this.state.lang === 'EN') {
    lang = 'FR';
  } else {
    lang = 'EN'; 
  }
  // var lang = this.state.lang//cookie_list['language'] || 'EN';
  //console.log('Country: ' + this.state.country);
  return (
    <div className="App">
    {//  <Router>
      }   <header>
          <nav className="navbar navbar-expand-lg navbar-light text-white bg-transparent navbar-grad-top border-bottom border-white px-0 py-0">
          <div style={{height:'70px', width:'300px', background:'white' }}><NavLink to="/" className="navbar-brand px-0 py-0"><img className='mx-auto' src={ this.state.lang === 'EN' ? '/img/Interstar logo for web_EN.png' : '/img/Interstar logo for web_FR.png'} alt='Interstar' style={{'maxHeight':'50px',marginTop:'10px'}}></img></NavLink></div>
          <button className="navbar-toggler bg-light" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/about' exact activeClassName='active' className='nav-link text-white' >{this.state.lang === 'EN' ? 'ABOUT US' : 'À PROPOS'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/charts' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'COLOR CHARTS' : 'CHARTES DE COULEURS'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/professionals' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'PROFESSIONALS' : 'PROFESSIONNELS'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/consumers' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'CONSUMERS' : 'CONSOMMATEURS'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/architects' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'ARCHITECTS & DESIGNERS' : 'LES ARCHITECTES'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/services' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'SERVICES' : 'SERVICES'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/gallery' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'GALLERY' : 'GALERIE'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/faq' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'FAQ' : 'FAQ'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/downloads' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'DOWNLOADS' : 'TÉLÉCHARGEMENTS'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <NavLink to='/contact' exact activeClassName='active' className='nav-link text-white'>{this.state.lang === 'EN' ? 'CONTACT US' : 'CONTACT'}</NavLink>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
              { this.state.lang === 'EN' ? 
                // <a href='http://www.interstar-marketing.com/' className='nav-link text-white' target='_blank' rel='noopener noreferrer' >MARKETING</a>
                <NavLink to='/marketing' exact activeClassName='active' className='nav-link text-white'>MARKETING</NavLink>
                : '' }
              </li>
             {/* <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <div className='nav-link text-white purechat-button-expand' style={{ cursor: 'pointer'}}><i className="fas fa-comments"></i>{this.state.lang === 'EN' ? ' CHAT' : ' SUPPORT EN LIGNE'}</div>
              </li> */}
              <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent">
                <div className='nav-link text-white' style={{ cursor: 'pointer'}} onClick={lang === 'EN' ? this._setLanguage('FR') : this._setLanguage('EN')}>{lang}</div>
              </li>
            </ul>
          </div>
        </nav>
       {/* <nav style={{'zIndex': 900}} className="navbar navbar-expand navbar-light text-white bg-transparent navbar-grad-right">
          <div className="collapse navbar-collapse" id="navbarPhone">
            <ul className="navbar-nav ml-auto">
            {lang === 'FR' ? <li className="nav-item font-weight-bold">
               Contact Us: 800-567-1857 (M - F, 8 am - 5 pm EST), <a className='nav-item text-white'href='mailto:info@interstar-paf.com' style={{ cursor: 'pointer'}}>info@interstar-paf.com</a>
              </li> : <li className="nav-item font-weight-bold">
              Contactez nous: 800-567-1857  (L - V, 8 hr - 17hr EST), <a className='nav-item text-white'href='mailto:info@interstar-paf.com' style={{ cursor: 'pointer'}}>info@interstar-paf.com</a>
              </li>}
            </ul>
          </div>
            </nav> */}
        </header>
        <main>
            <Switch>
              <Route path='/' exact render={() =><HomeComponent lang={this.state.lang}/> }/>
              <Route path='/about' render={() =><AboutComponent lang={this.state.lang}/>}/>
              <Route path='/charts' render={() =><ColorCharts lang={this.state.lang}/>}/>
              <Route path='/professionals' render={() =><ProfessionalPage lang={this.state.lang} country={this.state.country}/>}/>
              <Route path='/consumers' render={() =><ConsumerPage lang={this.state.lang} country={this.state.country}/>}/>
              <Route path='/architects' render={() =><ArchitectComponent lang={this.state.lang} country={this.state.country}/>}/>
              <Route path='/services' render={() =><ServicesPage lang={this.state.lang}/>}/>
              <Route path='/gallery' render={() =><GalleryPage lang={this.state.lang}/>}/>
              <Route path='/faq' render={() =><FAQPage lang={this.state.lang}/>}/>
              <Route path='/downloads' exact render={() =><DownloadsPage lang={this.state.lang}/>}/>
              <Route path='/contact' render={() =><ContactPage lang={this.state.lang}/>}/>
              <Route path='/marketing' render={() =><MarketingPage lang={this.state.lang}/>}/>
              <Route path='/warranty-legal-notices' render={() =><LegalPage lang={this.state.lang}/>}/>
              <Route path='/shipping-terms' render={() =><ShippingPage lang={this.state.lang} country={this.state.country}/>}/>
              <Route path='/privacy' render={() =><PrivacyPage lang={this.state.lang} country={this.state.country}/>}/>
              <Route path='/Product/Info/:id' render={() =><ProdInfoPage lang={this.state.lang} />}/>
              <Route path='/admin' render={() =><AdminPage/>}/>
              <Route render={() =><NotFoundComponent lang={this.state.lang}/>} />
            </Switch>
        </main>
        <ScrollToTop lang={this.state.lang}/>
        <footer className='text-white text-sm-left mt-4' style={{background:'#231f20'}}>
        <div className='col-xl-6 mx-auto py-4'>
        <div className= 'd-flex flex-column flex-sm-row justify-content-between border-bottom'>
          <div className='col-sm-2 px-0'>
          <nav className='nav flex-column'>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Home' : 'Accueil'}</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/charts' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Color Charts' : 'Chartes de Couleurs'}</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/professionals' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Professionals' : 'Professionnels' }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/consumers' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Consumers' : 'Consommateurs' }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/architects' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Architects & Designers' : 'Les Architectes' }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/services' exact activeClassName='active'>Services</NavLink>
          </nav>
          </div>
          <div className='col-sm-2 px-0'>
          <nav className='nav flex-column'>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/faq' exact activeClassName='active'>FAQ</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/downloads' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Downloads' : 'Téléchargements' }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/contact' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Contact Us' : 'Contact' }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/marketing' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Marketing' : 'Marketing' }</NavLink>
            {/* this.state.lang === 'EN' ? <a className='flex-sm-fill nav-link text-white py-1 px-1' href='http://www.interstar-marketing.com/'>Marketing</a> : ''
            */}
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/about' exact activeClassName='active'>{this.state.lang === 'EN' ? 'About Us' : 'À Propos' }</NavLink>
          </nav>
          </div>
          <div className='col-sm-2 px-0'>
          <nav className='nav flex-column'>
          {/*  <NavLink className='flex-sm-fill nav-link text-white purechat-button-expand py-1 px-1' to='/' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Chat With Us' : 'Support en Ligne'} </NavLink>
            */}
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/warranty-legal-notices' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Warranty & Legal Notices' : 'Garantie et Avis juridique' }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/shipping-terms' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Shipping and Return Policies' : "Politiques d'expédition et de retour" }</NavLink>
            <NavLink className='flex-sm-fill nav-link text-white py-1 px-1' to='/privacy' exact activeClassName='active'>{this.state.lang === 'EN' ? 'Privacy Policy' : "Politique de Confidentialité" }</NavLink>
          </nav>
          </div>
          <div className="card col-sm-6 col-xl-5 text-white rounded-0 border-left border-bottom border-right-0 border-top-0 mt-2" style={{background:'#231f20'}}>
              <div style={{height:'90px'}}>
              <img className='img-fluid' style={{maxWidth:'400px', maxHeight:'100%'}} src={this.state.lang === 'EN' ? '/img/logo-white-footer2x-p-300.png' : '/img/interstar-fr-logowhite.png'} alt='logo'></img>
              </div>
              <p className='mt-2 mb-1' style={{fontSize:'17px'}}>{this.state.lang === 'EN' ? 'For easy, hassle-free ordering, please call us at ' : "Pour une commande facile et sans problème, veuillez nous appeler au "}</p>
              <p><strong>800-567-1857</strong></p>
              {this.state.lang === 'EN' ? <nav className='nav'>
                <a className="nav-link text-white" href="https://www.instagram.com/interstarcolors/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram fa-2x"></i></a>
                <a className="nav-link text-white" href="https://twitter.com/interstarcolors" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square fa-2x"></i></a>
                <a className="nav-link text-white" href="https://www.facebook.com/pages/Interstar-Pigments-Admixtures-Fibers/95602287544" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square fa-2x"></i></a>
              </nav> : <nav className='nav'>
                <a className="nav-link text-white" href="https://www.facebook.com/pg/Les-Materiaux-Interstar-165373299829013" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square fa-2x"></i></a>
              </nav>}
          </div>
        </div>
        <div className='col-sm-12'><div className='mt-3'>{this.state.lang === 'EN' ? 'Copyright Interstar Materials, Inc. 2009-2023' : "Tous droits réservés © Matériaux Interstar, Inc. 2009 - 2023"}</div></div>
        </div>
        </footer>
     {// </Router>
  }</div>
  );
  }
}

export const Wedge = () => {
  useTracking('G-GWC625HF8W');
  return (
    <App/>
  )

}


export default () => (
    <Router>
      <Wedge/>
    </Router>
)
