import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminProCatCreate extends React.Component {
  componentDidMount() {
    
  }
  _onSubmit(e) {
    e.preventDefault();
    
    var form = $('#createprocat')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 600000,
        success: function (data) {
        // $("#result").text(data);
            alert("SUCCESS : " + data);
        //  $("#btnSubmit").prop("disabled", false);
        $('#createprocat')[0].reset();
        //$('#contactForm')[0].reset();
        },
        error: function (err) {
        // $("#result").text(e.responseText);
            alert("ERROR : " + JSON.stringify(err));
        //  $("#btnSubmit").prop("disabled", false);
        }
    });
    }
  render() {
    return (
      <div>
          <h5 className="card-title text-center">Category</h5>
        <form id="createprocat" action='/admin/createprocat' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control" id="InputID1c" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control" id="InputENc" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control" id="InputFRc" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
    <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_en' className="form-control-file" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_ca' className="custom-control-input" id="visibleCAN"/>
            <label className="custom-control-label" htmlFor="visibleCAN">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_us' className="custom-control-input" id="visibleUS"/>
            <label className="custom-control-label" htmlFor="visibleUS">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_gl' className="custom-control-input" id="visibleGL"/>
            <label className="custom-control-label" htmlFor="visibleGL">Show Global</label>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control" id="Inputsortc" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };