import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class LegalPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         marginTop:'-170px'}}>
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Page Not Found':'Page Not Found'}</h1>
      </div>
      <div className='card bg-white border-0 rounded-0'>
       { this.props.lang === 'EN' ? <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
       <h3>404 Not Found</h3>
        
      </div> : <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
              <h3>404 Not Found</h3>
          </div> }
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;