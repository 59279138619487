import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class FAQPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div className='faqpagebg pagebgimg'>
          <div style={{height: '50px'}}></div>
         <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'50px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'FAQs':'FAQs'}</h1>
         <div style={{height: '1px'}}></div>
      </div>
      <div className="accordion col-sm-9 col-lg-9 col-xl-7 mx-auto mt-4" id="accordionFAQ">
        <div className="card text-left rounded-0 border-0">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingOne"  data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
              {this.props.lang === 'EN' ? 'What is integral concrete color?' : "Qu’est-ce que la couleur intégrale pour le béton?"}
            </h4>
          </div>
          <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionFAQ">
            <div className="card-body">
              <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'It is color (made from iron oxide pigments) that is added to the concrete when it is being made, either in a ready mix plant or onsite in a ready mix truck. The color is evenly dispersed throughout, and integrated into the concrete.The entire batch of concrete will be evenly colored inside and out.' : 
               "C'est de la couleur (pigment à base d'oxyde de fer) qui est ajoutée au béton pendant la préparation du béton, soit dans une usine de béton prêt à l'emploi, soit dans la bétonnière au moment de la coulée. La couleur est dispersée uniformément dans le béton. L'ensemble du béton sera coloré, de l'intérieur à l'extérieur."}
               </p>
              <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'One major advantage of integrally colored concrete is that if it chips or is damaged, it is much less noticeable because the concrete is colored all the way through.' :
               "Un des principaux avantages du béton intégralement coloré est que, s'il est fissuré ou autrement endommagé, le défaut sera beaucoup moins visible car le béton est coloré en profondeur."}
              </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingTwo"  data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Does integral color fade?' : "Est-ce que la couleur intégrale déteint?"}
            </h4>
          </div>
          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'No. Iron oxide pigments cannot fade. Concrete, however, can change over time with exposure to the environment (weather, sun, foot or wheeled traffic, etc.). To reduce the effects of wear on concrete, it is important to use a durable mix design, an experienced concrete finisher and a high-quality concrete sealer that is suitable for the specific application (e.g., driveway, patio, garage, etc.). Colored concrete can be refreshed by power-washing and resealing the surface.' : 
               "Non, les pigments d'oxyde de fer ne peuvent pas déteindre. Néanmoins, la couleur du béton elle-même peut changer avec le temps à cause de l'exposition à l'environnement (conditions météorologiques, le soleil, la circulation piétonnière ou à roues, etc.). Afin de réduire les effets de l'usure sur le béton, il est important d'utiliser un mélange durable, engager un finisseur de béton ayant de l'expérience dans le béton coloré intégralement et un scellant pour béton de haute qualité, adapté à l'application spécifique (entrée, terrasse, garage, etc.). Le béton coloré peut être rafraîchi en lavant et en scellant à nouveau la surface."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingThree"  data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Is integral color difficult to use?' : "La couleur intégrale est-elle difficile à utiliser?"}
            </h4>
          </div>
          <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'Not at all. Interstar makes it easy with our automated granular pigment dispensers and pre-packaged bags of ready mix pigment. However, we strongly recommend using a skilled and experienced concrete finisher. Finishing techniques will affect the final color, so it is important to use a qualified contractor.' : 
               "Pas du tout. Interstar vous facilite la tâche avec nos dispensateurs de pigments granulaires et nos sacs prédosés de pigment pour béton préparé. Cependant, nous recommandons fortement d’engager un finisseur de béton qualifié ayant de l'expérience dans le béton coloré intégralement. Les techniques de finition affectent la couleur finale, il est donc important de faire appel à un entrepreneur qualifié."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingFour"  data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Will the color I choose look exactly like the swatch on the color chart?' : "Est-ce que la couleur que je choisis ressemblera-t-elle exactement à celle dans la charte de couleurs?"}
            </h4>
          </div>
          <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'No. Concrete is a unique material – no two pieces are the same. The color of concrete –even plain concrete – is affected by many, many factors such as: cement powder and sand color; aggregate color and size; the amount of water used; and finishing methods. Even though Interstar Marketing painstakingly calibrates every color swatch to an actual colored concrete sample to try to make our color charts as accurate as possible, it might not be an exact match to your concrete. We highly recommend pouring a mock-up to be sure you can achieve the desired color with the raw materials and finishing techniques you will be using. ' : 
               "Non. Le béton est un matériau unique; il n'y a pas deux pièces identiques. La couleur du béton - même du béton ordinaire - est affectée par de nombreux facteurs tels que: la couleur de la poudre de ciment et du sable; la couleur et taille des agrégats; la quantité d'eau utilisée et les méthodes de finition. Même si Interstar Marketing calibre minutieusement chaque image dans la charte de couleurs avec un échantillon de béton coloré afin de rendre nos chartes aussi précises que possible, il est pratiquement impossible que la couleur finale du béton corresponde exactement à la charte de couleurs. Nous vous recommandons fortement de préparer une maquette pour vous assurer que vous pouvez obtenir la couleur souhaitée avec les matières premières et les techniques de finition que vous utiliserez."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingFive"  data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'My colored concrete was poured a week ago and it is much darker than the color I chose. Is there a problem?' : "Mon béton coloré a été coulé il y a une semaine et il est beaucoup plus foncé que la couleur que j'ai choisie. Y-a-t-il un problème?"}
            </h4>
          </div>
          <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'No, this is perfectly normal. It takes at least 30 days for all of the moisture to evaporate from freshly poured concrete. During this time, the color will continue to lighten. Some areas may even appear lighter than others due to faster evaporation. Wait at least 30 days before the final color inspection.' : 
               "Il faut permettre au moins 30 jours pour que l'humidité s'évapore complètement du béton fraîchement coulé. Pendant ce temps, la couleur continuera à pâlir. Certaines zones peuvent même paraître plus claires que d'autres en raison d'une évaporation plus rapide. Attendez au moins 30 jours avant l'inspection finale de la couleur."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingSix"  data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Should colored concrete be sealed?' : "Le béton coloré devrait-il être scellé?"}
            </h4>
          </div>
          <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'Yes. Even though concrete is extremely durable, it is still susceptible to the environment. A high-quality sealer should be applied at least 30 days after the concrete has been poured. Please keep in mind that some sealers will impart a color, and most will slightly darken the color. Always test it in an inconspicuous place before applying to the entire surface. Interstar has many sealers for various applications that are durable and protective.' : 
               "Oui. Bien que le béton soit extrêmement durable, il reste sensible à l'environnement. Un scellant de haute qualité doit être appliqué au moins 30 jours après la coulée du béton. N'oubliez pas que certains scellants vont modifier la couleur et que la plupart le rendront légèrement plus foncé. Il est fortement recommandé de faire un test dans un endroit peu visible avant de l'appliquer sur toute la surface. Interstar a de nombreux scellants pour diverses applications qui sont durables et résistants."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingSeven"  data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'I received my order of integral color and it looks different than the color I chose. Is the dry pigment representative of the final color?' : "J'ai reçu ma commande de couleur intégrale et elle est différente de celle que j'ai choisie. Le pigment sec est-il représentatif de la couleur finale?"}
            </h4>
          </div>
          <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'No. The dry color is not reflective of the final result. It must be mixed with concrete. We create our color charts using photos of actual samples of colored concrete.' : 
               "Non, la couleur sèche ne reflète pas le résultat final. Il doit être mélangé avec du béton. Nous créons nos chartes de couleurs avec des photos calibrées de vrais échantillons de béton coloré."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingEight"  data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Are air-entraining admixtures compatible with integral color?' : "Les mélanges qui contiennent un agent d’entraînement d’air sont-ils compatibles avec la couleur intégrale?"}
            </h4>
          </div>
          <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'Yes' : 
               "Oui."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingNine"  data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Do admixtures affect colored concrete?' : "Les adjuvants peuvent-ils affecter le béton coloré?"}
            </h4>
          </div>
          <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'They can, but consistency is the key to mitigate their effects. The same admixture(s) should be used at the same dosages throughout a given job. In fact, every component of the mix design must be the same throughout a pour to help ensure consistent color. Calcium chloride accelerators should NEVER be used in colored concrete.' : 
               "C'est possible, mais la cohérence est la clé pour atténuer leurs effets. Le dosage doit demeurer constant tout au long d’un projet. En fait, chaque composant du mélange doit être identique tout au long de la coulée pour garantir une couleur homogène. Les accélérateurs de chlorure de calcium ne doivent JAMAIS être utilisés dans du béton coloré."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingTen"  data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'What happens if I pour colored concrete on separate days?' : "Que se passe-t-il si je coule du béton coloré sur plusieurs jours?"}
            </h4>
          </div>
          <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'There could be color differences, which is why pouring on different days should be avoided, if possible.' : 
               "Il pourrait y avoir des différences de couleur, ce qui explique pourquoi il est préférable d’éviter, si possible, des coulées sur des jours différents."}
               </p>
            </div>
          </div>
        </div>
        <div className="card text-left rounded-0 border-right-0 border-left-0 border-bottom-0 border-top">
          <div style={{cursor:'pointer'}} className="card-header bg-white collapsed" id="headingEleven"  data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
            <h4 className="mb-0 font-weight-bold my-3" style={{cursor:'pointer'}}>
            {this.props.lang === 'EN' ? 'Can I just add pigment to the surface of the concrete and mix it in?' : "Puis-je simplement ajouter un pigment à la surface du béton et le mélanger?"}
            </h4>
          </div>
          <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordionFAQ">
            <div className="card-body">
            <p style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?
               'No. Integral color must be added to the mix BEFORE pouring and placing the concrete. Interstar manufactures two topical colorants: Interstar Color Hardener and NStar, a non-reactive stain. Color hardener is used as a highlight color that is broadcast onto the surface of freshly placed concrete, and then floated into the concrete. NStar is a non-reactive stain that bonds to unsealed, cured concrete creating long-lasting, maintenance-free, translucent colors.' : 
               "Non. La couleur intégrale doit être ajoutée au mélange AVANT de verser et de mettre en place le béton. Si la coloration de surface est un option pour vous, Interstar fabrique deux colorants topiques: le Durcisseur Coloré Interstar et NStar, une teinture non réactive."}
               </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;