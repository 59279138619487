import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class AboutComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div className='aboutpagebg pagebgimg'>
         <div style={{height: '60px'}}></div>
         <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'60px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Corporate Philosophy':'À propos'}</h1>
         <div style={{height: '1px'}}></div>
      </div>
      <div className='card bg-white border-0 rounded-0'>
      <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
            <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'About Us' : "À propos de nous"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We’ve leveraged our expertise in the concrete industry to offer you more than just products. Our color and concrete experts, technical support and professional marketing services will help you grow your business. Focus on what you do best, and we’ll provide you with the tools and support you need to get the job done.' :
             "Nous avons su tirer profit de notre expérience dans l’industrie du béton pour vous offrir plus que des produits. Nos experts de la couleur et du béton, notre soutien technique, et nos services professionnels de marketing vous aideront à faire croître votre entreprise. Gardez le cap sur vos objectifs et nous vous fournirons les outils et le soutien dont vous aurez besoin pour réaliser le travail."}
             </p>
            <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Interstar’s Credo' : "Credo d’Interstar"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>
              <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? '1. Live by the Golden Rule:' : '1. Suivre la règle d’or:'}
            </span>
            {this.props.lang === 'EN' ?' We treat others as we want to be treated.' :
             " Nous traitons les autres comme nous voulons être traités."}
             </p>
            
             
            <p className='card-text mb-2' style={{fontSize:'17px'}}>
            <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? '2. Be Innovative:' : '2. Soyez innovant:'}
             </span>
             <span className='font-italic mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? ' Innovation ' : ''}
             </span>
             {this.props.lang === 'EN' ?' has become a cliché for many, but not for us. It’s the foundation on which Interstar was built. We develop products and services that are unique, reliable and add value for our customers.  ' :
             " L’innovation peut être clichée pour certains, mais pas pour nous. Elle est l’idée selon laquelle Interstar a été pensé. Nous développons des produits et des services uniques, fiables et à valeur ajoutée pour nos clients."}
             </p>

             

            <p className='card-text mb-2' style={{fontSize:'17px'}}>
            <span className='font-weight-bold mb-2' style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? '3. Keep Promises:' : '3. Tenir ses promesses:'}
             </span>
             {this.props.lang === 'EN' ?' We do what we say we’ll do – period.' :
             " Si nous le disons, nous le ferons – point final."}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Our Employees' : "Nos employés"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'At Interstar, our employees are our number one asset - their hard work and dedication have made our success possible. We encourage and maintain a team approach in every aspect of our company, the foundation of which is based on open communication, information sharing and most importantly, respect.' :
             "Chez Interstar, nos employés représentent notre valeur première - leur travail et leur loyauté ont rendu notre succès possible. Nous favorisons une approche « équipe » dans tous les secteurs de l’entreprise; ceci représente la base même du succès permettant d’intégrer la communication, le partage d’informations et plus spécifiquement le respect."}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We support and promote personal and professional development. We encourage our employees to obtain the highest levels of education possible, for we truly believe that education is the key to success. Toward this end, we invest in our employees, and require a minimum of 40 hours of training per year - whether it is a university course or a one-day seminar on a particular topic related to their job.' :
             "Nous encourageons le développement personnel et professionnel. Nous offrons à notre équipe la possibilité d’obtenir un niveau d’éducation supérieur car nous croyons sincèrement que l’éducation est la clé du succès. À cet effet, nous investissons dans notre équipe en exigeant un minimum de 40 heures par année de formation pour chacun, que ce soit pour un cours universitaire ou un atelier d’un jour sur un sujet relié à leur travail."}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We have the utmost concern for the safety and well-being of our employees and maintain a safe and orderly work environment.' :
             "La sécurité et le bien-être de notre équipe est une priorité et c’est pourquoi nous maintenons un environnement sécuritaire à leur intention. "}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Our Customers' : "Nos clients"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We treat our customers the way we, ourselves, want to be treated. We are responsive, flexible, and we strive to get things right the first time, every time.' :
             "Nous traitons nos clients comme nous voulons être traités. Nous sommes réactifs, flexibles et nous nous efforçons de bien faire les choses la première fois, et ce, à chaque fois."}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Everything we do is centered on our customers’ current and future needs. To understand these needs, we maintain direct and open lines of communication. We are only a phone call or email away.' :
             "Tout ce que nous faisons est centré sur les besoins actuels et futurs de nos clients. Pour comprendre ces besoins, nous maintenons des lignes de communication directes et ouvertes. Nous ne sommes qu’à un coup de téléphone ou un courriel."}
             </p>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Our ‘anything-can-be-done’ philosophy means that we find ways to address the unique needs of our customers. We are not afraid to try new things, whether this means developing new products, novel packaging, custom colors, or state-of-the-art dispensers.' :
             "Notre philosophie prônant que « tout est possible » signifie que nous employons les moyens nécessaires dans le but de répondre aux besoins uniques de nos clients. Nous n’avons pas peur d’essayer de nouvelles choses, qu’il s’agisse de développer de nouveaux produits, de nouveaux emballages, des couleurs personnalisées ou encore des dispensateurs à la fine pointe de la technologie."}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'The Environment' : "Environnement"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We believe that taking care of the environment is as important as taking care of people. Interstar’s state-of-the-art facility in Sherbrooke, Québec was designed with a deep concern for the environment in mind. There are many design features within the manufacturing facility that safeguard the environment and preserve natural resources. For example, it is a zero-discharge facility, meaning there are no by-products from the manufacturing processes that are released into the atmosphere; it utilizes a state-of-the art air filtration system that collects dust created from the manufacturing of pigment; the heat produced in the manufacturing facility is recycled and used as a primary source of heat in the winter; and all waste water is purified and reused.' :
             "Nous croyons sincèrement que de prendre soin de l’environnement est tout aussi important que de prendre soin des gens. Notre usine de Sherbrooke au Québec a été conçue en fonction de cette profonde croyance. Plusieurs caractéristiques dans l’usine démontrent à quel point la sauvegarde de l’environnement représente une priorité, tout en préservant les ressources naturelles. À titre d'exemple, notre usine est une usine zéro-déchet. C’est-à-dire que durant le processus de fabrication, aucune particule de poussière est libérée dans l'atmosphère; le système de filtration de haute technologie récupère cette poussière produite par la fabrication de pigments. La chaleur produite par la fabrication est récupérée et réutilisée comme source primaire de chauffage interne durant la période hivernale. Les eaux usées sont recyclées et réutilisées."}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'The Community' : "La Communauté"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'Our company reflects the demographics of communities in which we operate. We are very proud to have a staff that is comprised of individuals from all parts of the globe – Africa, South America, North America and Europe. Similarly, we are delighted to be able to offer people with disabilities a place where they can be accepted as part of a team, learn new skills, and hone those they already have while being paid fair and equitable wages for their efforts. We provide training, a supportive environment and competitive wages to individuals who may not otherwise find a place of employment.' :
             "Notre entreprise est vraiment le reflet des communautés dans lesquelles nous sommes présents. Nous sommes très fiers de pouvoir compter sur une équipe qui nous vient de partout dans le monde : Afrique, Amérique du Sud, Amérique du Nord et Europe. Dans la même veine, nous sommes fiers de pouvoir offrir aux personnes ayant des déficiences une chance d’intégrer le marché du travail, en tant que membre à part entière pour qu’ils puissent accéder à une intégration tout en étant payé selon les normes acceptables de l’industrie et tout en respectant leurs efforts. Nous fournissons de la formation, un environnement motivant et des salaires compétitifs pour cette catégorie de gens qui ont, autrement, de la difficulté à intégrer le marché du travail."}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Suppliers' : "Nos fournisseurs"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We are a supplier to hundreds of companies, so we understand how challenging it is to maintain a competitive edge while ensuring customer satisfaction. We expect from our suppliers what we provide to our own customers: top-quality products and services at a reasonable price. We pledge to always treat our suppliers with courtesy and respect.' :
             "Nous sommes un fournisseur de choix pour plusieurs centaines d’entreprises, donc nous comprenons combien il est difficile de conserver un avantage concurrentiel tout en garantissant la satisfaction du client. Nous attendons de nos propres fournisseurs ce que nous fournissons à nos clients : des produits et services de qualité à un prix raisonnable. Nous promettons de toujours traiter nos fournisseurs avec courtoisie et respect."}
             </p>

             <h6 className='card-title font-weight-bold mb-2 mt-5' style={{fontSize:'20px'}}>{this.props.lang === 'EN' ?'Professionals' : "Professionnels"}</h6>
            <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We are committed to providing value-added products at fair prices, with exceptional, personalized customer support.' :
             "Nous nous engageons à fournir des produits de qualité supérieure à des prix raisonnables, et ce, avec un support client exceptionnel et personnalisé."}
             </p>
             <p className='card-text mb-2' style={{fontSize:'17px'}}>{this.props.lang === 'EN' ?'We understand the needs of the concrete industry; Interstar has been developing and manufacturing a full line of products for over 30 years. We’ve not only perfected granular pigments for ready mix concrete, but also the granular pigment dispensers to go with them. We design and manufacture all of our own dispensers. Our Easystar and Megastar systems are the original granular dispensing systems for ready mix concrete. We have hundreds of systems operating worldwide, backed by nearly two decades of proven reliability.' :
             "Nous comprenons les besoins réels de l’industrie du béton et c’est pourquoi Interstar développe et fabrique une gamme complète de produits depuis plus de 30 ans. Nous avons perfectionné non seulement les pigments granulaires pour le béton préparé, mais également les dispensateurs de pigments granulaires qui vont de pair. Nous concevons et fabriquons tous nos propres dispensateurs. Nos systèmes Easystar et Megastar sont les systèmes de coloration à sec originaux pour béton préparé. Nous avons des centaines de systèmes opérationnels à travers le monde, soutenus par près de deux décennies de fiabilité éprouvée."}
             </p>
      </div>
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;