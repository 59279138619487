import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import ProMenu from './ProMenu';
import ProContent from './ProContent';
import proProducts from '../data/proProducts';

import $ from 'jquery';



export default class Professionalpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      proProducts: proProducts // Cookies.get('language') || 'EN'
    };
  //  this._setLanguage = this._setLanguage.bind(this);
    this._getProData = this._getProData.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0,0);
    this._getProData();
  }

  _getProData(){
    var self = this;
    //console.log('Getting Pro Data');
    $.get('/data/prodata',function(data, status, xhr){
      //console.log('Pro Data Status: ' + status, data);
     // console.log('pro data: ' + JSON.stringify(data));
      if (data) {
        if (data.categories.length > 0) {
          self.setState({proProducts: data});
        }
      }
    },'json');
  }

  render() {
  //  var self = this;
    return (
      <div>
        <div className='profpagebg pagebgimg'>
          <div style={{height: '60px'}}></div>
          <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'60px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Professionals':'Professionnels'}</h1>
          <div style={{height: '1px'}}></div>
        </div>
        <div className='card bg-white border-0 rounded-0'>
        <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto'>
        {this.props.lang === 'EN' ?<div> <p className='text-left card-text mt-4 mb-3' style={{fontSize:'17px'}}>Interstar has been developing and manufacturing pigment for concrete for over thirty years. We have perfected granular pigment for ready mix concrete. We design and manufacture all of our own granular pigment dispensers. All of our products are backed by outstanding customer service and a proven track record decades long.</p>
            <p className='text-left card-text mb-3' style={{fontSize:'17px'}}>Granastar® Granular Pigment for Ready Mix concrete: The Original. Proven. Reliable.</p>
            <p className='text-left card-text mb-3' style={{fontSize:'17px'}}>Why go with an imitator when you can go with the originator?</p>
            <p className='text-left card-text mb-3' style={{fontSize:'17px'}}>Interstar’s granular pigment dispensers are the original granular dispensing systems for ready mix –backed by nearly two decades of proven reliability. Hundreds of systems operating worldwide. No financial investment necessary.</p>
            </div> :
            <div>
              <p className='text-left card-text mt4- mb-3' style={{fontSize:'17px'}}>Chez Interstar, nous fabriquons des pigments pour le béton depuis plus de trente ans. Nous avons perfectionné le pigment granulaire pour l'utilisation dans tous les types de béton. Nous avons été les premiers à introduire le pigment granulaire sur le marché du béton prêt à l'emploi et nous avons transformé la façon dont les producteurs de béton prêt à l'emploi colorent le béton.</p>
              <p className='text-left card-text mb-3' style={{fontSize:'17px'}}>Notre pigment granulaire Granastar® et nos dispensateurs, que nous concevons et fabriquons nous-mêmes, ont une performance éprouvée de près de deux décennies. Nous avons des centaines de dispensateurs de pigments en fonction dans le monde entier, tous appuyés par une expérience et un service client inégalé.</p>
            </div>
            }
            <div className='row my-4'>
            <div className='col-md-4'>
          <ProMenu {...this.state.proProducts} lang={this.props.lang}></ProMenu>
            </div>
            <div className='col-md-8 px-0'>
              <ProContent {...this.state.proProducts} lang={this.props.lang}></ProContent>
            </div>
          </div>
          </div>
        </div>
      </div>
    )
    }
  };
  //export default AboutComponent;