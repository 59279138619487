import React from 'react';
import AdminConsumerSearchProductItem from './AdminConsumerSearchProductItem';
import AdminConsumerSearchSkuItem from './AdminConsumerSearchSkuItem';

export default class AdminConsSearch extends React.Component {
    constructor(props) {
      super(props);
      this.state = { //set initial states
        filter: '',
        mode:'Products'
      };
      this._changeFilter = this._changeFilter.bind(this);
      this._changeMode = this._changeMode.bind(this);
    }
    componentDidMount() {
      
    }
  
    _changeFilter(e){
      let str = e.target.value;
      this.setState({filter:str});
    }

    _changeMode(mode) {
        var self = this;
        return function(){
            console.log('Change Mode: ' + mode);
            self.setState({mode: mode});
        }
    }
    render() {
      var self = this;
      return (
          <div className="accordion" id="accordionProProd">
            <h5 className="card-title text-center font-weight-bold">Search Consumer Products</h5>
            <div className="input-group mb-3 col-lg-5">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">Filter</span>
                </div>
                <input type="text" className="form-control" placeholder="Filter By ID" aria-label="Query" aria-describedby="basic-addon1" onChange={this._changeFilter} value={this.state.filter}/>
            </div>

            <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="consumerSearchDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Search: {this.state.mode}
            </button>
            <div className="dropdown-menu" aria-labelledby="consumerSearchDropdown">
                <button className="dropdown-item" type="button" onClick={self._changeMode('Products')}>Products</button>
                <button className="dropdown-item" type="button" onClick={self._changeMode('SKU')}>SKU</button>
            </div>
            </div>
            <table className="table table-sm table-hover table-striped">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sort</th>
                    <th scope="col">English Title</th>
                    <th scope="col">French Title</th>
                    <th scope="col">Group</th>
                    <th scope="col">Show Can</th>
                    <th scope="col">Show US</th>
                    <th scope="col">Show Global</th>
                </tr>
                </thead>
                <tbody>
                    {
                    this.state.mode === 'Products' ? this.props.products.map(thisprod =>{
                        if (self.state.filter.length > 0) {
                            let lowfilter = self.state.filter.toLowerCase();
                            let lowID = thisprod.id.substring(0, lowfilter.length).toLowerCase();
                            if (lowID === lowfilter) {
                                return <AdminConsumerSearchProductItem {...this.props} thisprod={thisprod} />
                            } else {
                                return ''
                            }
                        } else {
                            return <AdminConsumerSearchProductItem {...this.props} thisprod={thisprod} />
                        }
                    }) : this.props.sku.map(thisprod =>{
                        if (self.state.filter.length > 0) {
                            let lowfilter = self.state.filter.toLowerCase();
                            let lowID = thisprod.id.substring(0, lowfilter.length).toLowerCase();
                            if (lowID === lowfilter) {
                                return <AdminConsumerSearchSkuItem {...this.props} thisprod={thisprod} />
                            } else {
                                return ''
                            }
                        } else {
                            return <AdminConsumerSearchSkuItem {...this.props} thisprod={thisprod} />
                        }
                    })
                    }
                </tbody>
            </table>
          </div>
      )
    }
}