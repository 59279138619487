import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';
import SmallGallery from './SmallGallery.js';
import LazyLoad from 'react-lazyload';
import ModalGallery from './ModalGallery.js';


export default class GalleryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      galleryImages: [{id:'none',sort:0,thumb:'1_thumba.jpg',image:'CoreyCo Concrete Tampa.jpg',visible:true},
                      {id:'none',sort:1,thumb:'2_thumba.jpg',image:'Anapharm-QC 2.jpg',visible:true }
                     ]//Cookies.get('language') || 'EN'
    };
    this._getGalleryImages = this._getGalleryImages.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0,0);
    this._getGalleryImages();
  }
  _onClickGallery(imgsrc) {
    return function(){
      console.log('imgsrc: ' + imgsrc);
      setTimeout(function(){
        $('#sld' + imgsrc).click();
      },250);
    }
  }
  _getGalleryImages() {
    var self = this;
      $.get('/data/gallery',function(data,status,xhr){
      console.log('Gallery Data Status: ' + status + ' Data: ' + JSON.stringify(data));
        if (data.length > 0) {
          self.setState({galleryImages: data});
        }
      },'json') 
  }
  render() {
    var self = this;
    let imagelength = self.state.galleryImages.length;
    let pagelength = Math.ceil(imagelength/12);
    let pages = [];
    for (var x = 0; x < pagelength; x++){
      pages.push({});
    }
    //console.log('img: ' + imagelength + ' page: ' + pagelength);
    //console.log('Pages: ' + pages.length);
    //console.log('galleryImages: ' + JSON.stringify(self.state.galleryImages));
    return (
      <div>
      <div className="modal fade" id='galleryModal' tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content bg-dark text-white">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body px-0 py-0">
                <ModalGallery galleryImages={self.state.galleryImages}/>
              </div>
            </div>
          </div>
        </div>
      <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         // height:'230px',
         marginTop:'-170px'}}>
         
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px','textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Gallery':'Galerie de photos'}</h1>
          </div>
         <div className='card-body bg-white col-sm-9 col-lg-9 col-xl-7 mx-auto px-0 mb-4'>
            <div className='d-none d-md-block'>
             <LazyLoad height={200} offset={100} once>
              <div id="carouselGallery" className="carousel" data-interval="0" data-ride="carousel">
              <div className="carousel-inner col-sm-10 mx-auto">

                {
                  pages.map(function(page, idxP) {
                    //console.log('outer page: ' + idxP);

                    let pageimages = [];
                    // let imagelength = self.state.galleryImages.length;
                    let maximages = imagelength;
                    if (imagelength > 12) {
                      maximages = 12 * (idxP + 1);
                      if (maximages > (imagelength - 1)){
                      //  console.log('Limit: ' + imagelength);
                        maximages = imagelength + 1;
                      }
                    }
                    console.log("maximages: " + maximages);
                    for (let x = (idxP + (idxP * 11)); x < maximages; x++){
                      //console.log(x);
                      let image = self.state.galleryImages[x];
                      let gkey = x;
                      if ((image !== undefined) && (image !== null)){
                      pageimages.push(<div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                            <a className='my-auto' onClick={self._onClickGallery(gkey)} data-toggle="modal" href="#galleryModal" data-whatever={gkey}>
                            <img className ='img-fluid' src={'../img/gallery/' + image.thumb} alt='thumb'></img>
                            </a>
                      </div>)
                      }
                    }

                    return <div className={"carousel-item " + (idxP === 0 ? 'active':'')}>
                    <div className='row'> 
                      {
                       pageimages
                       /* self.state.galleryImages.map(function(image, idximg){
                          // console.log('img: ' + idximg);
                          // console.log('sort: ' + image.sort.toString());
                          let gkey = idximg.toString();
                          return <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                            <a className='my-auto' onClick={self._onClickGallery(gkey)} data-toggle="modal" href="#galleryModal" data-whatever={gkey}>
                            <img className ='img-fluid' src={'../img/gallery/' + image.thumb} alt='thumb'></img>
                            </a>
                          </div>
                        })
                        */

                      }
                    
                    </div>
                    </div>
                  }) 
                }
                {/*
                <div className="carousel-item active">
                    <div className='row'>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                      <a className='my-auto' onClick={this._onClickGallery('0')} data-toggle="modal" href="#galleryModal" data-whatever='0'>
                      <img className ='img-fluid' src='../img/gallery/1_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                      <a className='my-auto' onClick={this._onClickGallery('1')} data-toggle="modal" href="#galleryModal" data-whatever='1'>
                      <img className ='img-fluid' src='../img/gallery/2_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('2')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/3_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('3')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/4_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('4')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/5_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>               
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('5')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/6_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('6')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/7_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('7')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/8_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('8')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/9_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>               
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('9')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/10_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('10')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/11_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('11')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/12_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    </div>
                </div>


                <div className="carousel-item">
                <div className='row'>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('12')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/13_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>               
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('13')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/14_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('14')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/15_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('15')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/16_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('16')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/17_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>               
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('17')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/18_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('18')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/19_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('19')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/20_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('20')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/21_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>               
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('21')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/22_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('22')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/23_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('23')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/24_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>
                    </div>
                </div>


                <div className="carousel-item">
                <div className='row'>
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('24')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/25_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div>               
                    <div className='card bg-white border-0 rounded-0 col-md-4 col-lg-3 px-0 my-xl-2' style={{ maxHeight:'250px'}}>
                    <a className='my-auto' onClick={this._onClickGallery('25')} data-toggle="modal" href="#galleryModal">
                      <img className ='img-fluid' src='../img/gallery/26_thumba.jpg' alt='thumb'></img>
                      </a>
                    </div> 
                    </div>
                </div>
                 */
              }



              </div>
              <a className="carousel-control-prev" href="#carouselGallery" role="button" data-slide="prev">
                {/* <span className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span> */
                }
                <span className='text-secondary mr-4'><i className="fas fa-angle-double-left fa-2x"></i></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselGallery" role="button" data-slide="next">
                {/*<span className="carousel-control-next-icon bg-dark" aria-hidden="true"></span> */
                }
                <span className='text-secondary ml-4'><i className="fas fa-angle-double-right fa-2x"></i></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
            </LazyLoad>
            </div>
            <div className='d-block d-md-none'>
            <LazyLoad height={200} offset={100} once>
              <SmallGallery galleryImages={self.state.galleryImages}/>
              </LazyLoad>
            </div>
          </div>
      </div>
    )
    }
  };
  //export default AboutComponent;