import React from 'react';

export default class MarketingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
       // Cookies.get('language') || 'EN'
    };
  }
  componentDidMount() {
    window.scrollTo(0,0);
  }

  
  
  render() {
    return (
      <div>
        <div className='markpagebg pagebgimg'>
          <div style={{height: '60px'}}></div>
          <h1 className='titleblock-headerMK text-white font-weight-bolder' style={{marginBottom:'60px', 'textShadow': '1px 1px black', fontSize:'60px'}}>Marketing</h1>
          <div style={{height: '1px'}}></div>
        </div>
        <div className='card bg-white border-0 rounded-0'>
          <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto'>
            <p className='card-text my-3' style={{fontSize:'18px'}}>
            For nearly ten years, Interstar’s Marketing Department has been helping customers stand out from the crowd. 
            Our in-house creative team is unlike any other because they understand decorative concrete. This unique understanding allows them 
            to deliver strategic design to drive business.
            </p>
            <ul className='list-inline list-middot my-3'>
              <li className='list-inline-item'>
                <strong>
                  Custom Color Charts
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Sample Kits
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Information Sheets
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Brand Identity
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Logos
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Digital Media
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Collateral
                </strong>
              </li>
              <li className='list-inline-item'>
                <strong>
                  Packaging and More
                </strong>
              </li>
            </ul>
            <h5 className='text-left font-weight-bold mb-2'>Custom Color Charts</h5>
            {//<div class="d-flex justify-content-between">...</div>
            }
            <div className='d-flex justify-content-between'>
              <div className='pr-1'>
                {//style={{minWidth:'100px', maxWidth:'300px'}}
                }
                <img className='img-fluid w-100'  src='./img/TRiCIty-Chart_for_web.jpg' alt='img'/>
              </div>
              <div className='px-2'>
              <img className='img-fluid w-100' src='./img/construction-service-Chart_for_web.jpg' alt='img'/>
              </div>
              <div className='pl-1'>
              <img className='img-fluid w-100' src='./img/RMCC_color_chart_for_web.jpg' alt='img'/>
              </div>
              </div>
              <div className='d-flex justify-content-between mt-2 mb-3'>
              <div className='pr-2'>
                {//style={{minWidth:'100px', maxWidth:'450px'}}
                }
              <img className='img-fluid w-100'  src='./img/Western_Materials_Chart_for_web.jpg' alt='img'/>
              </div>
              <div className='pl-2'>
              <img className='img-fluid w-100' src='./img/Contempo_kit_color_chart_for_web.jpg' alt='img'/>
              </div>
            </div>
            <h5 className='text-left font-weight-bold my-3'>Display Kits</h5>
            <div className='d-flex justify-content-between'>
              <div className='pr-1'>
                <img className='img-fluid w-100' src='./img/Kit_Ready_Mix_for_web.jpg' alt='img'/>
              </div>
              <div className='px-2'>
              <img className='img-fluid w-100' src='./img/Image_kit_Mortar_for_web.jpg' alt='img'/>
              </div>
              <div className='pl-1'>
              <img className='img-fluid w-100' src='./img/Kit_release_for_web.jpg' alt='img'/>
              </div>
              </div>
              <div className='d-flex justify-content-between mt-2 mb-3'>
              <div className='pr-1'>
                <img className='img-fluid w-100' src='./img/Granastar_Pigment_kit_for_web.jpg' alt='img'/>
              </div>
              <div className='px-2'>
              <img className='img-fluid w-100' src='./img/Image_kit_Stucco_for_web.jpg' alt='img'/>
              </div>
              <div className='pl-1'>
              <img className='img-fluid w-100' src='./img/RMCC_kit_for_web.jpg' alt='img'/>
              </div>
              </div>

            <h5 className='text-left font-weight-bold my-3'>Displays</h5>
            <div className='d-flex justify-content-between'>
              <div className='pr-1'>
                <img className='img-fluid w-100' src='./img/Outdoor_Display_for_web.jpg' alt='img'/>
              </div>
              <div className='px-2'>
              <img className='img-fluid w-100' src='./img/Easystar_Roll_Up_Banner_for_web.jpg' alt='img'/>
              </div>
              <div className='pl-1'>
              <img className='img-fluid w-100' src='./img/Color_chart_poster_for_web.jpg' alt='img'/>
              </div>
              </div>

          </div>
        </div>
      </div>
    )
    }
  };
  //export default AboutComponent;