import React from 'react';
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';

import $ from 'jquery';

function Confirm(title, msg, $true, $false, $link) { /*change*/
  var $content =  "<div class='dialog-ovelay'>" +
                  "<div class='dialog'><header>" +
                   " <h2> " + title + " </h2> " +
       //            "<i class='navbar-text fas fa-times'></i>" +
               "</header>" +
               "<div class='dialog-msg'>" +
                   " <p> " + msg + " </p> " +
               "</div>" +
               "<footer>" +
                   "<div class='controls'>" +
                       " <button class='btn btn-primary doAction'>" + $true + "</button> " +
                       " <button class='btn btn-light cancelAction'>" + $false + "</button> " +
                   "</div>" +
               "</footer>" +
            "</div>" +
          "</div>";
   $('body').prepend($content);
$('.doAction').click(function () {
  window.open($link, "_blank"); /*new*/
  $(this).parents('.dialog-ovelay').fadeOut(500, function () {
    $(this).remove();
  });
});
$('.cancelAction, .fa-close').click(function () {
  $(this).parents('.dialog-ovelay').fadeOut(500, function () {
    $(this).remove();
  });
});

}


export default class ProContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      active_cat: '1'
    };
    this._onClickC = this._onClickC.bind(this);
    this._onClickCF = this._onClickCF.bind(this);
  }

  componentDidMount(){
    //console.log('procontent update');
    setInterval(function(){forceCheck();},100);
  }

  _onClickC(e, product){
    if (product.disclaimer) {
        e.preventDefault();
        var target = e.currentTarget.getAttribute('href');
        Confirm('Disclaimer', 'These color charts are only a guideline, and CANNOT be used to make your final color selection. Computer screens and printers vary greatly and do not accurately represent the colors that will be obtained. We strongly recommend creating a sample with the materials you will use to be sure the color meets your expectations. Please contact our Customer Service Department at info@interstar-paf.com or an Interstar sales representative for information on seeing an Interstar sample kit or obtaining samples.', 'OK', 'Close', target);
    }

   // if (!window.confirm('These color charts are only a guideline, and CANNOT be used to make your final color selection. Computer screens and printers vary greatly and do not accurately represent the colors that will be obtained. We strongly recommend creating a sample with the materials you will use to be sure the color meets your expectations. Please contact our Customer Service Department at info@interstar-paf.com or an Interstar sales representative for information on seeing an Interstar sample kit or obtaining samples.')) {e.preventDefault();}
  }

  _onClickCF(e, product){
    if (product.disclaimer) {
    e.preventDefault();
    var target = e.currentTarget.getAttribute('href');
    Confirm('Important', 'Étant donné que la couleur du béton peut être affectée par de nombreux facteurs, il faut utiliser cette charte de couleurs uniquement à titre indicatif. Elle offre un apercu des couleurs pouvant être obtenues. Veuillez consulter le coffret de couleurs pour béton préparé d’Interstar pour une représentation plus précise de nos couleurs pour béton. Une maquette fait avec vos matériaux et vos techniques de finition est le meilleur moyen de déterminer si la couleur désirée peut être obtenue.', 'OK', 'Fermer', target);
    }
  }
  render() {
    var self = this;
    return (
      <div className="tab-content text-left" id="nav-tabContent">
        {
        this.props.categories.map(categorie => 

          <div key={categorie.id} className={'prod_panel_cat tab-pane fade ' + (self.state.active_cat === categorie.id ? 'show active' : '')} role="tabpanel" id={'pronav' + categorie.id}>
              <div className='col-sm-11 ml-0 px-0'><LazyLoad height={200} once><img src={'./img/pro_banner/' + categorie.image} alt='bannerimage' className='img-fluid'></img></LazyLoad>
              <div className="tab-content text-left" id="nav-tabContent">
              {
                categorie.products.map(product => <div key={product.id} className='prod_panel tab-pane fade' role="tabpanel" id={'pronav' + product.id}>
                    <h3 className='my-3 font-weight-bold' style={{fontSize:'32px'}}>{self.props.lang === 'EN' ? product.name_en : product.name_fr}</h3>
                    {self.props.lang === 'EN' ? product.desc_en.map((desc,idx) => <p key={product.id + idx} className='my-3' style={{fontSize:'17px'}}>
                      {desc}
                    </p>) : product.desc_fr.map((desc,idx) => <p key={product.id + idx} className='my-3' style={{fontSize:'17px'}}>
                      {desc}
                    </p>) }
                    <div className='row mx-1 d-flex justify-content-between'>
                      {(product.show_colorchart && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' onClick={(e) => {self._onClickC(e, product)}} href={'./downloads/charts/en/' + product.chart_en} target='_blank' rel='noopener noreferrer'>COLOR CHART</a> : ''}
                      {(product.show_productinfo && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/productinfo/en/' + product.productinfo_en} target='_blank' rel='noopener noreferrer'>PRODUCT INFO SHEET </a> : ''}
                      {(product.show_brochure && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/brochures/en/' + product.brochure_en} target='_blank' rel='noopener noreferrer'>BROCHURE</a> : ''}
                      {(product.show_instructions && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/instructions/en/' + product.instructions_en} target='_blank' rel='noopener noreferrer'>INSTRUCTIONS</a> : ''}
                      {(product.show_overview && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/overview/en/' + product.overview_en} target='_blank' rel='noopener noreferrer'>OVERVIEW</a> : ''}

                      {(product.show_colorchartfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' onClick={(e) => {self._onClickCF(e, product)}} href={'./downloads/charts/fr/' + product.chart_fr} target='_blank' rel='noopener noreferrer'>CHARTE DE COULEURS</a> : ''}
                      {(product.show_productinfofr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/productinfo/fr/' + product.productinfo_fr} target='_blank' rel='noopener noreferrer'>FICHE D'INFORMATION </a> : ''}
                      {(product.show_brochurefr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/brochures/fr/' + product.brochure_fr} target='_blank' rel='noopener noreferrer'>BROCHURE</a> : ''}
                      {(product.show_instructionsfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/instructions/fr/' + product.instructions_fr} target='_blank' rel='noopener noreferrer'>INSTRUCTIONS</a> : ''}
                      {(product.show_overviewfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/overview/fr/' + product.overview_fr} target='_blank' rel='noopener noreferrer'>OVERVIEW</a> : ''}
                    </div>
                  </div>
                )
              }
              {
                categorie.subcategorie.map(subcat => 
                  subcat.products.map(product => <div key={product.id + ' ' + subcat.id} className='subprod_panel tab-pane fade' role="tabpanel" id={'pronav' + product.id}>
                  <h3 className='my-3 font-weight-bold' style={{fontSize:'32px'}}>{self.props.lang === 'EN' ? product.name_en : product.name_fr}</h3>
                  {self.props.lang === 'EN' ? product.desc_en.map((desc,idx) => <p key={product.id + idx} className='my-3' style={{fontSize:'17px'}}>
                      {desc}
                    </p>) : product.desc_fr.map((desc,idx) => <p key={product.id + idx} className='my-3' style={{fontSize:'17px'}}>
                      {desc}
                    </p>) }
                  <div className='row mx-1 d-flex justify-content-between'>
                      {(product.show_colorchart && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' onClick={(e) => {self._onClickC(e, product)}} href={'./downloads/charts/en/' + product.chart_en} target='_blank' rel='noopener noreferrer'>COLOR CHART</a> : ''}
                      {(product.show_productinfo && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/productinfo/en/' + product.productinfo_en} target='_blank' rel='noopener noreferrer'>PRODUCT INFO SHEET </a> : ''}
                      {(product.show_brochure && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/brochures/en/' + product.brochure_en} target='_blank' rel='noopener noreferrer'>BROCHURE</a> : ''}
                      {(product.show_instructions && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/instructions/en/' + product.instructions_en} target='_blank' rel='noopener noreferrer'>INSTRUCTIONS</a> : ''}
                      {(product.show_overview && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/overview/en/' + product.overview_en} target='_blank' rel='noopener noreferrer'>OVERVIEW</a> : ''}

                      {(product.show_colorchartfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' onClick={(e) => {self._onClickCF(e, product)}} href={'./downloads/charts/fr/' + product.chart_fr} target='_blank' rel='noopener noreferrer'>CHART DE COULEURS</a> : ''}
                      {(product.show_productinfofr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/productinfo/fr/' + product.productinfo_fr} target='_blank' rel='noopener noreferrer'>FICHE D'INFORMATION </a> : ''}
                      {(product.show_brochurefr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/brochures/fr/' + product.brochure_fr} target='_blank' rel='noopener noreferrer'>BROCHURE</a> : ''}
                      {(product.show_instructionsfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/instructions/fr/' + product.instructions_fr} target='_blank' rel='noopener noreferrer'>INSTRUCTIONS</a> : ''}
                      {(product.show_overviewfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'./downloads/overview/fr/' + product.overview_fr} target='_blank' rel='noopener noreferrer'>OVERVIEW</a> : ''}
                  </div>
                </div>
              )  
                )
              }
              </div>
              </div>
          </div>
          

        )
        }
      </div>
    )
  }
};