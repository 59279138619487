import React from 'react';

import AdminProProductModal from './AdminProProductModal';

export default class AdminProProductItem extends React.Component {
    render(){
        var idsplice = this.props.thisprod.id.split(' ');
        return (
            <React.Fragment>
            <tr key={this.props.thisprod.id} data-toggle="modal" data-target={"#ProProdModal" + idsplice[0]}> {/*data-toggle="collapse" data-target={"#ProProdCollapse" + idsplice[0]}>*/}
                <td>
                    {this.props.thisprod.id}
                </td>
                <td>
                    {this.props.thisprod.order}
                </td>
                <td>
                    {this.props.thisprod.name_en}
                </td>
                <td>
                    {this.props.thisprod.name_fr}
                </td>
                <td>
                    {this.props.thisprod.memberof}
                </td>
                <td>
                    {this.props.thisprod.show_ca ? 'true':'false'}
                </td>
                <td>
                    {this.props.thisprod.show_us ? 'true':'false'}
                </td>
                <td>
                    {this.props.thisprod.show_gl ? 'true':'false'}
                </td>
                
            </tr>
          {/*  <tr id={"ProProdCollapse" + idsplice[0]} className="col collapse" aria-labelledby="headingTwo" data-parent="#accordionProProd">
                <AdminProProductCard {...this.props}/>
        </tr> */}
            <AdminProProductModal {...this.props}/>
            </React.Fragment>
        )
    }
}


/**
 * 
 * <AdminProProductModal {...this.props}/>
 * <tr>
                <div id=ProProdCollapse className="collapse" aria-labelledby="headingTwo" data-parent="#accordionProProd">
                    <div className="card-body">
                        <p style={{fontSize:'17px'}}> Test data </p>
                    </div>
                </div>
            </tr>

            
 */