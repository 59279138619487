import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminProSubCatCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          categories: [{id:'test1'},{id:'test2'}], //Cookies.get('language') || 'EN'
          cat_sel: ''
        };
        this._getProCats = this._getProCats.bind(this);
        this._onClickSel = this._onClickSel.bind(this);
      }
    
      componentDidMount() {
        this._getProCats();
      }
    
      _getProCats(){
      /*  var self = this;
        console.log('Pro Category List');
        $.get('/data/procats',function(data,status,xhr){
          console.log('status: ' + status);
          if (data.length > 0) {
            self.setState({categories: data});
          }
        },'json'); */
      }

      _onClickSel(catname){
        var self = this;
        return function(){
          if (catname === 'none') {
            self.setState({cat_sel : ''});
          } else {
            self.setState({cat_sel : catname});
          }
        }
      }

      _onSubmit(e) {
        e.preventDefault();
        
        var form = $('#createprosubcat')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#createprosubcat')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
        }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center">Sub-Category</h5>
        <form id='createprosubcat' action='/admin/createprosubcat' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control" id="InputID1s" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control" id="InputENs" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control" id="InputFRs" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
          <label className='col-sm-2 col-form-label'>Category</label>
          <select name='category' className="col-sm-10 col-lg-6 form-control">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_ca' className="custom-control-input" id="visibleSCAN"/>
            <label className="custom-control-label" htmlFor="visibleSCAN">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_us' className="custom-control-input" id="visibleSUS"/>
            <label className="custom-control-label" htmlFor="visibleSUS">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_gl' className="custom-control-input" id="visibleSGL"/>
            <label className="custom-control-label" htmlFor="visibleSGL">Show Global</label>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control" id="Inputsorts" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };