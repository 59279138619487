import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminGalleryItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          sort: 0,
          thumb: '',
          image: '',
          visible: 'on',
        };
        this._unlockItem = this._unlockItem.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);
        this._updateSort = this._updateSort.bind(this);
        this._updateVisible = this._updateVisible.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
      }
    
      componentDidMount() {
      //  this._getChartData();
      }
    
      _unlockItem(){
        this.setState({
          locked: false,
          sort: this.props.image.sort,
          thumb: this.props.image.thumb,
          image: this.props.image.image,
          visible: this.props.image.visible,
        });
      }
      _lockItem(){
        this.setState({locked:true});
      }
      _saveItem(){
        this.setState({locked:true});
      }
      _updateSort(e){
        var str = e.target.value;
        this.setState({sort:str});
      }

      _updateVisible(){
       // var str;// = e.target.value;
       // console.log('str en: ' + str);
        if (this.state.visible === true) {
          this.setState({visible: false});
        } else {
          this.setState({visible: true});
        }
      }
      
      _onSubmit(e) {
        e.preventDefault();
        
        var self = this;

        var form = $("#galform" + this.props.image.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);

                self.setState({locked:true});
                self.props._getGalleryImages();
            //  $("#btnSubmit").prop("disabled", false);
           // $('#createproproduct')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
        }
  render() {
  //  console.log('Subcatdata: ' + JSON.stringify(this.props.subcategory));
   // var self = this;
    return (
        this.state.locked ? <tr key={this.props.image.id}>
              <td>{this.props.image.id}</td>
              <td>{this.props.image.sort}</td>
              <td>{this.props.image.thumb}</td>
              <td>{this.props.image.image}</td>
              <td>{this.props.image.visible ? 'true':'false'}</td>
              <td className='d-flex justify-content-between'>
              <button className="btn btn-sm btn-outline-success" onClick={this._unlockItem}><i className='fas fa-lock' style={{color:'green', cursor:'pointer'}}></i></button>
              <button className="btn btn-sm btn-outline-danger" ><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
          </tr> : <tr key={this.props.image.id}>
            
              <td><input type="text" name="id" form={"galform" + this.props.image.id} readonly class="form-control-plaintext form-control-sm text-light" value={this.props.image.id}/></td>
              <td><input type="number" name="sort" form={"galform" + this.props.image.id} className='form-control form-control-sm px-1' value={this.state.sort} onChange={this._updateSort}/></td>
              <td>
              <input type="file" name='thumb' form={"galform" + this.props.image.id} className="form-control-file form-control-sm"/>
              </td>
              <td>
              <input type="file" name='image' form={"galform" + this.props.image.id} className="form-control-file form-control-sm"/>
              </td>
              <td>
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='visible' className="custom-control-input" id="visibleGale" form={"galform" + this.props.image.id} onClick={this._updateVisible} checked={this.state.visible === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleGale">Visible</label>
                </div>
              </td>
              <td className='d-flex justify-content-between'>
                <button className="btn btn-sm btn-outline-success" onClick={this._lockItem}><i className='fas fa-lock-open' style={{color:'green', cursor:'pointer'}}></i></button>
                <form id={"galform" + this.props.image.id} action='/admin/update_gallery' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
                  <button type="submit" className="btn btn-sm btn-outline-success"><i className='fas fa-save' style={{color:'green'}}></i></button>
                </form>
                <button className="btn btn-sm btn-outline-danger"><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
            
          </tr>
    )
    }
  };