import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminConsProdSKUCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          categories: [{id:'test1'},{id:'test2'}], //Cookies.get('language') || 'EN'
          cat_sel: ''
        };
        this._getProCats = this._getProCats.bind(this);
        this._onClickSel = this._onClickSel.bind(this);
      }
    
      componentDidMount() {
        this._getProCats();
      }
    
      _getProCats(){
     /*   var self = this;
        console.log('Pro Category List');
        $.get('/data/procats',function(data,status,xhr){
          console.log('status: ' + status);
          if (data.length > 0) {
            self.setState({categories: data});
          }
        },'json'); */
      }

      _onClickSel(catname){
        var self = this;
        return function(){
          if (catname === 'none') {
            self.setState({cat_sel : ''});
          } else {
            self.setState({cat_sel : catname});
          }
        }
      }

      _onSubmit(e) {
        e.preventDefault();
        
        var form = $('#createconsprodsku')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#createconsprodsku')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
        }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Consumer Product SKU</h5>
        <form id='createconsprodsku' action='/admin/createconsprodsku' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
        <label className='col-sm-2 col-form-label font-weight-bold'>Product</label>
        <select name='product' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.products.map(thiscat=>{
                return <option>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>SKU ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputSKU" aria-describedby="idHelp" placeholder="Enter SKU" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control form-control-sm" id="InputENps" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control form-control-sm" id="InputFRps" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control form-control-sm" id="InputSORT" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Product Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='img' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANs"/>
            <label className="custom-control-label" htmlFor="visiblepCANs">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSs"/>
            <label className="custom-control-label" htmlFor="visiblepUSs">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLs"/>
            <label className="custom-control-label" htmlFor="visiblepGLs">Show Global</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='sale_ca' className="custom-control-input" id="saleCAN"/>
            <label className="custom-control-label" htmlFor="saleCAN">Sale Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='sale_us' className="custom-control-input" id="saleUS"/>
            <label className="custom-control-label" htmlFor="saleUS">Sale US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='sale_gl' className="custom-control-input" id="saleGL"/>
            <label className="custom-control-label" htmlFor="saleGL">Sale Global</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Price CAD</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='price_cad' type="number" min="0" step="0.01" className="form-control form-control-sm" id="InputSKUPrice" aria-describedby="idHelp" placeholder="Enter CAD" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Price USD</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='price_usd' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputSKUPriceUS" aria-describedby="idHelp" placeholder="Enter USD" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Shipping Weight (lbs)</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='ship_weight' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputShipWeight" aria-describedby="idHelp" placeholder="Enter LBS" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='var_opt' className="custom-control-input" id="varient"/>
            <label className="custom-control-label" htmlFor="varient">Varient</label>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Primary Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_v1' type="text" className="form-control form-control-sm" id="Inputv1" aria-describedby="enHelp" placeholder="Dropdown Option1" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Primary Name FR</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_v1_fr' type="text" className="form-control form-control-sm" id="Inputv1f" aria-describedby="enHelp" placeholder="Dropdown Option1" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_v2' type="text" className="form-control form-control-sm" id="Inputv2" aria-describedby="enHelp" placeholder="Dropdown Option2" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient Name FR</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_v2_fr' type="text" className="form-control form-control-sm" id="Inputv2f" aria-describedby="enHelp" placeholder="Dropdown Option2" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient SKU</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='var_id' type="text" className="form-control form-control-sm" id="Inputvsid" aria-describedby="enHelp" placeholder="Input Varient SKU" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>V Price CAD</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='price_cad_v' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputSKUPriceCAv" aria-describedby="idHelp" placeholder="Enter CAD" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>V Price USD</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='price_usd_v' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputSKUPriceUSv" aria-describedby="idHelp" placeholder="Enter USD" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Shipping Weight (lbs)</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='ship_weight_v' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputShipWeightv" aria-describedby="idHelp" placeholder="Enter LBS" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='img_v' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };