import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import downloads from '../data/downloads';

import $ from 'jquery';

export default class DownloadsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { //set initial states
      downloads: downloads // Cookies.get('language') || 'EN'
    };
  //  this._setLanguage = this._setLanguage.bind(this);
    this._getDownData = this._getDownData.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0,0);
    this._getDownData();
  }

  _getDownData(){
    var self = this;
   // console.log('Getting Downloads Data');
    $.get('/data/downloads',function(data, status, xhr){
   //   console.log('Downloads Data status: ' + status + ' Data: ' + JSON.stringify(data));
     // console.log('downloads data: ' + JSON.stringify(data));
      if (data) {
        if (data.length > 0) {
          self.setState({downloads: data});
        } else {
        //  console.log('downloads data length less 1');
        }
      }
    },'json');
  }

  render() {
    return (
      <div>
      <div className='downloadpagebg pagebgimg'>
         <div style={{height: '50px'}}></div>
         <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'50px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Downloads':'Téléchargements'}</h1>
         <div style={{height: '1px'}}></div>
      </div>
      <div className='card border-0 rounded-0' style={{background:'#f0f0f0'}}>
        <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto'>
          <div className='row'>
          {this.state.downloads.map(item =>
            (this.props.lang === 'EN' && item.display_en) ? <div key={item.id} className='col-sm-6 col-md-4 col-lg-3 mt-4 px-2'>
             <LazyLoad>
             <a href={'../downloads/' + item.pdf_en} target='_blank' rel='noopener noreferrer'>
               <img className='img-fluid' style={{minWidth:'100px', maxWidth:'140px'}} src={'../img/downloads/en/' + item.image_en} alt={item.image_en}/>
                </a>
              </LazyLoad>
              <hr className='my-0 border-0'></hr>
              <a href={'../downloads/' + item.pdf_en} target='_blank' rel='noopener noreferrer'>
              <strong className='ml-2 text-dark'>{item.name_en}</strong>
              </a>
            </div> : (this.props.lang === 'FR' && item.display_fr) ? <div key={item.id} className='col-sm-6 col-md-4 col-lg-3 mt-4 px-2'>
             <LazyLoad>
             <a href={'../downloads/' + item.pdf_fr} target='_blank' rel='noopener noreferrer'>
               <img className='img-fluid' style={{minWidth:'100px', maxWidth:'140px'}} src={'../img/downloads/fr/' + item.image_fr} alt={item.image_fr}/>
                </a>
              </LazyLoad>
              <hr className='my-0 border-0'></hr>
              <a href={'../downloads/' + item.pdf_fr} target='_blank' rel='noopener noreferrer'>
              <strong className='ml-2 text-dark'>{item.name_fr}</strong>
              </a>
            </div> : <div key={item.id}></div>

          )}
          </div>
        </div>
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;