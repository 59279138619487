import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminDownCreate from './AdminDownCreate';
import AdminDownEdit from './AdminDownEdit';

export default class AdminDownPage extends React.Component {

  render() {
    return (
      <div className='card text-left'>
          <h5 className="card-header">Admin Consumer Products</h5>
          <div className='card-body'>
            <ul className="nav nav-pills mb-3" id="pills-tabd" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="pills-created-tab" data-toggle="pill" href="#pills-created" role="tab" aria-controls="pills-created" aria-selected="true">Create</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pills-editd-tab" data-toggle="pill" href="#pills-editd" role="tab" aria-controls="pills-editd" aria-selected="false">Edit</a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabcContent">
                <div className="tab-pane fade show active" id="pills-created" role="tabpanel" aria-labelledby="pills-created-tab">
                  <AdminDownCreate/>
                </div>
                <div className="tab-pane fade" id="pills-editd" role="tabpanel" aria-labelledby="pills-editd-tab">
                  <AdminDownEdit {...this.props} />
                </div>
            </div>
          </div>
      </div>
    )
    }
  };