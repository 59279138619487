
const proProducts = {
  categories:[
   /* {
      id:'1',
      name_en:'Pigments',
      name_fr:'Une',
      order: 1,
      show_ca: true,
      show_us: true,
      show_gl: true,
      image: 'banner_pigment_2019-01_3.jpg',
      subcategorie: [],
      products: [
        {
         id:'prod1',
         order: 1,
         name_en:'Granastar Granular Pigment',
         name_fr:'prod1fr',
         desc_en:['brief english description of the product and what it does. Adding some more text to make the line wrap so can see what a full paragraph will look like.','paragraph 2'],
         desc_fr:['french description'],
         show_ca: true,
         show_us: true,
         show_gl: true,
         show_colorchart: true,
         show_brochure: false,
         show_instructions: false,
         show_productinfo: true,
         show_overview: false,
         chart_en: 'Integral Granular Pigment Color Chart_EN_web 2019.pdf',
         chart_fr: '',
         brochure_en: '',
         brochure_fr: '',
         instructions_en: '',
         instructions_fr: '',
         productinfo_en: '',
         productinfo_fr: '',
         overview_en: '',
         overview_fr: ''
        },
        {id:'prod2', name_en:'prod2',name_fr:'prod2fr',desc_en:[''],desc_fr:['']}]
    },
    {
      id:'2',
      name_en:'Two',
      name_fr:'Deux',
      image: 'banner_decorative_2019-01.jpg',
      subcategorie: [{
        id:'1',
        name_en:'subcat_name',
        name_fr:'subcat_namefr',
        order: 1,
        show_ca: true,
        show_us: true,
        show_gl: true,
        products: [{id:'prod3', name_en:'prod3',name_fr:'prod3fr',desc_en:['eng desc','paragraph2'],desc_fr:['french desc']},{id:'prod4', name_en:'prod4',name_fr:'prod4fr',desc_en:[''],desc_fr:['']}]
      },
      {
        id:'2',
        name_en:'subcat_name2',
        name_fr:'subcat_name2fr',
        order: 2,
        show_ca: true,
        show_us: true,
        show_gl: true,
        products: [{id:'prod7', name_en:'prod7',name_fr:'prod7fr',desc_en:['eng desc'],desc_fr:['french desc']},{id:'prod8', name_en:'prod8',name_fr:'prod8fr',desc_en:[''],desc_fr:['']}]
      }],
      products:[]
    },
    {
      id:'3',
      name_en:'Three',
      name_fr:'Trois',
      image: 'banner_dispensers_2019-01.jpg',
      subcategorie: [],
      products: [{id:'prod5', name_en:'prod5',name_fr:'prod5fr',desc_en:['eng desc'],desc_fr:['french desc']},{id:'prod6', name_en:'prod6',name_fr:'prod6fr',desc_en:[''],desc_fr:['']}]
    },*/
  ],
  
};

export default proProducts;