import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminChartsCreate from './AdminChartsCreate';
import AdminChartsEdit from './AdminChartsEdit';
export default class AdminCharts extends React.Component {
  componentDidMount() {
    
  }
  render() {
    return (
      <div className='card text-left'>
          <h5 className="card-header">Admin Color Charts</h5>
          <div className='card-body'>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="pills-create-tab" data-toggle="pill" href="#pills-create" role="tab" aria-controls="pills-create" aria-selected="true">Create</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pills-edit-tab" data-toggle="pill" href="#pills-edit" role="tab" aria-controls="pills-edit" aria-selected="false">Edit</a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-create" role="tabpanel" aria-labelledby="pills-create-tab"><AdminChartsCreate/></div>
                <div className="tab-pane fade" id="pills-edit" role="tabpanel" aria-labelledby="pills-edit-tab"><AdminChartsEdit/></div>
            </div>
          </div>
      </div>
    )
    }
  };