import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminConsCatEdit from './AdminConsCatEdit';
import AdminConsSubCatEdit from './AdminConsSubCatEdit';
import AdminConsProductEdit from './AdminConsProductEdit';
import AdminConsSkuEdit from './AdminConsSkuEdit';

export default class AdminConsEdit extends React.Component {
  componentDidMount() {
    
  }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));

    return (
      <div>
            <ul className="nav nav-pills mb-3" id="pills-tabcC" role="tablist">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#dropdown" role="button" aria-haspopup="true" aria-expanded="false">Select Type</a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" id="pills-category-tab-ec" data-toggle="pill" href="#pills-category-ec" role="tab" aria-controls="pills-category" aria-selected="false">Category</a>
                    <a className="dropdown-item" id="pills-subcat-tab-ec" data-toggle="pill" href="#pills-subcat-ec" role="tab" aria-controls="pills-subcat" aria-selected="false">Sub Category</a>
                    <a className="dropdown-item" id="pills-product-tab-ec" data-toggle="pill" href="#pills-product-ec" role="tab" aria-controls="pills-product" aria-selected="false">Product</a>
                    <a className="dropdown-item" id="pills-skus-tab-ec" data-toggle="pill" href="#pills-skus-ec" role="tab" aria-controls="pills-skus" aria-selected="false">SKUs</a>
                  </div>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabcCContent">
                <div className="tab-pane fade" id="pills-category-ec" role="tabpanel" aria-labelledby="pills-category-tab"><AdminConsCatEdit categories={this.props.categories}/></div>
                <div className="tab-pane fade" id="pills-subcat-ec" role="tabpanel" aria-labelledby="pills-subcat-tab"><AdminConsSubCatEdit {...this.props}/></div>
                <div className="tab-pane fade" id="pills-product-ec" role="tabpanel" aria-labelledby="pills-product-tab"><AdminConsProductEdit {...this.props}/></div>
                <div className="tab-pane fade" id="pills-skus-ec" role="tabpanel" aria-labelledby="pills-skus-tab"><AdminConsSkuEdit {...this.props}/></div>
            </div>
      </div>
    )
    }
  };