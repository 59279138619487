import React from 'react';
import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';
import $ from 'jquery';

import ConsSKU from './ConsSKU';



function Confirm(title, msg, $true, $false, $link) { /*change*/
  var $content =  "<div class='dialog-ovelay'>" +
                  "<div class='dialog'><header>" +
                   " <h2> " + title + " </h2> " +
             //      "<i class='navbar-text fas fa-times'></i>" +
               "</header>" +
               "<div class='dialog-msg'>" +
                   " <p> " + msg + " </p> " +
               "</div>" +
               "<footer>" +
                   "<div class='controls'>" +
                   " <button class='btn btn-primary doAction'>" + $true + "</button> " +
                   " <button class='btn btn-light cancelAction'>" + $false + "</button> " +
                   "</div>" +
               "</footer>" +
            "</div>" +
          "</div>";
   $('body').prepend($content);
$('.doAction').click(function () {
  window.open($link, "_blank"); /*new*/
  $(this).parents('.dialog-ovelay').fadeOut(500, function () {
    $(this).remove();
  });
});
$('.cancelAction, .fa-close').click(function () {
  $(this).parents('.dialog-ovelay').fadeOut(500, function () {
    $(this).remove();
  });
});

}


export default class ConsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      active_cat: '1',
      freeze: false
    };
    this._getFreezeMode = this._getFreezeMode.bind(this);
  }

  componentDidMount() {
    this._getFreezeMode();
    setInterval(function(){forceCheck();},100);
  }

  _getFreezeMode() {
    var self = this;
   // console.log('Getting freeze_mode');
    $.get('/freeze_mode',function(data,status,xhr){
   //   console.log('/freeze_mode status: ' + status + ' Mode: ' + data.mode);
      self.setState({freeze: data.mode});
    },'json');
  }

  _onClickC(e, product){
    if (product.disclaimer){
    e.preventDefault();
    //console.log('e:', e.currentTarget.getAttribute('href'));
    var target = e.currentTarget.getAttribute('href');
    Confirm('Disclaimer', 'These color charts are only a guideline, and CANNOT be used to make your final color selection. Computer screens and printers vary greatly and do not accurately represent the colors that will be obtained. We strongly recommend creating a sample with the materials you will use to be sure the color meets your expectations. Please contact our Customer Service Department at info@interstar-paf.com or an Interstar sales representative for information on seeing an Interstar sample kit or obtaining samples.', 'OK', 'Close', target);
    //if (!window.confirm('These color charts are only a guideline, and CANNOT be used to make your final color selection. Computer screens and printers vary greatly and do not accurately represent the colors that will be obtained. We strongly recommend creating a sample with the materials you will use to be sure the color meets your expectations. Please contact our Customer Service Department at info@interstar-paf.com or an Interstar sales representative for information on seeing an Interstar sample kit or obtaining samples.')) {e.preventDefault();}
    }
  }

  _onClickCF(e, product){
    if (product.disclaimer){
    e.preventDefault();
    var target = e.currentTarget.getAttribute('href');
    Confirm('Important', 'Étant donné que la couleur du béton peut être affectée par de nombreux facteurs, il faut utiliser cette charte de couleurs uniquement à titre indicatif. Elle offre un apercu des couleurs pouvant être obtenues. Veuillez consulter le coffret de couleurs pour béton préparé d’Interstar pour une représentation plus précise de nos couleurs pour béton. Une maquette fait avec vos matériaux et vos techniques de finition est le meilleur moyen de déterminer si la couleur désirée peut être obtenue.', 'OK', 'Fermer', target);
    }
  }

  



  render() {
    var self = this;
    return (
      <div className="tab-content text-left" id="nav-tabContent">
        
        {
        this.props.categories.map(categorie => 

          <div key={categorie.id} className={'cprod_panel_cat tab-pane fade ' + (self.state.active_cat === categorie.id ? 'show active' : '')} role="tabpanel" id={'pronav' + categorie.id}>
              <div className='col-sm-11 ml-0 px-0'>
              <div className="tab-content text-left" id="nav-tabContent">
              {
                categorie.products.map(product => <div key={product.id} className='cprod_panel tab-pane fade' role="tabpanel" id={'pronav' + product.id}>
                    <h3 className='my-3' style={{fontSize:'38px'}}>{self.props.lang === 'EN' ? product.name_en : product.name_fr}
                    { (product.freeze && self.state.freeze) ? <NavLink className='text-white' to='/shipping-terms#temperatureEN' exact activeClassName='active'><i className="ml-4 fas fa-snowflake" style={{color: '#0a69ba'}} data-toggle="tooltip" data-placement="top" title="Freeze Danger"></i></NavLink> : ''}
                   {/* <span class="ml-5 fa-stack">
                        <i class="fas fa-circle fa-stack-2x" style={{color: '#0a69ba'}}></i>
                        <i class="fas fa-snowflake fa-stack-1x fa-inverse"></i>
              </span> */ }
                    </h3>
                    { self.props.lang === 'EN' ? <div className='row'>
                    {product.show_f1 === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' onClick={(e) => {self._onClickC(e, product)}} href={'./downloads/' + product.file1} target='_blank' rel='noopener noreferrer'>{product.name_f1}</a>
                    </div> : <div> </div>}
                    {product.show_f2 === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file2} target='_blank' rel='noopener noreferrer'>{product.name_f2}</a>
                    </div> : <div> </div>}
                    {product.show_f3 === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file3} target='_blank' rel='noopener noreferrer'>{product.name_f3}</a>
                    </div> : <div> </div>}
                    </div>
                    : <div className='row'>
                    {product.show_f1_fr === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' onClick={(e) => {self._onClickCF(e, product)}} href={'./downloads/' + product.file1_fr} target='_blank' rel='noopener noreferrer'>{product.name_f1_fr}</a>
                    </div> : <div> </div>}
                    {product.show_f2_fr === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file2_fr} target='_blank' rel='noopener noreferrer'>{product.name_f2_fr}</a>
                    </div> : <div> </div>}
                    {product.show_f3_fr === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file3_fr} target='_blank' rel='noopener noreferrer'>{product.name_f3_fr}</a>
                    </div> : <div> </div>}
                    </div>
                    }
                    <p className='my-3' style={{fontSize:'17px'}}>{self.props.lang === 'EN' ? product.desc_en : product.desc_fr}</p>
                    {product.skus.map(sku => 
                   /* <div key={sku.id} className='card border-0 border-bottom my-3'>
                    <div className='row'>
                        <div className='col-md-4'><LazyLoad height={200} offset={100}><img className='img-fluid' src={'./img/consumer/' + sku.img} alt='sampletile'></img></LazyLoad></div>
                       <div className='col-md-8 ml-0 px-0'>
                        <h6 className='text-left card-title mb-2 mt-1' style={{fontSize:'24px'}}>{sku.name_en}</h6>
            { ((self.props.country === 'CA') && (sku.sale_ca)) ? <h6 className='mt-3' style={{fontSize:'17px'}}>{'$' + sku.price_cad + ' CAD'}</h6> :
              ((self.props.country === 'US') && (sku.sale_us)) ? <h6 className='mt-3' style={{fontSize:'17px'}}>{'$' + sku.price_usd + ' USD'}</h6> : '' }
                        <h6 className='mt-3' style={{fontSize:'17px'}}>{ sku.id}</h6>
                        { ((self.props.country === 'CA') && (sku.sale_ca)) ? 
                        <button className='mt-3 btn btn-large rounded-0 border-0 cons-button'>ADD TO CART</button>
                        :''}
                        </div>

                    </div>
                    
                    <hr className='mb-0' style={{height:'1px', border:'none',color:'black','backgroundColor':'black'}}></hr>
                    </div> */
                    <LazyLoad height={200} offset={100}> <ConsSKU sku={sku} lang={self.props.lang} country={self.props.country}></ConsSKU> </LazyLoad>
                    )}
                  </div>
                )
                
              }
              {
                categorie.subcategorie.map(subcat => 
                  subcat.products.map(product => <div key={product.id + ' ' + subcat.id} className='csubprod_panel tab-pane fade' role="tabpanel" id={'pronav' + product.id}>
                  <h3 className='my-3' style={{fontSize:'38px'}}>{self.props.lang === 'EN' ? product.name_en : product.name_fr}
                  { (product.freeze && self.state.freeze) ? <NavLink className='text-white' to='/shipping-terms#temperatureEN' exact activeClassName='active'><i className="ml-4 fas fa-snowflake" style={{color: '#0a69ba'}} data-toggle="tooltip" data-placement="top" title="Freeze Danger"></i></NavLink> : ''}
                 {/* <span class="ml-5 fa-stack">
                      <i class="fas fa-circle fa-stack-2x" style={{color: '#0a69ba'}}></i>
                      <i class="fas fa-snowflake fa-stack-1x fa-inverse"></i>
            </span> */ }
                  </h3>
                  { self.props.lang === 'EN' ? <div className='row'>
                    {product.show_f1 === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' onClick={(e) => {self._onClickC(e, product)}} href={'./downloads/' + product.file1} target='_blank' rel='noopener noreferrer'>{product.name_f1}</a>
                    </div> : <div> </div>}
                    {product.show_f2 === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file2} target='_blank' rel='noopener noreferrer'>{product.name_f2}</a>
                    </div> : <div> </div>}
                    {product.show_f3 === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file3} target='_blank' rel='noopener noreferrer'>{product.name_f3}</a>
                    </div> : <div> </div>}
                    </div>
                    : <div className='row'>
                    {product.show_f1_fr === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' onClick={(e) => {self._onClickCF(e, product)}} href={'./downloads/' + product.file1_fr} target='_blank' rel='noopener noreferrer'>{product.name_f1_fr}</a>
                    </div> : <div> </div>}
                    {product.show_f2_fr === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file2_fr} target='_blank' rel='noopener noreferrer'>{product.name_f2_fr}</a>
                    </div> : <div> </div>}
                    {product.show_f3_fr === true ? <div className='mx-2'>
                      <a role='button' className='btn btn-lg btn-info product-button my-2' href={'./downloads/' + product.file3_fr} target='_blank' rel='noopener noreferrer'>{product.name_f3_fr}</a>
                    </div> : <div> </div>}
                    </div>
                    }
                  <p className='my-3' style={{fontSize:'17px'}}>{self.props.lang === 'EN' ? product.desc_en : product.desc_fr}</p>
                  {product.skus.map(sku => 
                 /* <div key={sku.id} className='card border-0 border-bottom my-3'>
                  <div className='row'>
                      <div className='col-md-4'><LazyLoad height={200} offset={100}><img className='img-fluid' src={'./img/consumer/' + sku.img} alt='sampletile'></img></LazyLoad></div>
                     <div className='col-md-8 ml-0 px-0'>
                      <h6 className='text-left card-title mb-2 mt-1' style={{fontSize:'24px'}}>{sku.name_en}</h6>
          { ((self.props.country === 'CA') && (sku.sale_ca)) ? <h6 className='mt-3' style={{fontSize:'17px'}}>{'$' + sku.price_cad + ' CAD'}</h6> :
            ((self.props.country === 'US') && (sku.sale_us)) ? <h6 className='mt-3' style={{fontSize:'17px'}}>{'$' + sku.price_usd + ' USD'}</h6> : '' }
                      <h6 className='mt-3' style={{fontSize:'17px'}}>{ sku.id}</h6>
                      { ((self.props.country === 'CA') && (sku.sale_ca)) ? 
                      <button className='mt-3 btn btn-large rounded-0 border-0 cons-button'>ADD TO CART</button>
                       : ''}
                      </div>

                  </div>
                  
                  <hr className='mb-0' style={{height:'1px', border:'none',color:'black','backgroundColor':'black'}}></hr>
                  </div> */
                  <LazyLoad height={200} offset={100}><ConsSKU sku={sku} lang={self.props.lang} country={self.props.country}></ConsSKU></LazyLoad>
                  )}
                </div>
              )  
                )
              }
              </div>
              </div>
          </div>
          

        )
        }
      </div>
    )
  }
};
