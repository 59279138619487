
const colorCharts = [
  /*{
    'id': 'Integral Colors',
    'display_en':true,
    'display_fr':true,
    'name_en': 'Preweighed Powder Pigment',
    'name_fr': 'Pigments en poudre prédosé',
    'image_en': 'Integral Colors -  Color Chart cover_web.jpg',
    'image_fr': 'Integral Colors -  Color Chart cover_FR_web.jpg',
    'pdf_en': 'Integral Color for Ready Mix_EN_web 2019.pdf',
    'pdf_fr': 'Integral Color for Ready Mix_FR_web 2019.pdf'
  },
  {
    'id': 'Granular Pigments',
    'display_en':true,
    'display_fr':true,
    'name_en': 'Granular Pigments for Dispensers',
    'name_fr': 'Pigments Granulaires Pour',
    'image_en': 'Integral Granular Pigment Color th.jpg',
    'image_fr': 'Pigments granulaires pour coloration intégrale (Integral Granular Pigment) Color Chart_FR_web 2019.jpg',
    'pdf_en': 'Integral Granular Pigment Color Chart_EN_web 2019.pdf',
    'pdf_fr': 'Pigments granulaires pour coloration integrale FR_web 2019.pdf'
  },
  {
    'id': 'Stamping Release',
    'display_en':true,
    'display_fr':true,
    'name_en': 'Stamping Release',
    'name_fr': "Poudre d'estampage pour le béton",
    'image_en': 'Stamping Release - Color Chart cover_web.jpg',
    'image_fr': 'Stamping Release - Color Chart cover_FR_web.jpg',
    'pdf_en': 'Stamping Release_EN_web 2019.pdf',
    'pdf_fr': 'Stamping Release_FR_web 2019.pdf'
  },
  {
    'id': 'Mortar Color',
    'display_en':true,
    'display_fr':true,
    'name_en': 'Mortar Color',
    'name_fr': 'Couleurs pour mortier',
    'image_en': 'Mortar - Color Chart cover web.jpg',
    'image_fr': 'Mortar - Color Chart cover_FR_web.jpg',
    'pdf_en': 'Mortar Color Chart_EN_web 2019.pdf',
    'pdf_fr': 'Mortar Color Chart_FR_web 2019.pdf'
  },
  {
    'id': 'NStar',
    'display_en':true,
    'display_fr':true,
    'name_en': 'NStar - Non-Reactive Stain For Concrete',
    'name_fr': 'NStar - Teintures non réactives pour surface de béton',
    'image_en': 'NStar - Color Chart cover_web.jpg',
    'image_fr': 'NStar - Color Chart cover_FR_web.jpg',
    'pdf_en': 'NStar Color Chart_EN_web 2019.pdf',
    'pdf_fr': 'NStar Color Chart_FR_web 2019.pdf'
  },
  {
    'id': 'Color Hardener',
    'display_en':true,
    'display_fr':true,
    'name_en': 'Color Hardener',
    'name_fr': 'Durcisseur coloré',
    'image_en': 'Color Hardener - Color Chart cover_web.jpg',
    'image_fr': 'Color Hardener - Color Chart cover_FR_web.jpg',
    'pdf_en': 'Color Hardener Color Chart_EN_web 2019.pdf',
    'pdf_fr': 'Color Hardener Color Chart_FR_web 2019.pdf'
  },
  {
    'id': 'Stucco',
    'display_en':true,
    'display_fr':false,
    'name_en': 'Stucco',
    'name_fr': 'Stucco',
    'image_en': 'Stucco - Color Chart cover_web.jpg',
    'image_fr': 'Stucco - Color Chart cover_web.jpg',
    'pdf_en': 'Stucco Color Chart_EN_web 2019.pdf',
    'pdf_fr': 'Stucco Color Chart_EN_web 2019.pdf'
  },
  {
    'id': 'Mulch',
    'display_en':true,
    'display_fr':true,
    'name_en': 'Mulch',
    'name_fr': 'Paillis',
    'image_en': 'Mulch - Color Chart cover_web_0.jpg',
    'image_fr': 'Mulch - Color Chart cover_FR_web_0.jpg',
    'pdf_en': 'Mulch Color Chart_EN_web 2019.pdf',
    'pdf_fr': 'Mulch Color Chart_FR_web 2019.pdf'
  },
  {
    'id': 'CX Concrete',
    'display_en':true,
    'display_fr':true,
    'name_en': 'CX Concrete Countertop Pro-Formula - Indoor',
    'name_fr': 'CX Pro-Formula mélange pour comptoir de béton',
    'image_en': 'CX_Concrete Countertop Pro-Formula_indoor_EN_web 2019.jpg',
    'image_fr': 'CX_Concrete Countertop Pro-Formula_indoor_FR_web 2019.jpg',
    'pdf_en': 'CX_Concrete Countertop Pro-Formula_indoor_EN_web 2019.pdf',
    'pdf_fr': 'CX_Concrete Countertop Pro-Formula_indoor_FR_web 2019.pdf'
  },
  {
    'id': 'CX All Weather',
    'display_en':true,
    'display_fr':true,
    'name_en': 'CX All Weather Concrete Pro-Formula',
    'name_fr': 'CX Pro-Formula mélange tout climat',
    'image_en': 'CX_Concrete Countertop Pro-Formula_outdoor_EN_web 2019.jpg',
    'image_fr': 'CX_Concrete Countertop Pro-Formula_outdoor_FR_web 2019.jpg',
    'pdf_en': 'CX_Concrete Countertop Pro-Formula_outdoor_EN_web 2019.pdf',
    'pdf_fr': 'CX_Concrete Countertop Pro-Formula_outdoor_FR_web 2019.pdf'
  }, */
];

export default colorCharts;