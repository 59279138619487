import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminConsCatItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          sort: 0,
          name_en: '',
          name_fr: '',
          display_us: 'on',
          display_ca: '',
          display_gl: '',
        };
        this._unlockItem = this._unlockItem.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);
        this._updateSort = this._updateSort.bind(this);
        this._updateNameEn = this._updateNameEn.bind(this);
        this._updateNameFr = this._updateNameFr.bind(this);
        this._updateDispCa = this._updateDispCa.bind(this);
        this._updateDispUs = this._updateDispUs.bind(this);
        this._updateDispGl = this._updateDispGl.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
      }
    
      componentDidMount() {
      //  this._getChartData();
      }
    
      _unlockItem(){
        this.setState({
          locked:false,
          sort: this.props.category.order,
          name_en: this.props.category.name_en,
          name_fr: this.props.category.name_fr,
          display_us: this.props.category.show_us,
          display_ca: this.props.category.show_ca,
          display_gl: this.props.category.show_gl,
        });
      }
      _lockItem(){
        this.setState({locked:true});
      }
      _saveItem(){
        this.setState({locked:true});
      }
      _updateSort(e){
        var str = e.target.value;
        this.setState({sort:str});
      }
      _updateNameEn(e){
        var str = e.target.value;
        this.setState({name_en:str});
      }
      _updateNameFr(e){
        var str = e.target.value;
        this.setState({name_fr:str});
      }
      _updateDispCa(){
       // var str;// = e.target.value;
       // console.log('str en: ' + str);
        if (this.state.display_ca === true) {
          this.setState({display_ca: false});
        } else {
          this.setState({display_ca: true});
        }
      }
      _updateDispUs(){
       // var str = e.target.value;
      //  console.log('str fr: ' + str);
        if (this.state.display_us === true) {
          this.setState({display_us: false});
        } else {
          this.setState({display_us: true});
        }
      }
      _updateDispGl(){
        // var str = e.target.value;
       //  console.log('str fr: ' + str);
         if (this.state.display_gl === true) {
           this.setState({display_gl: false});
         } else {
           this.setState({display_gl: true});
         }
       }
       _onSubmit(e) {
        e.preventDefault();
        
        var self = this;

        var form = $("#ccform" + this.props.category.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
                self.setState({locked:true});
                //  $("#btnSubmit").prop("disabled", false);
            //$('#chartcreate')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
    }
  render() {
    return (
/**
 * id:'1',
      name_en:'Pigments',
      name_fr:'Une',
      order: 1,
      show_ca: true,
      show_us: true,
      show_gl: true,
      image: 'banner_pigment_2019-01_3.jpg'
 */

        this.state.locked ? <tr key={this.props.category.id}>
              <td>{this.props.category.id}</td>
              <td>{this.props.category.order}</td>
              <td>{this.props.category.name_en}</td>
              <td>{this.props.category.name_fr}</td>
              <td>{this.props.category.show_ca ? 'true':'false'}</td>
              <td>{this.props.category.show_us ? 'true':'false'}</td>
              <td>{this.props.category.show_gl ? 'true':'false'}</td>
              <td className='d-flex justify-content-between'>
              <button className="btn btn-sm btn-outline-success" onClick={this._unlockItem}><i className='fas fa-lock' style={{color:'green', cursor:'pointer'}}></i></button>
              <button className="btn btn-sm btn-outline-danger" ><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
          </tr> : <tr>
            
              <td><input type="text" name="id" form={"ccform" + this.props.category.id} readonly className="form-control-plaintext text-light" value={this.props.category.id}/></td>
              <td><input type="number" name="sort" form={"ccform" + this.props.category.id} className='form-control form-control-sm px-1' value={this.state.sort} onChange={this._updateSort}/></td>
              <td><input type="text" name="name_en" form={"ccform" + this.props.category.id} className='form-control form-control-sm px-1' value={this.state.name_en} onChange={this._updateNameEn}/></td>
              <td><input type="text" name="name_fr" form={"ccform" + this.props.category.id} className='form-control form-control-sm px-1' value={this.state.name_fr} onChange={this._updateNameFr}/></td>
              <td>
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='show_ca' className="custom-control-input" id="visibleCAce" form={"ccform" + this.props.category.id} onClick={this._updateDispCa} checked={this.state.display_ca === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleCAce">CA</label>
                </div>
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='show_us' className="custom-control-input" id="visibleUSce" form={"ccform" + this.props.category.id} onClick={this._updateDispUs} checked={this.state.display_us === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleUSce">US</label>
              </div>
              
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='show_gl' className="custom-control-input" id="visibleGLce" form={"ccform" + this.props.category.id} onClick={this._updateDispGl} checked={this.state.display_gl === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleGLce">GL</label>
              </div>
              
              </td>
              <td className='d-flex justify-content-between'>
                <button className="btn btn-sm btn-outline-success" onClick={this._lockItem}><i className='fas fa-lock-open' style={{color:'green', cursor:'pointer'}}></i></button>
                <form id={"ccform" + this.props.category.id} action='/admin/update_conscat' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
                  <button type="submit" className="btn btn-sm btn-outline-success"><i className='fas fa-save' style={{color:'green'}}></i></button>
                </form>
                <button className="btn btn-sm btn-outline-danger"><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
            
          </tr>
    )
    }
  };