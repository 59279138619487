import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminConsCatItem from './AdminConsCatItem';


export default class AdminConsCatEdit extends React.Component {
  componentDidMount() {
    
  }
  render() {
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Consumer Category Edit</h5>
            <table class="table table-sm table-striped table-hover table-dark">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sort</th>
                    <th scope="col">English Title</th>
                    <th scope="col">French Title</th>
                    <th scope="col">Show Can</th>
                    <th scope="col">Show US</th>
                    <th scope="col">Show Global</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                    {
                        this.props.categories.map(thiscat => 
                            <AdminConsCatItem category={thiscat} />    
                        )
                    }
                </tbody>
            </table>


      </div>
    )
    }
  };