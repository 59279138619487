import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminProCreate from './AdminProCreate';
import AdminProEdit from './AdminProEdit';
import AdminProSearch from './AdminProSearch';

export default class AdminProPage extends React.Component {

  render() {
    return (
      <div className='card text-left'>
          <h5 className="card-header">Admin Professional Products</h5>
          <div className='card-body'>
            <ul className="nav nav-pills mb-3" id="pills-tabp" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="pills-createp-tab" data-toggle="pill" href="#pills-createp" role="tab" aria-controls="pills-createp" aria-selected="true">Create</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pills-editp-tab" data-toggle="pill" href="#pills-editp" role="tab" aria-controls="pills-editp" aria-selected="false">Edit</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pills-searchp-tab" data-toggle="pill" href="#pills-searchp" role="tab" aria-controls="pills-searchp" aria-selected="false">Search</a>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabpContent">
                <div className="tab-pane fade show active" id="pills-createp" role="tabpanel" aria-labelledby="pills-createp-tab"><AdminProCreate {...this.props} /></div>
                <div className="tab-pane fade" id="pills-editp" role="tabpanel" aria-labelledby="pills-editp-tab"><AdminProEdit {...this.props} /></div>
                <div className="tab-pane fade" id="pills-searchp" role="tabpanel" aria-labelledby="pills-searchp-tab"><AdminProSearch {...this.props} /></div>
            </div>
          </div>
      </div>
    )
    }
  };