import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminConsCatCreate from './AdminConsCatCreate';
import AdminConsSubCatCreate from './AdminConsSubCatCreate';
import AdminConsProductCreate from './AdminConsProductCreate';
import AdminConsProdSKUCreate from './AdminConsProdSKUCreate';
export default class AdminConsCreate extends React.Component {
  componentDidMount() {
    
  }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));

    return (
      <div>
            <ul className="nav nav-pills mb-3" id="pills-tabcC" role="tablist">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" href='#dropdown' aria-haspopup="true" aria-expanded="false">Select Type</a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" id="pills-categoryc-tab" data-toggle="pill" href="#pills-categoryc" role="tab" aria-controls="pills-category" aria-selected="false">Category</a>
                    <a className="dropdown-item" id="pills-subcact-tab" data-toggle="pill" href="#pills-subcatc" role="tab" aria-controls="pills-subcat" aria-selected="false">Sub Category</a>
                    <a className="dropdown-item" id="pills-productc-tab" data-toggle="pill" href="#pills-productc" role="tab" aria-controls="pills-product" aria-selected="false">Product</a>
                    <a className="dropdown-item" id="pills-sku-tab" data-toggle="pill" href="#pills-sku" role="tab" aria-controls="pills-sku" aria-selected="false">SKU</a>
                  </div>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabcCContent">
                <div className="tab-pane fade" id="pills-categoryc" role="tabpanel" aria-labelledby="pills-categoryc-tab"><AdminConsCatCreate/></div>
                <div className="tab-pane fade" id="pills-subcatc" role="tabpanel" aria-labelledby="pills-subcatc-tab"><AdminConsSubCatCreate categories={this.props.categories}/></div>
                <div className="tab-pane fade" id="pills-productc" role="tabpanel" aria-labelledby="pills-productc-tab"><AdminConsProductCreate {...this.props}/></div>
                <div className="tab-pane fade" id="pills-sku" role="tabpanel" aria-labelledby="pills-sku-tab"><AdminConsProdSKUCreate {...this.props}/></div>
            </div>
      </div>
    )
    }
  };