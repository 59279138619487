import React from 'react';
import $ from 'jquery';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
//import AdminConsCatCreate from './AdminConsCatCreate';
//import AdminConsSubCatCreate from './AdminConsSubCatCreate';
//import AdminConsProductCreate from './AdminConsProductCreate';
//import AdminConsProdSKUCreate from './AdminConsProdSKUCreate';
export default class AdminDownCreate extends React.Component {
  componentDidMount() {
    
  }
  _onSubmit(e) {
    e.preventDefault();
    
    var form = $('#createdownload')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 20000,
        success: function (data) {
        // $("#result").text(data);
            alert("SUCCESS : " + data);
        //  $("#btnSubmit").prop("disabled", false);
        $('#createdownload')[0].reset();
        //$('#contactForm')[0].reset();
        },
        error: function (err) {
        // $("#result").text(e.responseText);
            alert("ERROR : " + JSON.stringify(err));
        //  $("#btnSubmit").prop("disabled", false);
        }
    });
    }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));
    /**
     * 'id': 'Easystar',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Easystar Dispenser Brochure',
      'name_fr': 'Brochure Easystar',
      'image_en': '../img/downloads/en/EasySTAR dispenser_FR_web 2019.jpg',
      'image_fr': '../img/downloads/en/EasySTAR dispenser_FR_web 2019.jpg',
      'pdf_en': '../downloads/EasySTAR dispenser_EN_web 2019.pdf',
      'pdf_fr': '../downloads/EasySTAR dispenser_FR_web 2019.pdf'
     */
    return (
      <div>
        <h5>
          Create Download Items
        </h5>
        <form id="createdownload" action='/admin/createdownload' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control" id="InputID1d" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control" id="InputENd" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control" id="InputFRd" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='display_en' className="custom-control-input" id="visibleENGd"/>
            <label className="custom-control-label" htmlFor="visibleENGd">Show English</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='display_fr' className="custom-control-input" id="visibleFRd"/>
            <label className="custom-control-label" htmlFor="visibleFRd">Show French</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English File</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='pdf_en' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French File</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='pdf_fr' className="form-control-file form-control-sm"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
          </form>
      </div>
    )
    }
  };