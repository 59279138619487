import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class PrivacyPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
  //  var self = this;
    return (
      <div>
      <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         marginTop:'-170px'}}>
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Privacy Policy':"Politique de Confidentialité"}</h1>
      </div>
      <div className='card bg-white border-0 rounded-0'>
       { this.props.lang === 'EN' ? <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>

        <h5 className='text-left'>Your individual information and privacy are very important to us. We are committed to making sure that your information is kept confidential. We do not collect personal information online.</h5>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>This Privacy Policy governs the way Interstar collects, uses, maintains and discloses information collected from users (each, a “User”) of the interstar-paf.com website (“Site”). This privacy policy applies to the Site and all products and services offered by Interstar Materials, Inc.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Personal identification information</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Interstar does not collect Users' personal information.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Non-personal identification information</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Web browser cookies</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Our Site may use “cookies” to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>How we use collected information</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Interstar does not collect Users' personal information.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>How we protect your information</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Sharing your personal information</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>We do not sell, trade, or rent Users personal identification information to others.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Changes to this privacy policy</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Interstar has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Your acceptance of these terms</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>By using this Site, you signify your acceptance of this policy. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Contacting us</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:</p>
        <div className='text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Interstar Materials, Inc.</h6>
              <div>4255 Portland Boulevard</div>
              <div>Sherbrooke, Quebec  J1L 3A5</div>
              <div>Canada</div>
            </div>
        </div> : <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
        <h5 className='text-left'>Votre information personnelle et votre vie privée sont très importantes pour nous. Nous nous engageons à veiller à ce que vos informations restent confidentielles. Nous ne recueillons pas de renseignements personnels en ligne.</h5>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>La Politique de confidentialité régit la façon dont Interstar recueille, utilise, conserve et divulgue les renseignements recueillis auprès des utilisateurs (chacun, un « utilisateur») du site Web interstar-paf.com (le « site»). Cette politique de confidentialité s'applique au site et à tous les produits et services offerts par Les Matériaux Interstar, Inc.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Information d'identification personnel</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Interstar ne recueille pas les renseignements personnels des utilisateurs.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Information d'identification non-personnelles</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Nous pouvons collecter des informations d'identification non personnelles sur les utilisateurs chaque fois qu'ils interagissent avec notre site. Les informations d'identification non personnelles peuvent inclure le nom du navigateur, le type d'ordinateur et des informations techniques sur les moyens de connexion des utilisateurs à notre site, telles que le système d'exploitation et les fournisseurs de services Internet utilisés et d'autres informations similaires.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Cookies de navigateur Web</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Notre site peut utiliser des « cookies » pour améliorer l'expérience de l'utilisateur. Le navigateur Web de l'utilisateur place des cookies sur son disque dur à des fins d'archivage et parfois pour suivre les informations le concernant. L'utilisateur peut choisir de configurer son navigateur Web pour refuser les cookies ou pour vous avertir lorsque des cookies sont envoyés. Si c'est le cas, notez que certaines parties du site peuvent ne pas fonctionner correctement.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Comment utilisons-nous les informations collectées?</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Interstar ne recueille pas les renseignements personnels des utilisateurs.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Comment protégeons-nous vos informations?</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Nous adoptons des pratiques appropriées de collecte, de stockage et de traitement des données ainsi que des mesures de sécurité pour nous protéger contre l'accès, l'altération, la divulgation ou la destruction non autorisés de vos informations personnelles, de votre nom d'utilisateur, de votre mot de passe, de vos informations de transaction et de vos données stockées sur notre site.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Partage de vos informations personnelles</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Nous ne vendons pas, n'échangeons pas et ne louons pas les informations d'identification personnelle des utilisateurs à des tiers partis.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Modifications à la politique de confidentialité</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Interstar se réserve le droit de mettre à jour cette politique de confidentialité à tout moment. Lorsque nous le ferons, nous réviserons la date de mise à jour au bas de cette page. Nous encourageons les utilisateurs à consulter fréquemment cette page pour prendre connaissance de tout changement afin de rester informés de la façon dont nous contribuons à protéger les informations personnelles que nous recueillons. Vous reconnaissez et acceptez qu'il est de votre responsabilité de consulter périodiquement cette politique de confidentialité et de prendre connaissance des modifications.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Votre acceptation de ces conditions</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>En utilisant ce site, vous signifiez votre acceptation de cette politique. Votre utilisation continue du site après la publication des modifications apportées à la présente politique sera considérée comme votre acceptation de ces modifications.</p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Nous rejoindre</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>Si vous avez des questions au sujet de la présente politique de confidentialité, des pratiques de ce site ou de votre utilisation avec ce site, veuillez communiquer avec nous à l'adresse suivante :</p>
        <div className='text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Les Matériaux Interstar, Inc</h6>
              <div>4255 Boulevard de Portland </div>
              <div>Sherbrooke, Quebec  J1L 3A5</div>
              <div>Canada</div>
            </div>
          </div> }
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;