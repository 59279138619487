import React from 'react';
//import LazyLoad from 'react-lazyload';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class ModalGallery extends React.Component {
// const ModalGallery = () => {
    _scroll_ele() {
      return function(){
        window.scrollBy(0, -1);
        setTimeout(function(){
          window.scrollBy(0, 1);
        },5);
      }
    }
    render() {
      var self = this;
      console.log('Load Gallery Modal: ' + JSON.stringify(self.props.galleryImages));
    return (
      <div>
        <div id="mdGallery" className="carousel" data-interval="0" data-ride="carousel">
              <ol className="carousel-indicators bg-dark">
                {
                  self.props.galleryImages.map(function(thisimage, idxIND){
                    return <li id={'sld' + idxIND} data-target="#mdGallery" data-slide-to={idxIND.toString()} onClick={self._scroll_ele()}></li>
                  })
                }
                {/*
                <li id='sld0' data-target="#mdGallery" data-slide-to="0" onClick={self._scroll_ele()}></li>
                <li id='sld1' data-target="#mdGallery" data-slide-to="1" onClick={self._scroll_ele()}></li>
                <li id='sld2' data-target="#mdGallery" data-slide-to="2" onClick={self._scroll_ele()}></li>
                <li id='sld3' data-target="#mdGallery" data-slide-to="3" onClick={self._scroll_ele()}></li>
                <li id='sld4' data-target="#mdGallery" data-slide-to="4" onClick={self._scroll_ele()}></li>
                <li id='sld5' data-target="#mdGallery" data-slide-to="5" onClick={self._scroll_ele()}></li>
                <li id='sld6' data-target="#mdGallery" data-slide-to="6" onClick={self._scroll_ele()}></li>
                <li id='sld7' data-target="#mdGallery" data-slide-to="7" onClick={self._scroll_ele()}></li>
                <li id='sld8' data-target="#mdGallery" data-slide-to="8" onClick={self._scroll_ele()}></li>
                <li id='sld9' data-target="#mdGallery" data-slide-to="9" onClick={self._scroll_ele()}></li>
                <li id='sld10' data-target="#mdGallery" data-slide-to="10" onClick={self._scroll_ele()}></li>
                <li id='sld11' data-target="#mdGallery" data-slide-to="11" onClick={self._scroll_ele()}></li>
                <li id='sld12' data-target="#mdGallery" data-slide-to="12" onClick={self._scroll_ele()}></li>
                <li id='sld13' data-target="#mdGallery" data-slide-to="13" onClick={self._scroll_ele()}></li>
                <li id='sld14' data-target="#mdGallery" data-slide-to="14" onClick={self._scroll_ele()}></li>
                <li id='sld15' data-target="#mdGallery" data-slide-to="15" onClick={self._scroll_ele()}></li>
                <li id='sld16' data-target="#mdGallery" data-slide-to="16" onClick={self._scroll_ele()}></li>
                <li id='sld17' data-target="#mdGallery" data-slide-to="17" onClick={self._scroll_ele()}></li>
                <li id='sld18' data-target="#mdGallery" data-slide-to="18" onClick={self._scroll_ele()}></li>
                <li id='sld19' data-target="#mdGallery" data-slide-to="19" onClick={self._scroll_ele()}></li>
                <li id='sld20' data-target="#mdGallery" data-slide-to="20" onClick={self._scroll_ele()}></li>
                <li id='sld21' data-target="#mdGallery" data-slide-to="21" onClick={self._scroll_ele()}></li>
                <li id='sld22' data-target="#mdGallery" data-slide-to="22" onClick={self._scroll_ele()}></li>
                <li id='sld23' data-target="#mdGallery" data-slide-to="23" onClick={self._scroll_ele()}></li>
                <li id='sld24' data-target="#mdGallery" data-slide-to="24" onClick={self._scroll_ele()}></li>
                <li id='sld25' data-target="#mdGallery" data-slide-to="25" onClick={self._scroll_ele()}></li>
                */}
              </ol>
              <div className="carousel-inner">
                { 
                  self.props.galleryImages.map(function(thisimage, idxGM){
                    // console.log('draw');
                    // console.log(idxGM === 0 ? 'active':' not active') //+ idxGM === 0 ? 'active':''
                    return <div className={"carousel-item " + (idxGM === 0 ? "active" : "")}>
                               <img className ='img-fluid' src={'../img/gallery/' + thisimage.image} alt='smgalleyimage'></img>
                            </div>
                  })
                 }
                {/*
                <div className="carousel-item active">
                  
                      <img className ='img-fluid' src='../img/gallery/CoreyCo Concrete Tampa.jpg' alt='smgalleyimage'></img>
                  
                </div>               
                <div className="carousel-item">
                  
                      <img className ='img-fluid' src='../img/gallery/Anapharm-QC 2.jpg' alt='smgalleyimage'></img>
                      
                </div> 
                <div className="carousel-item">
                    
                      <img className ='img-fluid' src='../img/gallery/Arturro.jpg' alt='smgalleyimage'></img>
                    
                </div> 
                <div className="carousel-item">
                    
                      <img className ='img-fluid' src='../img/gallery/Black Curb 3_0.jpg' alt='smgalleyimage'></img>
                    
                </div>
                <div className="carousel-item">
                    
                      <img className ='img-fluid' src='../img/gallery/Bloomerstudio_360 State Street_2_small.jpg' alt='smgalleyimage'></img>
                    
                </div>
                <div className="carousel-item">
                    
                      <img className ='img-fluid' src='../img/gallery/Calgary wooden Concrete 2_0.jpeg' alt='smgalleyimage'></img>
                    
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/CH and SR in FL.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/colorhardener.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/driveway-a.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Florida Patio.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/brownmulch.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Block.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/block2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/BPDL_Jeux1.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/BPDL_Jeux2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Customer Pool.jpeg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/DCP_1028.jpeg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/ETS.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/20150903_125250.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/seaport hotel -1745373799_9d77721daa_o.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Tremca_DSC_2096.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/stamped-colored-concrete-driveway-2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/stamped-colored-concrete-walkway.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/colored-concrete-sink.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Nutmeg1.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                <div className="carousel-item">
                    <LazyLoad height={200} offset={100}>
                      <img className ='img-fluid' src='../img/gallery/Nutmeg2.jpg' alt='smgalleyimage'></img>
                    </LazyLoad>
                </div>
                */ }
              </div>
              <a className="carousel-control-prev" href="#mdGallery" role="button" data-slide="prev" onClick={self._scroll_ele()}>
                <span className="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#mdGallery" role="button" data-slide="next" onClick={self._scroll_ele()}>
                <span className="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
              
            </div>
            <hr className='my-0'></hr>
            <div style={{height:'35px'}}></div>
            {/*
            <ol className="carousel-indicators">
                <li id='sld0' data-target="#mdGallery" data-slide-to="0" onClick={_scroll_ele()}></li>
                <li id='sld1' data-target="#mdGallery" data-slide-to="1" onClick={_scroll_ele()}></li>
                <li id='sld2' data-target="#mdGallery" data-slide-to="2" onClick={_scroll_ele()}></li>
                <li id='sld3' data-target="#mdGallery" data-slide-to="3" onClick={_scroll_ele()}></li>
                <li id='sld4' data-target="#mdGallery" data-slide-to="4" onClick={_scroll_ele()}></li>
                <li id='sld5' data-target="#mdGallery" data-slide-to="5" onClick={_scroll_ele()}></li>
                <li id='sld6' data-target="#mdGallery" data-slide-to="6" onClick={_scroll_ele()}></li>
                <li id='sld7' data-target="#mdGallery" data-slide-to="7" onClick={_scroll_ele()}></li>
                <li id='sld8' data-target="#mdGallery" data-slide-to="8" onClick={_scroll_ele()}></li>
                <li id='sld9' data-target="#mdGallery" data-slide-to="9" onClick={_scroll_ele()}></li>
                <li id='sld10' data-target="#mdGallery" data-slide-to="10" onClick={_scroll_ele()}></li>
                <li id='sld11' data-target="#mdGallery" data-slide-to="11" onClick={_scroll_ele()}></li>
                <li id='sld12' data-target="#mdGallery" data-slide-to="12" onClick={_scroll_ele()}></li>
                <li id='sld13' data-target="#mdGallery" data-slide-to="13" onClick={_scroll_ele()}></li>
                <li id='sld14' data-target="#mdGallery" data-slide-to="14" onClick={_scroll_ele()}></li>
                <li id='sld15' data-target="#mdGallery" data-slide-to="15" onClick={_scroll_ele()}></li>
                <li id='sld16' data-target="#mdGallery" data-slide-to="16" onClick={_scroll_ele()}></li>
                <li id='sld17' data-target="#mdGallery" data-slide-to="17" onClick={_scroll_ele()}></li>
                <li id='sld18' data-target="#mdGallery" data-slide-to="18" onClick={_scroll_ele()}></li>
                <li id='sld19' data-target="#mdGallery" data-slide-to="19" onClick={_scroll_ele()}></li>
                <li id='sld20' data-target="#mdGallery" data-slide-to="20" onClick={_scroll_ele()}></li>
                <li id='sld21' data-target="#mdGallery" data-slide-to="21" onClick={_scroll_ele()}></li>
                <li id='sld22' data-target="#mdGallery" data-slide-to="22" onClick={_scroll_ele()}></li>
                <li id='sld23' data-target="#mdGallery" data-slide-to="23" onClick={_scroll_ele()}></li>
    </ol>

            */}

           {/* <ul className="list-group list-group-horizontal filmstrip"> 
                <li id='sld0' data-target="#mdGallery" data-slide-to="0" onClick={_scroll_ele()}>
                  <img className='img-fluid thumbnail' src='../img/gallery/1_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li id='sld1' data-target="#mdGallery" data-slide-to="1" onClick={_scroll_ele()}>
                  <img className='img-fluid thumbnail' src='../img/gallery/2_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li id='sld2' data-target="#mdGallery" data-slide-to="2" onClick={_scroll_ele()}>
                  <img className='img-fluid' src='../img/gallery/3_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li id='sld3' data-target="#mdGallery" data-slide-to="3" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/4_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld4' data-target="#mdGallery" data-slide-to="4" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/5_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld5' data-target="#mdGallery" data-slide-to="5" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/6_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld6' data-target="#mdGallery" data-slide-to="6" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/7_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld7' data-target="#mdGallery" data-slide-to="7" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/8_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld8' data-target="#mdGallery" data-slide-to="8" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/9_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld9' data-target="#mdGallery" data-slide-to="9" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/10_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld10' data-target="#mdGallery" data-slide-to="10" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/11_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld11' data-target="#mdGallery" data-slide-to="11" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/12_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld12' data-target="#mdGallery" data-slide-to="12" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/13_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld13' data-target="#mdGallery" data-slide-to="13" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/14_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld14' data-target="#mdGallery" data-slide-to="14" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/15_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld15' data-target="#mdGallery" data-slide-to="15" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/16_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld16' data-target="#mdGallery" data-slide-to="16" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/17_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld17' data-target="#mdGallery" data-slide-to="17" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/18_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld18' data-target="#mdGallery" data-slide-to="18" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/19_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld19' data-target="#mdGallery" data-slide-to="19" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/20_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld20' data-target="#mdGallery" data-slide-to="20" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/21_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld21' data-target="#mdGallery" data-slide-to="21" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/22_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld22' data-target="#mdGallery" data-slide-to="22" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/23_thumba.jpg' alt='thumb' width="33"/>
                </li>
                <li className="" id='sld23' data-target="#mdGallery" data-slide-to="23" onClick={_scroll_ele()}>
                <img className='img-fluid' src='../img/gallery/24_thumba.jpg' alt='thumb' width="33"/>
                </li>
              </ul> */}
      </div>
    )
    }
  }