import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class LegalPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         marginTop:'-170px'}}>
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Warranty & Legal Notices':'Garantie et Avis juridique'}</h1>
      </div>
      <div className='card bg-white border-0 rounded-0'>
       { this.props.lang === 'EN' ? <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Interstar Limited Warranty</h6>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        All Interstar products are guaranteed, at the time of delivery (i) to meet the product specification, (ii) to conform to the description and standards stipulated on the product packaging and (iii) to produce a uniform, limeproof and sunfast color when the product is mixed, applied and used in accordance with the stipulated directions.
        </p>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        All color charts and sample kits are to be used as a guide only and do not represent the final color that may or may not be obtained.
        </p>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        All other warranties of Interstar are expressly disclaimed, including without limitation, any warranties relating to merchantability, fitness for a particular or intended purpose, quality or performance.
        </p>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        Interstar shall in no event be liable for any indirect, consequential, special, incidental, exemplary or punitive damages or similar damage or losses of any nature whatsoever, including without limitation, for any loss of clientele, sales or profits, regardless of whether arising from breach of contract, warranty, tort, delict, quasi-delict, strict liability or otherwise, even if Interstar is advised of the possibility of such damage or loss or if such damage or loss could have been reasonably foreseen.
        </p>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        In the event of any non-confirming product disclosed to Interstar within 30 days of delivery, then Interstar shall, at its option, either (i) replace any defective product or (ii) refund the price paid for a defective product. Interstar shall not have any liability for claims beyond 30 days. The liability of Interstar shall not, under any circumstances whatsoever exceed the amount of the price paid or payable for the products sold and delivered by Interstar.
        </p>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        The terms and conditions stated in the above limited warranty shall have precedence over any additional or different terms or conditions stipulated in any present or future contract, purchase order, invoice or other documents issued by Interstar. Use of Interstar products constitutes acceptance of the terms and conditions of this limited warranty. Neither performance by Interstar nor receipt of payment shall be deemed or construed as acceptance by Interstar of customer’s additional or different terms and conditions unless otherwise agreed to in a written document signed by an authorized representative of Interstar.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Interstar Legal Notice</h6>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        The following notice constitutes an agreement between Interstar Materials Inc., its affiliates and users of this Internet site in respect to the terms and conditions of usage. This agreement immediately takes effect upon entering the site.
        </p>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        While navigating through this site, the user acknowledges and accepts the rules and conditions of usage as set forth by Interstar Materials Inc.. This user agreement between all parties can only be modified by Interstar Materials Inc. at its sole discretion and without any prior notice. Interstar Materials Inc., recommends that users carefully review the rules and regulations from time to time to ensure that any subsequent changes are acknowledged.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Copyrights</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        All information contained on this web site, or accessible through this web site, is protected by the copyrights of Interstar Materials Inc., or its content suppliers. Users of this web site may only use or reproduce this information for personal or educational purposes but not for commercial use and may not use, redistribute, retransmit, reproduce or publish any information contained herein without prior written consent of the copyright holder. Users must comply with all notices, information and additional restrictions concerning copyrighted material incorporated into the content of this web site, or web sites accessible through this web site, and must abide by all rules and regulations pertaining to said material.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Trademarks</h6>
        <p className='text-left card-text' style={{fontSize:'17px'}}>
        All logos of Interstar Materials Inc., and its affiliates are trademarks belonging to the respective companies and may be registered in Canada and/or in other countries. Other names or logos of products and services referred to on this web site are also trademarks or registered trademarks of Interstar Materials Inc., and its affiliates.
        </p>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        The posting of logos or names of products or services on this web site in no way implies that any license has been granted to anyone relative to these trademarks or registered trademarks. Users of this web site may not use logos or names of products and services contained on this web site without prior written permission of Interstar Materials Inc., or the respective holder of the trademark or registered trademark.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Live links and downloadable software</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
        The live links available on this web site allow users to access external web sites that are not managed or controlled in any way by Interstar Materials, Inc. including the web sites of its affiliates. Interstar Materials, Inc. provides these links solely as a convenience and is in no way responsible for content contained on the sites reached through these links. Interstar Materials, Inc. makes no representations or warranties whatsoever in regard to external web sites accessible through this site. It is incumbent upon users to ensure that any web sites visited are exempt from potentially harmful elements, including viruses. Live links to sites offering downloadable software are also provided solely as a convenience and Interstar Materials, Inc. is not responsible in any way for problems associated with the downloading of software.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Confidentiality</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
          You hereby authorize Interstar Materials, Inc. to collect and retain all relevant information relating to your usage of this site from any party. You additionally authorize all parties to provide Interstar Materials, Inc. with any such information. Furthermore, you understand and agree that, unless notified by email to the contrary, Interstar Materials, Inc. is authorized to disclose, on a confidential basis, all relevant information pertaining to your business dealings with Interstar Materials, Inc. and your use of this web site to any party with whom Interstar Materials, Inc. conducts business.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Online Security</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
          Interstar Materials, Inc. takes all reasonable precautions to protect any confidential information transmitted and ensures the confidentiality of your Internet transactions with Interstar Materials, Inc. We guarantee that any electronic commerce information supplied during Internet transactions with Interstar Materials, Inc. will remain confidential unless prior authorization is obtained from the user.
        </p>
        <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Indemnification</h6>
        <p className='text-left card-text mb-2' style={{fontSize:'17px'}}>
          You agree to indemnify and hold harmless Interstar Materials, Inc. and its affiliates and related companies from any and all liabilities, costs and expenses, including reasonable attorney fees, related to any violation of this agreement by you or the users of your account, or in connection with the use of this site, or the publication or transmission of information or any other materials on this site by you or the users of your account.
        </p>
      </div> : <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Garantie limitée de Interstar</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>
            Tous les produits Interstar sont garantis, au moment de la livraison (i) pour rencontrer les spécifications du produit (ii) pour être conformes à la description et aux normes stipulées sur l'emballage du produit et (iii) pour procurer une couleur uniforme et résistante au soleil et à la chaux lorsque le produit est mélangé, appliqué et utilisé conformément aux indications stipulées.
            </p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>
            Tous les diagrammes de couleurs et les trousses d'échantillons doivent être utilisés à titre indicatif seulement et ne représentent pas la couleur définitive qui pourra ou ne pourra pas être obtenue.
            </p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>
            Toute autre garantie de Interstar est spécifiquement exclue, incluant, sans limitation, toute garantie relativement au caractère commercial, à un usage pour une fin particulière ou projetée, à la qualité ou au rendement du produit.
            </p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>
            En aucun cas, Interstar ne pourra être tenue responsable de tout dommage indirect, conséquent, incident, spécial, exemplaire ou punitif ou dommage ou perte similaire, de quelque nature que ce soit, incluant, sans limitation, la perte de clientèle, de vente ou de profit, et ce, que le dommage découle d'une contravention à un contrat ou à une garantie, d'un délit, d'un quasi-délit, d'une responsabilité stricte ou de toute autre cause, et ce, même si Interstar est avisée de la possibilité d'un tel dommage ou perte ou même si tels dommages ou pertes auraient pu être raisonnablement prévisible.
            </p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>
            En cas de divulgation à Interstar d'une non-conformité du produit dans les 30 jours de sa livraison, Interstar aura alors l'option (i) de remplacer le produit défectueux, ou (ii) de rembourser le prix payé pour le produit défectueux. Interstar ne sera pas tenue responsable de toute réclamation effectuée au-delà de la période de 30 jours. La responsabilité de Interstar ne pourra, en aucune circonstance, excéder le montant du prix payé ou payable pour le produit vendu et livré par Interstar.
            </p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>
            Les conditions stipulées dans la présente garantie limitée auront préséance sur toutes autres conditions additionnelles ou différentes stipulées dans tout contrat, bon de commande, facture ou autre document émanant de Interstar, qu'il soit présent ou futur. L'utilisation des produits de Interstar constitue l'acceptation des conditions de cette garantie limitée. Ni l'exécution en nature par Interstar ni la réception d'un paiement ne sera réputée ou interprétée comme une acceptation par Interstar de conditions additionnelles ou différentes en faveur du client, à moins qu'il n'en soit autrement convenu dans un document écrit et signé par un représentant autorisé de Interstar.
            </p>
            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Avis juridique de Interstar</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Le texte qui suit constitue une entente entre Interstar, ses filiales et l'utilisateur de ce site Internet quant aux modalités et conditions d'utilisation. Cette entente prend effet au moment de l'entrée de l'utilisateur sur le site.</p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>En naviguant sur ce site, l'utilisateur reconnaît et accepte tous les règlements et conditions d'utilisation du site en ligne par Interstar. Cette entente d'utilisation entre les parties ne peut être modifiée que par Interstar, à sa seule discrétion et sans préavis. Interstar suggère à l'usager de relire ces règles et consignes de temps à autre dans l'éventualité où elles auraient changé.</p>
            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Droits d'auteur</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Toute l'information contenue dans le présent site Web ou accessible par l'entremise de celui-ci est protégée par les droits d'auteur de Interstar ou de ses fournisseurs de contenu. Les utilisateurs du présent site Web peuvent uniquement utiliser ou reproduire cette information pour un usage personnel ou éducatif, mais non pour un usage commercial et ils ne peuvent utiliser, redistribuer, retransmettre, reproduire ou publier cette information sans le consentement écrit du détenteur des droits d'auteur. Les utilisateurs du présent site Web doivent se conformer à tout avis, information et restriction supplémentaires concernant les droits d'auteur intégrés à l'information du présent site Web ou accessible par l'intermédiaire de celui-ci, et doivent respecter tous les règles et règlements concernant ledit matériel.</p>
            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Marques de commerce</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Les logos de Interstar et de ses filiales sont des marques de commerce appartenant à ces entreprises, déposées soient au Canada ou dans d'autres pays. Les autres noms ou logos de produits ou services évoqués dans le présent site Web sont des marques de commerce ou des marques déposées de Interstar ou de ses filiales.</p>
            <p className='text-left card-text' style={{fontSize:'17px'}}>L'affichage de logos ou de noms de produits ou services dans le présent site Web n'implique d'aucune façon qu'une licence quelconque a été accordée à quiconque relativement à ces marques de commerce ou à ces marques déposées. Les utilisateurs du présent site Web ne peuvent utiliser les logos ou les noms de produits et services contenus dans le site sans la permission écrite de Interstar ou du détenteur respectif de la marque de commerce ou de la marque déposée.</p>

            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Liens dynamiques et logiciels à télécharger</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Les liens dynamiques disponibles dans le présent site Web vous permettront d'accéder à des sites Web qui ne sont pas gérés ou contrôlés par Interstar, y compris les sites Web de ses filiales. Interstar offre ces liens pour votre commodité uniquement et n'est pas responsable du contenu des sites auxquels ces liens renvoient, et ne fait aucune représentation ou recommandation quelle qu'elle soit quant aux sites Web extérieurs auquel vous pourriez accéder à partir du présent site. Il vous incombe de vous assurer que tout site Web que vous choisissez de consulter est exempt d'éléments potentiellement destructeurs, tels que des virus. Les liens dynamiques vers des sites Web de logiciels à télécharger sont également fournis pour votre commodité uniquement et Interstar n'est pas responsable des difficultés associées au téléchargement de tels logiciels.</p>

            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Information confidentielle</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Vous autorisez Interstar à recueillir toute information relative à votre visite et utilisation sur ce site et de ses partenaires et à conserver cette information. Vous comprenez et convenez que, à moins que vous n'avisiez Interstar du contraire par courrier électronique, vous autorisez Interstar à divulguer sur une base confidentielle à toute partie avec laquelle elle entretient des relations commerciales toute information pertinente relative à vos transactions avec Interstar et à votre utilisation de ce site.</p>

            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Sécurité en ligne</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Interstar prend tous les moyens raisonnables afin de protéger les renseignements confidentiels que vous lui transmettez et en assurer la confidentialité lors de transactions Internet avec Interstar. Nous vous garantissons que l'information ayant trait au commerce électronique lors d'une transaction Internet demeurera confidentielle à moins d'obtenir votre autorisation.</p>

            <h6 className='text-left card-title font-weight-bold mb-2 mt-5' style={{fontSize:'17px'}}>Indemnité</h6>
            <p className='text-left card-text' style={{fontSize:'17px'}}>Vous vous engagez à tenir non responsable Interstar, ses filiales et partenaires de toutes responsabilités, coûts et dépenses, incluant les honoraires d'avocat raisonnables, liés à la violation de la présente entente par vous-même ou par les utilisateurs de votre compte, à l'utilisation de ce site, ou encore à la publication ou à la transmission d'information ou d'éléments sur ce site par vous-même ou par les utilisateurs de votre compte.</p>
          </div> }
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;