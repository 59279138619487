import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminChartsCreate extends React.Component {
  componentDidMount() {
    
  }
  _onSubmit(e) {
    e.preventDefault();
    
    var form = $('#chartcreate')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 600000,
        success: function (data) {
        // $("#result").text(data);
            alert("SUCCESS : " + data);
        //  $("#btnSubmit").prop("disabled", false);
        $('#chartcreate')[0].reset();
        //$('#contactForm')[0].reset();
        },
        error: function (err) {
        // $("#result").text(e.responseText);
            alert("ERROR : " + JSON.stringify(err));
        //  $("#btnSubmit").prop("disabled", false);
        }
    });
}
 render() {
    return (
      <div>
        <form id='chartcreate' onSubmit={this._onSubmit} action='/admin/createchart' enctype="multipart/form-data" method="post">
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control" id="InputID1" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control" id="InputEN" aria-describedby="enHelp" placeholder="Enter English Name"/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control" id="InputFR" aria-describedby="frHelp" placeholder="Enter French Name"/>
            </div>
        </div>
    <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Img</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_en' className="form-control-file"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Img</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_fr' className="form-control-file"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='pdf_en' className="form-control-file"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='pdf_fr' className="form-control-file"/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='display_en' className="custom-control-input" id="visibleEng"/>
            <label className="custom-control-label" htmlFor="visibleEng">English Visible</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='display_fr' className="custom-control-input" id="visibleFr"/>
            <label className="custom-control-label" htmlFor="visibleFr">French Visible</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='disclaimer' className="custom-control-input" id="disclaimer"/>
            <label className="custom-control-label" htmlFor="disclaimer">Disclaimer</label>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control" id="InputSort" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };