import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminConsSkuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          sort: 0,
          name_en: '',
          name_fr: '',
          image: '',
          display_us: 'on',
          display_ca: '',
          display_gl: '',
          sale_ca:'',
          sale_us:'',
          sale_gl:'',
          price_cad: 0,
          price_usd: 0,
          ship_weight: 0
        };
        this._unlockItem = this._unlockItem.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);
        this._updateSort = this._updateSort.bind(this);
        this._updateNameEn = this._updateNameEn.bind(this);
        this._updateNameFr = this._updateNameFr.bind(this);
        this._updateDispCa = this._updateDispCa.bind(this);
        this._updateDispUs = this._updateDispUs.bind(this);
        this._updateDispGl = this._updateDispGl.bind(this);
        this._updateSaleCa = this._updateSaleCa.bind(this);
        this._updateSaleUs = this._updateSaleUs.bind(this);
        this._updateSaleGl = this._updateSaleGl.bind(this);
        this._updatePriceCa = this._updatePriceCa.bind(this);
        this._updatePriceUs = this._updatePriceUs.bind(this);
        this._updateWeight = this._updateWeight.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
      }
    
      componentDidMount() {
      //  this._getChartData();
      }
    
      _unlockItem(){
        this.setState({
          locked:false,
          sort: this.props.sku.order,
          name_en: this.props.sku.name_en,
          name_fr: this.props.sku.name_fr,
          display_us: this.props.sku.show_us,
          display_ca: this.props.sku.show_ca,
          display_gl: this.props.sku.show_gl,
          sale_ca: this.props.sku.sale_ca,
          sale_us:  this.props.sku.sale_us,
          sale_gl: this.props.sku.sale_gl,
          product: this.props.sku.product,
          image: this.props.sku.img,
          price_cad: this.props.sku.price_cad,
          price_usd: this.props.sku.price_usd,
          ship_weight: this.props.sku.ship_weight
        });
      }
      _lockItem(){
        this.setState({locked:true});
      }
      _saveItem(){
        this.setState({locked:true});
      }
      _updateSort(e){
        var str = e.target.value;
        this.setState({sort:str});
      }
      _updateNameEn(e){
        var str = e.target.value;
        this.setState({name_en:str});
      }
      _updateNameFr(e){
        var str = e.target.value;
        this.setState({name_fr:str});
      }
      _updateDispCa(){
       // var str;// = e.target.value;
       // console.log('str en: ' + str);
        if (this.state.display_ca === true) {
          this.setState({display_ca: false});
        } else {
          this.setState({display_ca: true});
        }
      }
      _updateDispUs(){
       // var str = e.target.value;
      //  console.log('str fr: ' + str);
        if (this.state.display_us === true) {
          this.setState({display_us: false});
        } else {
          this.setState({display_us: true});
        }
      }
      _updateDispGl(){
        // var str = e.target.value;
       //  console.log('str fr: ' + str);
         if (this.state.display_gl === true) {
           this.setState({display_gl: false});
         } else {
           this.setState({display_gl: true});
         }
       }
       _updateSaleCa(){
        // var str = e.target.value;
       //  console.log('str fr: ' + str);
         if (this.state.sale_ca === true) {
           this.setState({sale_ca: false});
         } else {
           this.setState({sale_ca: true});
         }
       }
       _updateSaleUs(){
        // var str = e.target.value;
       //  console.log('str fr: ' + str);
         if (this.state.sale_us === true) {
           this.setState({sale_us: false});
         } else {
           this.setState({sale_us: true});
         }
       }
       _updateSaleGl(){
        // var str = e.target.value;
       //  console.log('str fr: ' + str);
         if (this.state.sale_gl === true) {
           this.setState({sale_gl: false});
         } else {
           this.setState({sale_gl: true});
         }
       }
       _updatePriceCa(e){
        var str = e.target.value;
        this.setState({price_cad: str});
       }
       _updatePriceUs(e){
        var str = e.target.value;
        this.setState({price_usd: str});
       }
       _updateWeight(e){
         var str = e.target.value;
         this.setState({ship_weight: str});
       }
       _onSubmit(e) {
        e.preventDefault();
        
        //var self = this;

        var form = $("#cskform" + this.props.sku.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);

                this.setState({locked:true});
            //  $("#btnSubmit").prop("disabled", false);
           // $('#createproproduct')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
        }
  render() {
  //  console.log('Subcatdata: ' + JSON.stringify(this.props.subcategory));
    var self = this;
    return (
/**
 * id:'1',
      name_en:'Pigments',
      name_fr:'Une',
      order: 1,
      show_ca: true,
      show_us: true,
      show_gl: true,
      image: 'banner_pigment_2019-01_3.jpg'
 */

        this.state.locked ? <tr key={this.props.sku.id}>
              <td>{this.props.sku.id}</td>
              <td>{this.props.sku.order}</td>
              <td>{this.props.sku.name_en}</td>
              <td>{this.props.sku.name_fr}</td>
              <td>{this.props.sku.product}</td>
              <td>{this.props.sku.img}</td>
              <td>{this.props.sku.show_ca ? 'true':'false'}</td>
              <td>{this.props.sku.show_us ? 'true':'false'}</td>
              <td>{this.props.sku.show_gl ? 'true':'false'}</td>
              <td>{this.props.sku.sale_ca ? 'true':'false'}</td>
              <td>{this.props.sku.sale_us ? 'true':'false'}</td>
              <td>{this.props.sku.sale_gl ? 'true':'false'}</td>
              <td>{'$' + this.props.sku.price_cad}</td>
              <td>{'$' + this.props.sku.price_usd}</td>
              <td className='d-flex justify-content-between'>
              <button className="btn btn-sm btn-outline-success" onClick={this._unlockItem}><i className='fas fa-lock' style={{color:'green', cursor:'pointer'}}></i></button>
              <button className="btn btn-sm btn-outline-danger" ><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
          </tr> : <tr key={this.props.sku.id}>
            
              <td><input type="text" name="id" form={"cskform" + this.props.sku.id} readonly class="form-control-plaintext form-control-sm text-light" value={this.props.sku.id}/></td>
              <td><input type="number" name="sort" form={"cskform" + this.props.sku.id} className='form-control form-control-sm px-1' value={this.state.sort} onChange={this._updateSort}/></td>
              <td><input type="text" name="name_en" form={"cskform" + this.props.sku.id} className='form-control form-control-sm px-1' value={this.state.name_en} onChange={this._updateNameEn}/></td>
              <td><input type="text" name="name_fr" form={"cskform" + this.props.sku.id} className='form-control form-control-sm px-1' value={this.state.name_fr} onChange={this._updateNameFr}/></td>
              <td>
              <select name='category' form={"cskform" + this.props.sku.id} className="custom-select">
              <option>None</option>
              {
                self.props.products.map(thisprod=> {
                  return <option selected={self.state.product === thisprod.id ? true : false}>{thisprod.id}</option>
                })
              }
              </select>
              </td>
              <td>
              <input type="file" name='image' form={"cskform" + this.props.sku.id} className="form-control-file form-control-sm"/>
              </td>
              <td>
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='show_ca' className="custom-control-input" id="visibleCAecs" form={"cskform" + this.props.sku.id} onClick={this._updateDispCa} checked={this.state.display_ca === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleCAecs">CA</label>
                </div>
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='show_us' className="custom-control-input" id="visibleUSecs" form={"cskform" + this.props.sku.id} onClick={this._updateDispUs} checked={this.state.display_us === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleUSecs">US</label>
              </div>
              
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='show_gl' className="custom-control-input" id="visibleGLecs" form={"cskform" + this.props.sku.id} onClick={this._updateDispGl} checked={this.state.display_gl === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleGLecs">GL</label>
              </div>
              
              </td>
              <td>
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='sale_ca' className="custom-control-input" id="saleCAecs" form={"cskform" + this.props.sku.id} onClick={this._updateSaleCa} checked={this.state.sale_ca === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="saleCAecs">CA</label>
                </div>
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='sale_us' className="custom-control-input" id="saleUSecs" form={"cskform" + this.props.sku.id} onClick={this._updateSaleUs} checked={this.state.sale_us === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="saleUSecs">US</label>
              </div>
              
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='sale_gl' className="custom-control-input" id="saleGLecs" form={"cskform" + this.props.sku.id} onClick={this._updateSaleGl} checked={this.state.sale_gl === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="saleGLecs">GL</label>
              </div>
              
              </td>
              <td>
              <input type='number' name='price_cad' className='form-control form-control-sm' min='0' step='0.01' value={this.state.price_cad} form={"cskform" + this.props.sku.id} onChange={this._updatePriceCa}/>
              </td>
              <td>
              <input type='number' name='price_usd' className='form-control form-control-sm' min='0' step='0.01' value={this.state.price_usd} form={"cskform" + this.props.sku.id} onChange={this._updatePriceUs}/>
              </td> 
              <td>
              <input type='number' name='ship_weight' className='form-control form-control-sm' min='0' step='0.01' value={this.state.ship_weight} form={"cskform" + this.props.sku.id} onChange={this._updateWeight}/>
              </td>
              <td className='d-flex justify-content-between'>
                <button className="btn btn-sm btn-outline-success" onClick={this._lockItem}><i className='fas fa-lock-open' style={{color:'green', cursor:'pointer'}}></i></button>
                <form id={"cskform" + this.props.sku.id} action='/admin/update_conssku' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
                  <button type="submit" className="btn btn-sm btn-outline-success"><i className='fas fa-save' style={{color:'green'}}></i></button>
                </form>
                <button className="btn btn-sm btn-outline-danger"><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
            
          </tr>
    )
    }
  };