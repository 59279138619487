import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class ContactPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div style={{
        'background':"#0a69ba",
        'backgroundPosition':'center center',
        'backgroundRepeat':'no-repeat',
        'backgroundAttachment':'fixed',
        'backgroundSize': 'cover',
          minHeight:'230px',
         marginTop:'-170px'}}>
         <div style={{height: '60px'}}></div>
         <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Contacts':'Contacts'}</h1>
      </div>
      <div className='card bg-white border-0 rounded-0'>
        <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto'>
        {//}  <div className='row'>
        }
         <h5 className='text-left font-weight-bold border-bottom mb-2'>Canada</h5>
          <div className='row'> 
            <div className='col col-md-6 text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Sherbrooke, Quebec</h6>
              <div>4255 Portland Boulevard</div>
              <div>Sherbrooke, Quebec  J1L 3A5</div>
              <div>Canada</div>
              {
                this.props.lang === 'EN' ? <div>Phone: <a href='tel:819-563-9975'>819-563-9975</a> or <a href='tel:800-567-1857'>800-567-1857</a></div>
                : <div>Téléphone: <a href='tel:819-563-9975'>819-563-9975</a> ou <a href='tel:800-567-1857'>800-567-1857</a></div>
              }
              {
                this.props.lang === 'EN' ?<div>Fax: <a href='tel:819-563-1317'>819-563-1317</a> or <a href='tel:888-563-1317'>888-563-1317</a></div> 
                : <div>Télécopieur: <a href='tel:819-563-1317'>819-563-1317</a> ou <a href='tel:888-563-1317'>888-563-1317</a></div>
              }
              
              <div>Email: <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a></div>
            </div>
            <div className='col col-md-6 text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Calgary, Alberta</h6>
              <div>498005 - 122 Street E</div>
              <div>High River, Alberta  T1V 1N1</div>
              <div>Canada</div>
              {
                this.props.lang === 'EN' ? <div>Phone: <a href='tel:403-601-8778'>403-601-8778</a> or <a href='tel:888-568-5521'>888-568-5521</a></div>
                : <div>Téléphone: <a href='tel:403-601-8778'>403-601-8778</a> ou <a href='tel:888-568-5521'>888-568-5521</a></div>
              }
              <div>Email: <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a></div>
            </div>
            </div>
            <h5 className='text-left font-weight-bold border-bottom mb-2 mt-3'>United States</h5>
            <div className='row'> 
            <div className='col col-md-6 text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Junction City, Illinois</h6>
              <div>530 Madison Avenue</div>
              <div>Junction City, Illinois  62882</div>
              <div>United States</div>
              {
                this.props.lang === 'EN' ? <div>Phone: <a href='tel:618-533-3600'>618-533-3600</a> or <a href='tel:877-693-1212'>877-693-1212</a></div>
                : <div>Téléphone: <a href='tel:618-533-3600'>618-533-3600</a> ou <a href='tel:877-693-1212'>877-693-1212</a></div>
              }
              <div>Email: <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a></div>
            </div>            
            </div>
            <h5 className='text-left font-weight-bold border-bottom mb-2 mt-3'>Mexico</h5>
            <div className='row'> 
            <div className='col col-md-6 text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Mexico City, D.F.</h6>
              <div>Shakespeare 84-8</div>
              <div>Col. Anzures</div>
              <div>CP11590, México, DF</div>
              {
                this.props.lang === 'EN' ? <div>Phone: <a href='tel:52-55/5254-6157'>52-55/5254-6157</a></div>
                : <div>Téléphone: <a href='tel:52-55/5254-6157'>52-55/5254-6157</a></div>
              }
            </div>
            </div>
            <h5 className='text-left font-weight-bold border-bottom mb-2 mt-3'>South Korea</h5>
            <div className='row'> 
            <div className='col col-md-6 text-left'>
              <h6 className='card-title font-weight-bold mb-1'>Seoul</h6>
              <div>Room #3304, Korea World Trade Cente</div>
              <div>159 Samsung-Dong, Kangnam-Ku</div>
              <div>Seoul, 135-729, South Korea</div>
              {
                this.props.lang === 'EN' ? <div>Phone: <a href='tel:82.2.551.2716'>82.2.551.2716</a></div>
                : <div>Téléphone: <a href='tel:82.2.551.2716'>82.2.551.2716</a></div>
              }
            </div>
            </div>
            {/*
            <div className="col-sm-4 col-xl-3">
              <div className="nav flex-column nav-pills text-left" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <h5 className='text-left border-bottom mb-0'>Canada</h5>
                <a className="ml-2 mt-0 nav-link pill-style active" id="v-pills-qc-tab" data-toggle="pill" href="#v-pills-qc" role="tab" aria-controls="v-pills-qc" aria-selected="true">Sherbrooke, Quebec</a>
                <a className="ml-2 nav-link pill-style" id="v-pills-ab-tab" data-toggle="pill" href="#v-pills-ab" role="tab" aria-controls="v-pills-ab" aria-selected="false">Calgary, Alberta</a>
                <h5 className='text-left border-bottom mb-0'>United States</h5>
                <a className="ml-2 mt-0 nav-link pill-style" id="v-pills-il-tab" data-toggle="pill" href="#v-pills-il" role="tab" aria-controls="v-pills-il" aria-selected="false">Junction City, Illinois</a>
                <h5 className='text-left border-bottom mb-0'>Mexico</h5>
                <a className="ml-2 mt-0 nav-link pill-style" id="v-pills-mx-tab" data-toggle="pill" href="#v-pills-mx" role="tab" aria-controls="v-pills-mx" aria-selected="false">Mexico City, Mexico</a>
                <h5 className='text-left border-bottom mb-0'>South Korea</h5>
                <a className="ml-2 mt-0 nav-link pill-style" id="v-pills-mx-tab" data-toggle="pill" href="#v-pills-sk" role="tab" aria-controls="v-pills-sk" aria-selected="false">Seoul, South Korea</a>
                
              </div>
            </div>
            <div className="col-sm-8 col-xl-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-pills-qc" role="tabpanel" aria-labelledby="v-pills-qc-tab">
                <div className='row'>
                  <div className='col-xl-5 text-left'>
                    <div>4255 Portland Boulevard</div>
                    <div>Sherbrook, Quebec  J1L 3A5</div>
                    <div>Canada</div>
                    <div>Phone: <a href='tel:819-563-9975'>819-563-9975</a> or <a href='tel:800-567-1857'>800-567-1875</a></div>
                    <div>Fax: <a href='tel:819-563-1317'>819-563-1317</a> or <a href='tel:800-563-1317'>800-563-1367</a></div>
                    <div>Email: <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a></div>
                  </div>
                  <div className='col-xl-7'>
                  <iframe title='sherbrookeoffice' style={{border:'0px'}}src="https://www.google.com/maps/d/embed?mid=1-fEVBQkDaFkL1p1z1xJmM2CssW_zZaLm" width="500" height="400" allowFullScreen></iframe>
                  </div>
                </div>
                </div>
                <div className="tab-pane fade" id="v-pills-ab" role="tabpanel" aria-labelledby="v-pills-ab-tab">
                <div className='row'>
                  <div className='col-xl-5 text-left'>
                    <div>498005 - 122 Street E</div>
                    <div>High River, Alberta  T1V 1N1</div>
                    <div>Canada</div>
                    <div>Phone: <a href='tel:888-568-5521'>888-568-5521</a></div>
                    <div>Fax: <a href='tel:403-601-8779'>403-601-8779</a> or <a href='tel:800-563-1317'>800-563-1367</a></div>
                    <div>Email: <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a></div>
                  </div>
                  <div className='col-xl-7'>
                  <iframe title='calgaryoffice' style={{border:'0px'}}src="https://www.google.com/maps/d/embed?mid=1v9GGvy-NHiQfes8evkGbFK6dELK8T9OM" width="500" height="400" allowFullScreen></iframe>
                  </div>
                </div>
                </div>
                <div className="tab-pane fade" id="v-pills-il" role="tabpanel" aria-labelledby="v-pills-il-tab">
                <div className='row'>
                  <div className='col-xl-5 text-left'>
                    <div>530 Madison Avenue</div>
                    <div>Junction City, Illinois  62882</div>
                    <div>United States</div>
                    <div>Phone: <a href='tel:618-533-3600'>618-533-3600</a> or <a href='tel:877-693-1212'>877-693-1212</a></div>
                    <div>Fax: <a href='tel:618-533-3636'>618-533-3636</a> or <a href='tel:888-563-1317'>888-563-1317</a></div>
                    <div>Email: <a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a></div>
                  </div>
                  <div className='col-xl-7'>
                  <iframe title='junctioncityoffice' style={{border:'0px'}}src="https://www.google.com/maps/d/embed?mid=1WuKbc6Ml_xIuAGQ5vPJQHzPo63FYb2mS" width="500" height="400" allowFullScreen></iframe>
                  </div>
                </div>
                </div>
                <div className="tab-pane fade" id="v-pills-mx" role="tabpanel" aria-labelledby="v-pills-mx-tab">
                <div className='row'>
                  <div className='col-xl-5 text-left'>
                    <div>Shakespeare 84-8</div>
                    <div>Col. Anzures</div>
                    <div>CP11590, México, DF</div>
                    <div>Phone: <a href='tel:52-55/5254-6157'>52-55/5254-6157</a></div>
                    <div>Fax: <a href='tel:52-55/5545-2005'>52-55/5545-2005</a></div>
                    <div>Email: <a href='mailto:urimiller@interstar.ca'>urimiller@interstar.ca</a></div>
                  </div>
                  <div className='col-xl-7'>
                  <iframe title='mexicooffice' style={{border:'0px'}}src="https://www.google.com/maps/d/embed?mid=1R46vDE_N-9WsEVqfx_svIV3ML-8AGjmC" width="500" height="400" allowFullScreen></iframe>
                  </div>
                </div>
                </div>
                <div className="tab-pane fade" id="v-pills-sk" role="tabpanel" aria-labelledby="v-pills-sk-tab">
                <div className='row'>
                  <div className='col-xl-5 text-left'>
                    <div>Room #3304, Korea World Trade Cente</div>
                    <div>159 Samsung-Dong, Kangnam-Ku</div>
                    <div>Seoul, 135-729, South Korea</div>
                    <div>Phone: <a href='tel:82.2.551.2716'>82.2.551.2716</a></div>
                  </div>
                  <div className='col-xl-7'>
                  <iframe title='Seouloffice' style={{border:'0px'}}src="https://www.google.com/maps/d/embed?mid=1Uyxd6qTW2MkRJJUHN7B4_mIN4psMrMsM" width="500" height="400" allowFullScreen></iframe>
                  </div>
                </div>
                </div>
              </div>
            </div>
          */}
        {//  </div>
        }
        </div>
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;