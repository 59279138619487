
const downloads = [
   /* {
      'id': 'Easystar',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Easystar Dispenser Brochure',
      'name_fr': 'Brochure Easystar',
      'image_en': 'EasySTAR dispenser_FR_web 2019.jpg',
      'image_fr': 'EasySTAR dispenser_FR_web 2019.jpg',
      'pdf_en': 'EasySTAR dispenser_EN_web 2019.pdf',
      'pdf_fr': 'EasySTAR dispenser_FR_web 2019.pdf'
    },
    {
      'id': 'Megastar',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Megastar Dispenser Brochure',
      'name_fr': 'Brochure Megastar',
      'image_en': 'MegaSTAR dispenser_EN_web 2019.jpg',
      'image_fr': 'MegaSTAR dispenser_FR_web 2019.jpg',
      'pdf_en': 'MegaSTAR dispenser_EN_web 2019.pdf',
      'pdf_fr': 'MegaSTAR dispenser_FR_web 2019.pdf'
    },
    {
      'id': 'Granastar',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Granastar Dispenser Brochure',
      'name_fr': 'Brochure Megastar',
      'image_en': 'GranaSTAR dispenser_EN_web 2019_3.jpg',
      'image_fr': 'GranaSTAR dispenser_FR_web 2019_3.jpg',
      'pdf_en': 'GranaSTAR dispenser_EN_web 2019.pdf',
      'pdf_fr': 'GranaSTAR dispenser_FR_web 2019.pdf'
    },
    {
      'id': 'Sealer',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Sealer Comparison Chart',
      'name_fr': 'Comparaison Scellants',
      'image_en': 'Concret Sealers_EN_web 2019.jpg',
      'image_fr': 'Concret Sealers_FR_web 2019.jpg',
      'pdf_en': 'Concrete Sealers_EN_web 2019_3.pdf',
      'pdf_fr': 'Concrete Sealers_FR_web 2019.pdf'
    },
    {
      'id': 'MulchDisp',
      'display_en':true,
      'display_fr':false,
      'name_en': 'Mulch Dispenser Brochure',
      'name_fr': 'Brochure Megastar',
      'image_en': 'Mulch Dispenser_EN_web 2019.jpg',
      'image_fr': 'Mulch Dispenser_EN_web 2019.jpg',
      'pdf_en': 'Mulch Dispenser_EN_web 2019_0.pdf',
      'pdf_fr': 'Mulch Dispenser_EN_web 2019_0.pdf'
    },
    {
      'id': 'Granular Pigment',
      'display_en':true,
      'display_fr':false,
      'name_en': 'Granastar® Granular Pigment',
      'name_fr': 'Granastar® Granular Pigment',
      'image_en': 'Granastar Granular Pigment_EN_web 2019.jpg',
      'image_fr': 'Granastar Granular Pigment_EN_web 2019.jpg',
      'pdf_en': 'Granastar Granular Pigment_EN_web 2019.pdf',
      'pdf_fr': 'Granastar Granular Pigment_EN_web 2019.pdf'
    },
    {
      'id': 'GranMulch',
      'display_en':true,
      'display_fr':false,
      'name_en': 'Granastar® Granular Mulch Color vs. Liquid Mulch Color',
      'name_fr': 'Granastar® Granular Mulch Color vs. Liquid Mulch Color',
      'image_en': 'Granastar_Liquid_comparison_EN_web 2019.jpg',
      'image_fr': 'Granastar_Liquid_comparison_EN_web 2019.jpg',
      'pdf_en': 'MegaSTAR dispenser_EN_web 2019.pdf',
      'pdf_fr': 'MegaSTAR dispenser_FR_web 2019.pdf'
    },
    {
      'id': 'Expose',
      'display_en':true,
      'display_fr':false,
      'name_en': 'EXPOSÉ Surface Retarder',
      'name_fr': 'EXPOSÉ Surface Retarder',
      'image_en': 'Expose_EN_web 2019.jpg',
      'image_fr': 'Expose_EN_web 2019.jpg',
      'pdf_en': 'Expose_EN_web 2019_0.pdf',
      'pdf_fr': 'Expose_EN_web 2019_0.pdf'
    },
    {
      'id': 'Factors',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Factors Affecting The Color of Concrete',
      'name_fr': 'Facteurs influençant la couleur du béton',
      'image_en': 'Factors Affecting Color_EN_web 2019.jpg',
      'image_fr': 'Factors Affecting Color_FR_web 2019.jpg',
      'pdf_en': 'Factors Affecting Color_EN_web 2019.pdf',
      'pdf_fr': 'Factors Affecting Color_FR_web 2019.pdf'
    },
    {
      'id': 'CareMaint',
      'display_en':true,
      'display_fr':true,
      'name_en': 'Care & Maintenance Products',
      'name_fr': "Produits de nettoyage et d'entretien",
      'image_en': 'Care and Maintenance_EN_web 2019.jpg',
      'image_fr': 'Care and Maintenance_FR_web 2019.jpg',
      'pdf_en': 'Care and Maintenance_EN_web 2019.pdf',
      'pdf_fr': 'Care and Maintenance_FR_web 2019.pdf'
    },
    {
      'id': 'BestPractice',
      'display_en':true,
      'display_fr':false,
      'name_en': 'Best Pratices for Colored Concrete',
      'name_fr': 'Brochure Megastar',
      'image_en': 'Best Practices_EN_web 2019.jpg',
      'image_fr': 'Best Practices_EN_web 2019.jpg',
      'pdf_en': 'Best Practices_EN_web 2019.pdf',
      'pdf_fr': 'Best Practices_EN_web 2019.pdf'
    },
    {
      'id': 'SL-20',
      'display_en':true,
      'display_fr':false,
      'name_en': 'SL-20 Sealer',
      'name_fr': 'SL-20 Sealer',
      'image_en': 'SL20_EN_web 2019.jpg',
      'image_fr': 'SL20_EN_web 2019.jpg',
      'pdf_en': 'SL20 PI Sheet Nov 2018 - rmv high gloss.pdf',
      'pdf_fr': 'SL20 PI Sheet Nov 2018 - rmv high gloss.pdf'
    },
    {
      'id': 'InterstarSol',
      'display_en':true,
      'display_fr':false,
      'name_en': 'Interstar Solutions',
      'name_fr': 'Interstar Solutions',
      'image_en': 'Interstar Solution_EN_web 2019.jpg',
      'image_fr': 'Interstar Solution_EN_web 2019.jpg',
      'pdf_en': 'Interstar Solution_EN_web 2019.pdf',
      'pdf_fr': 'Interstar Solution_EN_web 2019.pdf'
    },
    {
      'id': 'eviter',
      'display_en':false,
      'display_fr':true,
      'name_en': 'À faire et à éviter',
      'name_fr': 'À faire et à éviter',
      'image_en': 'Faire_Eviter_FR_web 2019_4.jpg',
      'image_fr': 'Faire_Eviter_FR_web 2019_4.jpg',
      'pdf_en': 'Faire_Eviter_FR_web 2019_3.pdf',
      'pdf_fr': 'Faire_Eviter_FR_web 2019_3.pdf'
    }, */
  ];
  
  export default downloads;