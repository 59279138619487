import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminProProductEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          categories: [{id:'test1'},{id:'test2'}], //Cookies.get('language') || 'EN'
          cat_sel: '',

          /**
           *  
              memberof: fields.group === 'subcat' ? fields.subcat : fields.category,
           */
          filter:'',
          prod_sel: '',

          prod_id:'',
          prod_name_en: '',
          prod_name_fr: '',
          prod_desc_en: [''],
          prod_desc_fr: [''],
          prod_membertype: '',
          prod_memberof: '',
          prod_show_en: false,
          prod_show_fr: false,
          prod_show_ca: false,
          prod_show_us: false,
          prod_show_gl: false,
          prod_show_colorchart: false,
          prod_show_brochure: false,
          prod_show_instructions: false,
          prod_show_productinfo: false,
          prod_show_overview: false,
          prod_show_colorchartfr: false,
          prod_show_brochurefr: false,
          prod_show_instructionsfr: false,
          prod_show_productinfofr: false,
          prod_show_overviewfr: false,
          prod_order: 0,
          prod_chart_en: '',
          prod_chart_fr: '',
          prod_brochure_en: '',
          prod_brochure_fr: '',
          prod_instructions_en: '',
          prod_instructions_fr: '',
          prod_productinfo_en: '',
          prod_productinfo_fr: '',
          prod_overview_en: '',
          prod_overview_fr: ''

        };
        this._getProCats = this._getProCats.bind(this);
        this._onClickSel = this._onClickSel.bind(this);
        this._onChangeNameEn = this._onChangeNameEn.bind(this);
        this._onChangeNameFr = this._onChangeNameFr.bind(this);
        this._onChangeDescEn = this._onChangeDescEn.bind(this);
        this._onChangeDescFr = this._onChangeDescFr.bind(this);
        this._setmembertype = this._setmembertype.bind(this);
        this._ChangeOrder = this._ChangeOrder.bind(this);
        this._toggle_show_en = this._toggle_show_en.bind(this);
        this._toggle_show_fr = this._toggle_show_fr.bind(this);
        this._toggle_show_ca = this._toggle_show_ca.bind(this);
        this._toggle_show_us = this._toggle_show_us.bind(this);
        this._toggle_show_gl = this._toggle_show_gl.bind(this);
        this._toggle_show_colorchart = this._toggle_show_colorchart.bind(this);
        this._toggle_show_brochure = this._toggle_show_brochure.bind(this);
        this._toggle_show_instructions = this._toggle_show_instructions.bind(this);
        this._toggle_show_productinfo = this._toggle_show_productinfo.bind(this);
        this._toggle_show_overview = this._toggle_show_overview.bind(this);
        this._toggle_show_colorchartfr = this._toggle_show_colorchartfr.bind(this);
        this._toggle_show_brochurefr = this._toggle_show_brochurefr.bind(this);
        this._toggle_show_instructionsfr = this._toggle_show_instructionsfr.bind(this);
        this._toggle_show_productinfofr = this._toggle_show_productinfofr.bind(this);
        this._toggle_show_overviewfr = this._toggle_show_overviewfr.bind(this);
        this._changeFilter = this._changeFilter.bind(this);
      }
      _changeFilter(e){
        let str = e.target.value;
        this.setState({filter:str});
      }
      componentDidMount() {
        this._getProCats();
      }
    
      _getProCats(){
     /*   var self = this;
        console.log('Pro Category List');
        $.get('/data/procats',function(data,status,xhr){
          console.log('status: ' + status);
          if (data.length > 0) {
            self.setState({categories: data});
          }
        },'json'); */
      }

      _onClickSel(product){
        var self = this;
        return function(){
         // console.log('On Click Product: ' + product);
          var selIdx = document.getElementById("prod_select").selectedIndex;
          var selValue = document.getElementById("prod_select").value;
         // console.log('On Click ProductIdx: ' + selIdx);
         
         //console.log('****************selValue: ' + selValue);
         let pos = 0;

         if ((selIdx !== undefined) && ( selIdx > 0 )){       
            if (self.state.filter.length > 0) {
              pos = self.props.products.map(function(e) { return e.id; }).indexOf(selValue); 
             // console.log("*****************************POS: " + pos);
            } else {
              pos = selIdx - 1;
            }

            self.setState({
              prod_id: self.props.products[pos].id,
              prod_name_en: self.props.products[pos].name_en,
              prod_name_fr: self.props.products[pos].name_fr,
              prod_desc_en: self.props.products[pos].desc_en[0],
              prod_desc_fr: self.props.products[pos].desc_fr[0],
              prod_membertype: self.props.products[pos].membertype,
              prod_memberof: self.props.products[pos].memberof,
              prod_show_en: self.props.products[pos].show_en,
              prod_show_fr: self.props.products[pos].show_fr,
              prod_show_ca: self.props.products[pos].show_ca,
              prod_show_us: self.props.products[pos].show_us,
              prod_show_gl: self.props.products[pos].show_gl,
              prod_show_colorchart: self.props.products[pos].show_colorchart,
              prod_show_brochure: self.props.products[pos].show_brochure,
              prod_show_instructions: self.props.products[pos].show_instructions,
              prod_show_productinfo: self.props.products[pos].show_productinfo,
              prod_show_overview: self.props.products[pos].show_overview,
              prod_show_colorchartfr: self.props.products[pos].show_colorchartfr,
              prod_show_brochurefr: self.props.products[pos].show_brochurefr,
              prod_show_instructionsfr: self.props.products[pos].show_instructionsfr,
              prod_show_productinfofr: self.props.products[pos].show_productinfofr,
              prod_show_overviewfr: self.props.products[pos].show_overviewfr,
              prod_order: self.props.products[pos].order,
              prod_chart_en: self.props.products[pos].chart_en,
              prod_chart_fr: self.props.products[pos].chart_fr,
              prod_brochure_en: self.props.products[pos].brochure_en,
              prod_brochure_fr: self.props.products[pos].brochure_fr,
              prod_instructions_en: self.props.products[pos].instructions_en,
              prod_instructions_fr: self.props.products[pos].instructions_fr,
              prod_productinfo_en: self.props.products[pos].productinfo_en,
              prod_productinfo_fr: self.props.products[pos].productinfo_fr,
              prod_overview_en: self.props.products[pos].overview_en,
              prod_overview_fr: self.props.products[pos].overview_fr
            });
          }
          
        }
      }
      _ChangeOrder(e){
        var str = e.target.value;
        this.setState({prod_order: str});
      }

      _onChangeNameEn(e){
        var str = e.target.value;
        this.setState({prod_name_en: str});
      }

      _onChangeNameFr(e){
        var str = e.target.value;
        this.setState({prod_name_fr: str});
      }

      _onChangeDescEn(e){
        var str = e.target.value;
        this.setState({prod_desc_en: str});
      }

      _onChangeDescFr(e){
        var str = e.target.value;
        this.setState({prod_desc_fr: str});
      }

      _setmembertype(type) {
        var self = this;
        return function(){
          console.log('Set membertype: ' + type);
          self.setState({prod_membertype: type});
        }
      }
      _toggle_show_en(){
        let newstate = !this.state.prod_show_en;
        this.setState({prod_show_en: newstate});
      }
      _toggle_show_fr(){
        let newstate = !this.state.prod_show_fr;
        this.setState({prod_show_fr: newstate});
      }
      _toggle_show_ca(){
        let newstate = !this.state.prod_show_ca;
        this.setState({prod_show_ca: newstate});
      }
      _toggle_show_us(){
        let newstate = !this.state.prod_show_us;
        this.setState({prod_show_us: newstate});
      }
      _toggle_show_gl(){
        let newstate = !this.state.prod_show_gl;
        this.setState({prod_show_gl: newstate});
      }
      _toggle_show_colorchart(){
        let newstate = !this.state.prod_show_colorchart;
        this.setState({prod_show_colorchart: newstate});
      }
      _toggle_show_brochure(){
        let newstate = !this.state.prod_show_brochure;
        this.setState({prod_show_brochure: newstate});
      }
      _toggle_show_instructions(){
        let newstate = !this.state.prod_show_instructions;
        this.setState({prod_show_instructions: newstate});
      }
      _toggle_show_productinfo(){
        let newstate = !this.state.prod_show_productinfo;
        this.setState({prod_show_productinfo: newstate});
      }
      _toggle_show_overview(){
        let newstate = !this.state.prod_show_overview;
        this.setState({prod_show_overview: newstate});
      }
      _toggle_show_colorchartfr(){
        let newstate = !this.state.prod_show_colorchartfr;
        this.setState({prod_show_colorchartfr: newstate});
      }
      _toggle_show_brochurefr(){
        let newstate = !this.state.prod_show_brochurefr;
        this.setState({prod_show_brochurefr: newstate});
      }
      _toggle_show_instructionsfr(){
        let newstate = !this.state.prod_show_instructionsfr;
        this.setState({prod_show_instructionsfr: newstate});
      }
      _toggle_show_productinfofr(){
        let newstate = !this.state.prod_show_productinfofr;
        this.setState({prod_show_productinfofr: newstate});
      }
      _toggle_show_overviewfr(){
        let newstate = !this.state.prod_show_overviewfr;
        this.setState({prod_show_overviewfr: newstate});
      }
      _onSubmit(e) {
        e.preventDefault();
        
        var form = $('#editproproduct')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#editproproduct')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
      }
      _onSubmitEnChart(e) {
        e.preventDefault();
        var form = $('#editproproduct_en_chart')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrChart(e) {
        e.preventDefault();
        var form = $('#editproproduct_fr_chart')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitEnBroc(e) {
        e.preventDefault();
        var form = $('#editproproduct_en_broc')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrBroc(e) {
        e.preventDefault();
        var form = $('#editproproduct_fr_broc')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

      _onSubmitEnInst(e) {
        e.preventDefault();
        var form = $('#editproproduct_en_inst')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrInst(e) {
        e.preventDefault();
        var form = $('#editproproduct_fr_inst')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

      _onSubmitEnInfo(e) {
        e.preventDefault();
        var form = $('#editproproduct_en_info')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitFrInfo(e) {
        e.preventDefault();
        var form = $('#editproproduct_fr_info')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      
      _onSubmitEnOver(e) {
        e.preventDefault();
        var form = $('#editproproduct_en_over')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }

      _onSubmitFrOver(e) {
        e.preventDefault();
        var form = $('#editproproduct_fr_over')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }


  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Professional Product Edit</h5>
          <div className="input-group mb-2 col-lg-5">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">Filter</span>
          </div>
          <input type="text" className="form-control" placeholder="Filter By ID" aria-label="Username" aria-describedby="basic-addon1" onChange={this._changeFilter} value={this.state.filter}/>
        </div>
          <div className="form-group row">
          <label className='col-sm-2 col-form-label font-weight-bold'>Select</label>
          <div className='col-sm-10 col-lg-6'>
          <select id='prod_select' name='product' className="col-sm-10 col-lg-6 form-control form-control-sm" onClick={self._onClickSel(this.value)}>
            <option>None</option>
            {
              self.props.products.map(thisprod=>{
                if (self.state.filter.length > 0) {
                  let lowfilter = self.state.filter.toLowerCase();
                  let lowID = thisprod.id.substring(0, lowfilter.length).toLowerCase();
                  if (lowID === lowfilter) {
                    return <option>{thisprod.id}</option>
                  } else {
                    return ''
                  }
                } else {
                  return <option>{thisprod.id}</option>
                }
              })
            }
          </select>
          </div>
          </div>
        <form id='editproproduct' action='/admin/update_proproduct' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pe" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameEn} value={self.state.prod_name_en} name='name_en' type="text" className="form-control form-control-sm" id="InputENpe" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameFr} value={self.state.prod_name_fr} name='name_fr' type="text" className="form-control form-control-sm" id="InputFRpe" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescEn} value={self.state.prod_desc_en} name='desc_en' className="form-control form-control-sm" id="InputDescENe"  placeholder="Enter English Description" rows="3" required></textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescFr} value={self.state.prod_desc_fr} name='desc_fr' className="form-control form-control-sm" id="InputDescFRe"  placeholder="Enter French Description" rows="3" required></textarea>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('category')} className="form-check-input" type="radio" name="group" id="exampleRadios1e" value="category" checked={this.state.prod_membertype === 'category' ? true : ''}/> {/**={this.state.prod_membertype === 'category' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios1e">
            Category
          </label>
          
        </div>
        <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option selected={thiscat.id === self.state.prod_memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('subcat')} className="form-check-input" type="radio" name="group" id="exampleRadios2e" value="subcat" checked={this.state.prod_membertype === 'subcat' ? true : ''}/> {/**checked={this.state.prod_membertype === 'subcat' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios2e">
            Sub-Category
          </label>
          
        </div>
        <select name='subcat' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.subcategories.map(thiscat=>{
                return <option selected={thiscat.id === self.state.prod_memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_en} type="checkbox" name='show_en' className="custom-control-input" id="visiblepENGe" checked={this.state.prod_show_en === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepENGe">Show Eng</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_fr} type="checkbox" name='show_en' className="custom-control-input" id="visiblepFRNe" checked={this.state.prod_show_fr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepFRNe">Show Frn</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_ca} type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANe" checked={this.state.prod_show_ca === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepCANe">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_us} type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSe" checked={this.state.prod_show_us === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepUSe">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_gl} type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLe" checked={this.state.prod_show_gl === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepGLe">Show Global</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_colorchart} type="checkbox" name='show_chart' className="custom-control-input" id="show_charte" checked={this.state.prod_show_colorchart === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_charte">Show Chart</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_brochure} type="checkbox" name='show_broc' className="custom-control-input" id="show_broce" checked={this.state.prod_show_brochure === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_broce">Show Brochure</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_instructions} type="checkbox" name='show_inst' className="custom-control-input" id="show_inste" checked={this.state.prod_show_instructions === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_inste">Show Instructions</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_productinfo} type="checkbox" name='show_info' className="custom-control-input" id="show_infoe" checked={this.state.prod_show_productinfo === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_infoe">Show Info</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_overview} type="checkbox" name='show_over' className="custom-control-input" id="show_overe" checked={this.state.prod_show_overview === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_overe">Show Overview</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_colorchartfr} type="checkbox" name='show_chartfr' className="custom-control-input" id="show_chartefr" checked={this.state.prod_show_colorchartfr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_chartefr">Show Fr.Chart</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_brochurefr} type="checkbox" name='show_brocfr' className="custom-control-input" id="show_brocefr" checked={this.state.prod_show_brochurefr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_brocefr">Show Fr.Brochure</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_instructionsfr} type="checkbox" name='show_instfr' className="custom-control-input" id="show_instefr" checked={this.state.prod_show_instructionsfr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_instefr">Show Fr.Instructions</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_productinfofr} type="checkbox" name='show_infofr' className="custom-control-input" id="show_infoefr" checked={this.state.prod_show_productinfofr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_infoefr">Show Fr.Info</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_overviewfr} type="checkbox" name='show_overfr' className="custom-control-input" id="show_overefr" checked={this.state.prod_show_overviewfr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="show_overefr">Show Fr.Overview</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={this._ChangeOrder}type="number" name='sort' className="form-control form-control-sm" id="InputID2e" aria-describedby="idHelp" placeholder='0' value={this.state.prod_order}/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit Updates</button>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_en_chart' action='/admin/update_proproduct_en_chart' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnChart}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pec" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='chart_en' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_fr_chart' action='/admin/update_proproduct_fr_chart' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrChart}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefc" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Chart</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='chart_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_en_broc' action='/admin/update_proproduct_en_broc' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnBroc}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peb" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Brochure</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='brochure_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_fr_broc' action='/admin/update_proproduct_fr_broc' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrBroc}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefb" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Brochure</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='brochure_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_en_inst' action='/admin/update_proproduct_en_inst' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnInst}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peei" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Instructions</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='instructions_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_fr_inst' action='/admin/update_proproduct_fr_inst' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrInst}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefi" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Instructions</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='instructions_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_en_info' action='/admin/update_proproduct_en_info' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnInfo}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peep" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Product Info</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='productinfo_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_fr_info' action='/admin/update_proproduct_fr_info' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrInfo}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefp" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Product Info</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='productinfo_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_en_over' action='/admin/update_proproduct_en_over' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnOver}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peeo" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Overview</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='overview_en' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editproproduct_fr_over' action='/admin/update_proproduct_fr_over' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrOver}>
        <div className="form-group row">
        <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pefo" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Overview</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='overview_fr' className="form-control-file form-control-sm"/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
        </div>
        <hr className='my-1'></hr>
       {// <button type="submit" className="btn btn-primary">Submit</button>
        }
        </form>
      </div>
    )
    }
  };