import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminConsProductCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          categories: [{id:'test1'},{id:'test2'}], //Cookies.get('language') || 'EN'
          cat_sel: ''
        };
        this._getProCats = this._getProCats.bind(this);
        this._onClickSel = this._onClickSel.bind(this);
      }
    
      componentDidMount() {
        this._getProCats();
      }
    
      _getProCats(){
     /*   var self = this;
        console.log('Pro Category List');
        $.get('/data/procats',function(data,status,xhr){
          console.log('status: ' + status);
          if (data.length > 0) {
            self.setState({categories: data});
          }
        },'json'); */
      }

      _onClickSel(catname){
        var self = this;
        return function(){
          if (catname === 'none') {
            self.setState({cat_sel : ''});
          } else {
            self.setState({cat_sel : catname});
          }
        }
      }

      _onSubmit(e) {
        e.preventDefault();
        
        var form = $('#createconsproduct')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#createconsproduct')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
        }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Consumer Product</h5>
        <form id='createconsproduct' action='/admin/createconsproduct' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pc" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control form-control-sm" id="InputENpc" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control form-control-sm" id="InputFRpc" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea name='desc_en' className="form-control form-control-sm" id="InputDescENc"  placeholder="Enter English Description" rows="3" required></textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea name='desc_fr' className="form-control form-control-sm" id="InputDescFRc"  placeholder="Enter French Description" rows="3" required></textarea>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input className="form-check-input" type="radio" name="group" id="exampleRadios1c" value="category" checked/>
          <label className="form-check-label font-weight-bold" for="exampleRadios1c">
            Category
          </label>
          
        </div>
        <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input className="form-check-input" type="radio" name="group" id="exampleRadios2c" value="subcat"/>
          <label className="form-check-label font-weight-bold" for="exampleRadios2c">
            Sub-Category
          </label>
          
        </div>
        <select name='subcat' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.subcategories.map(thiscat=>{
                return <option>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_en' className="custom-control-input" id="visiblepENc"/>
            <label className="custom-control-label" htmlFor="visiblepENc">Show Eng</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_fr' className="custom-control-input" id="visiblepFRc"/>
            <label className="custom-control-label" htmlFor="visiblepFRc">Show Frn</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANc"/>
            <label className="custom-control-label" htmlFor="visiblepCANc">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSc"/>
            <label className="custom-control-label" htmlFor="visiblepUSc">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLc"/>
            <label className="custom-control-label" htmlFor="visiblepGLc">Show Global</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='freeze' className="custom-control-input" id="freezeD"/>
            <label className="custom-control-label" htmlFor="freezeD">Freeze Danger</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='hazard' className="custom-control-input" id="hazardD"/>
            <label className="custom-control-label" htmlFor="hazardD">Hazardous</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='eco' className="custom-control-input" id="EcoD"/>
            <label className="custom-control-label" htmlFor="EcoD">Eco Friendly</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control form-control-sm" id="InputID2c" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <div className="col-lg-2 ml-3 mr-2 custom-control custom-switch custom-control-inline">
              <input type="checkbox" name='show_f1' className="custom-control-input" id="file1"/>
              <label className="custom-control-label" htmlFor="file1">File Option 1</label>
          </div>
            <div className='col-sm-10 col-lg-3 ml-2 mr-2'>
                <input name='name_f1' type="text" className="form-control form-control-sm" id="InputF1Name" aria-describedby="frHelp" placeholder="Enter Button Name"/>
            </div>
            <div className='col-sm-10 col-lg-3'>
                <input type="file" name='file1' className="form-control-file"/>
            </div>  
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <div className="col-lg-2 ml-3 mr-2 custom-control custom-switch custom-control-inline">
              <input type="checkbox" name='show_f2' className="custom-control-input" id="file2"/>
              <label className="custom-control-label" htmlFor="file2">File Option 2</label>
          </div>
            <div className='col-sm-10 col-lg-3 ml-2 mr-2'>
                <input name='name_f2' type="text" className="form-control form-control-sm" id="InputF2Name" aria-describedby="frHelp" placeholder="Enter Button Name"/>
            </div>
            <div className='col-sm-10 col-lg-3'>
                <input type="file" name='file2' className="form-control-file"/>
            </div>  
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <div className="col-lg-2 ml-3 mr-2 custom-control custom-switch custom-control-inline">
              <input type="checkbox" name='show_f3' className="custom-control-input" id="file3"/>
              <label className="custom-control-label" htmlFor="file3">File Option 3</label>
          </div>
            <div className='col-sm-10 col-lg-3 ml-2 mr-2'>
                <input name='name_f3' type="text" className="form-control form-control-sm" id="InputF3Name" aria-describedby="frHelp" placeholder="Enter Button Name"/>
            </div>
            <div className='col-sm-10 col-lg-3'>
                <input type="file" name='file3' className="form-control-file"/>
            </div>  
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <div className="col-lg-2 ml-3 mr-2 custom-control custom-switch custom-control-inline">
              <input type="checkbox" name='show_f1_fr' className="custom-control-input" id="file1_fr"/>
              <label className="custom-control-label" htmlFor="file1_fr">File Option 1 FR</label>
          </div>
            <div className='col-sm-10 col-lg-3 ml-2 mr-2'>
                <input name='name_f1_fr' type="text" className="form-control form-control-sm" id="InputF1Name_fr" aria-describedby="frHelp" placeholder="Enter Button Name"/>
            </div>
            <div className='col-sm-10 col-lg-3'>
                <input type="file" name='file1_fr' className="form-control-file"/>
            </div>  
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <div className="col-lg-2 ml-3 mr-2 custom-control custom-switch custom-control-inline">
              <input type="checkbox" name='show_f2_fr' className="custom-control-input" id="file2_fr"/>
              <label className="custom-control-label" htmlFor="file2_fr">File Option 2 FR</label>
          </div>
            <div className='col-sm-10 col-lg-3 ml-2 mr-2'>
                <input name='name_f2_fr' type="text" className="form-control form-control-sm" id="InputF2Name_fr" aria-describedby="frHelp" placeholder="Enter Button Name"/>
            </div>
            <div className='col-sm-10 col-lg-3'>
                <input type="file" name='file2_fr' className="form-control-file"/>
            </div>  
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <div className="col-lg-2 ml-3 mr-2 custom-control custom-switch custom-control-inline">
              <input type="checkbox" name='show_f3_fr' className="custom-control-input" id="file3_fr"/>
              <label className="custom-control-label" htmlFor="file3_fr">File Option 3 FR</label>
          </div>
            <div className='col-sm-10 col-lg-3 ml-2 mr-2'>
                <input name='name_f3_fr' type="text" className="form-control form-control-sm" id="InputF3Name_fr" aria-describedby="frHelp" placeholder="Enter Button Name"/>
            </div>
            <div className='col-sm-10 col-lg-3'>
                <input type="file" name='file3_fr' className="form-control-file"/>
            </div>  
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };