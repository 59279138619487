import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
//import AdminConsCatCreate from './AdminConsCatCreate';
//import AdminConsSubCatCreate from './AdminConsSubCatCreate';
//import AdminConsProductCreate from './AdminConsProductCreate';
//import AdminConsProdSKUCreate from './AdminConsProdSKUCreate';

import $ from 'jquery';

export default class AdminDownEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
   //   downloads: downloads // Cookies.get('language') || 'EN'
      dl_id: '',
      dl_name_en: '',
      dl_name_fr: '',

      dl_display_en: false,
      dl_display_fr: false,

      dl_image_en: '',
      dl_image_fr: '',
      dl_pdf_en: '',
      dl_pdf_fr: ''
  };
  //  this._setLanguage = this._setLanguage.bind(this);
    this._onClickSel = this._onClickSel.bind(this);
    this._onChangeNameEn = this._onChangeNameEn.bind(this);
    this._onChangeNameFr = this._onChangeNameFr.bind(this);
    this._onClickDispEn = this._onClickDispEn.bind(this);
    this._onClickDispFr = this._onClickDispFr.bind(this);
    this._onSubmitEnImage = this._onSubmitEnImage.bind(this);
    this._onSubmitFrImage = this._onSubmitFrImage.bind(this);
    this._onSubmitEnFile = this._onSubmitEnFile.bind(this);
    this._onSubmitFrFile = this._onSubmitFrFile.bind(this);
  }
  componentDidMount() {
    
  }

  _onClickSel(){
    var self = this;
    return function(){
    var selIdx = document.getElementById("download_select").selectedIndex;
    if ((selIdx !== undefined) && ( selIdx > 0 )){
      self.setState({
        dl_id: self.props.downloads[selIdx -1].id,
        dl_name_en: self.props.downloads[selIdx -1].name_en,
        dl_name_fr: self.props.downloads[selIdx -1].name_fr,

        dl_display_en: self.props.downloads[selIdx -1].display_en,
        dl_display_fr: self.props.downloads[selIdx -1].display_fr,

        dl_image_en: self.props.downloads[selIdx -1].image_en,
        dl_image_fr: self.props.downloads[selIdx -1].image_fr,
        dl_pdf_en: self.props.downloads[selIdx -1].pdf_en,
        dl_pdf_fr: self.props.downloads[selIdx -1].pdf_fr
      });
    }
    }
  }

  _onChangeNameEn(e){
    var str = e.target.value;
    this.setState({dl_name_en: str});
  }

  _onChangeNameFr(e){
    var str = e.target.value;
    this.setState({dl_name_fr: str});
  }

  _onClickDispEn(){
    var newstate = !this.state.dl_display_en;
    this.setState({dl_display_en:newstate});
  }
  _onClickDispFr(){
    var newstate = !this.state.dl_display_fr;
    this.setState({dl_display_fr:newstate});
  }

  _onSubmit(e) {
    e.preventDefault();
    
    var form = $('#editdownload')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 30000,
        success: function (data) {
        // $("#result").text(data);
            alert("SUCCESS : " + data);
        //  $("#btnSubmit").prop("disabled", false);
        $('#editdownload')[0].reset();
        //$('#contactForm')[0].reset();
        },
        error: function (err) {
        // $("#result").text(e.responseText);
            alert("ERROR : " + JSON.stringify(err));
        //  $("#btnSubmit").prop("disabled", false);
        }
    });
  }
  _onSubmitEnImage(e){
    e.preventDefault();
    
    var form = $('#editdownload_en_image')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 30000,
        success: function (data) {
            alert("SUCCESS : " + data);
          $('#editdownload_en_image')[0].reset();
        },
        error: function (err) {
            alert("ERROR : " + JSON.stringify(err));
        }
    });
  }
  _onSubmitFrImage(e){
    e.preventDefault();
    
    var form = $('#editdownload_fr_image')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 30000,
        success: function (data) {
            alert("SUCCESS : " + data);
          $('#editdownload_fr_image')[0].reset();
        },
        error: function (err) {
            alert("ERROR : " + JSON.stringify(err));
        }
    });
  }
  _onSubmitEnFile(e){
    e.preventDefault();
    
    var form = $('#editdownload_en_file')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 30000,
        success: function (data) {
            alert("SUCCESS : " + data);
          $('#editdownload_en_file')[0].reset();
        },
        error: function (err) {
            alert("ERROR : " + JSON.stringify(err));
        }
    });
  }
  _onSubmitFrFile(e){
    e.preventDefault();
    
    var form = $('#editdownload_fr_file')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 30000,
        success: function (data) {
            alert("SUCCESS : " + data);
          $('#editdownload_fr_file')[0].reset();
        },
        error: function (err) {
            alert("ERROR : " + JSON.stringify(err));
        }
    });
  }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));
    var self = this;
    return (
      <div>
        <h5 className="card-title text-center font-weight-bold">Downloads Edit</h5>
          <div className="form-group row">
          <label className='col-sm-2 col-form-label font-weight-bold'>Select</label>
          <div className='col-sm-10 col-lg-6'>
          <select id='download_select' name='product' className="col-sm-10 col-lg-6 form-control form-control-sm" onClick={self._onClickSel(this.value)}>
            <option>None</option>
            {
              self.props.downloads.map(thisdown=>{
                return <option>{thisdown.id}</option>
              })
            }
          </select>
          </div>
          </div>
          <form id='editdownload' action='/admin/update_download' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1de" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.dl_id} readonly/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameEn} value={self.state.dl_name_en} name='name_en' type="text" className="form-control form-control-sm" id="InputENde" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameFr} value={self.state.dl_name_fr} name='name_fr' type="text" className="form-control form-control-sm" id="InputFRde" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        
        
        
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispEn} type="checkbox" name='display_en' className="custom-control-input" id="visiblepEND" checked={this.state.dl_display_en === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepEND">Display English</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispFr} type="checkbox" name='display_fr' className="custom-control-input" id="visiblepFRD" checked={this.state.dl_display_fr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepFRD">Display French</label>
        </div>
        

        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit Updates</button>
        </form>
        <hr className='my-1'></hr>
        <form id='editdownload_en_image' action='/admin/update_download_en_image' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnImage}>
        <div className="form-group row">
            <div className='d-none'>
              <input name='id' type="text" className="form-control form-control-sm" id="EnImageDown" aria-describedby="idHelp"  value={this.state.dl_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_en' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
          <div className='col-12 text-bold'>{self.state.dl_image_en}</div>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editdownload_fr_image' action='/admin/update_download_fr_image' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrImage}>
        <div className="form-group row">
            <div className='d-none'>
              <input name='id' type="text" className="form-control form-control-sm" id="FrImageDown" aria-describedby="idHelp"  value={this.state.dl_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
            <div className='col-12 text-bold'>{self.state.dl_image_fr}</div>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editdownload_en_file' action='/admin/update_download_en_file' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitEnFile}>
        <div className="form-group row">
            <div className='d-none'>
              <input name='id' type="text" className="form-control form-control-sm" id="EnFileDown" aria-describedby="idHelp"  value={this.state.dl_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>English File</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='pdf_en' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
            <span className='col-12 text-bold'>{self.state.dl_pdf_en}</span>
        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editdownload_fr_file' action='/admin/update_download_fr_file' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitFrFile}>
        <div className="form-group row">
            <div className='d-none'>
              <input name='id' type="text" className="form-control form-control-sm" id="FrFileDown" aria-describedby="idHelp"  value={this.state.dl_id} readonly required/>
            </div>
            <label className='col-sm-2 col-form-label font-weight-bold'>French File</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='pdf_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-primary">Send File</button>
            <span className='col-12 text-bold'>{self.state.dl_pdf_fr}</span>
        </div>
        </form>
        <hr className='my-1'></hr>
      </div>
    )
    }
  };