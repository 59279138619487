import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminProSubCatItem from './AdminProSubCatItem';

export default class AdminProSubCatEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      filter: ''
    };
    this._changeFilter = this._changeFilter.bind(this);
  }
  componentDidMount() {
    
  }

  _changeFilter(e){
    let str = e.target.value;
    this.setState({filter:str});
  }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Sub-Category Edit</h5>
          <div className="input-group mb-3 col-lg-5">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">Filter</span>
          </div>
          <input type="text" className="form-control" placeholder="Filter By Eng Name" aria-label="Username" aria-describedby="basic-addon1" onChange={this._changeFilter} value={this.state.filter}/>
        </div>
            <table class="table table-sm table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sort</th>
                    <th scope="col">English Title</th>
                    <th scope="col">French Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">Show Can</th>
                    <th scope="col">Show US</th>
                    <th scope="col">Show Global</th>
                    <th scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                    {
                        this.props.subcategories.map(thiscat => 
                          {if (self.state.filter.length > 0) {
                            let lowfilter = self.state.filter.toLowerCase();
                            let lowID = thiscat.name_en.substring(0, lowfilter.length).toLowerCase();
                            if (lowID === lowfilter) {
                              return <AdminProSubCatItem categories={self.props.categories} subcategory={thiscat} />
                            } else {
                              return ''
                            }
                          } else {
                            return <AdminProSubCatItem categories={self.props.categories} subcategory={thiscat} />
                          }}
                                
                        )
                    }
                </tbody>
            </table>


      </div>
    )
    }
  };