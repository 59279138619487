import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import consProducts from '../data/consProd';
import ConsMenu from './ConsMenu';
import ConsContent from './ConsContent';

import $ from 'jquery';

export default class ConsumerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      consProducts: consProducts // Cookies.get('language') || 'EN'
    };
  //  this._setLanguage = this._setLanguage.bind(this);
    this._getConsData = this._getConsData.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0,0);
    this._getConsData();
  }

  _getConsData(){
    var self = this;
   // console.log('Getting Consumer Data');
    $.get('/data/consdata',function(data, status, xhr){
   //   console.log('Consumer Data status: ' + status, data);
      if (data) {
        if (data.categories.length > 0) {
          self.setState({consProducts: data});
        }
      }
    },'json');
  }
  // //data/consdata
  
  render() {
    return (
      <div>
        <div className='conspagebg pagebgimg'>
          <div style={{height: '60px'}}></div>
          <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'60px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Consumers':'Consommateurs'}</h1>
          <div style={{height: '1px'}}></div>
        </div>
        <div className='card bg-white border-0 rounded-0'>
          <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto'>
           {
             this.props.country === 'CA' ? 
             <div className='d-flex justify-content-end'>
            <h6 className='mr-2 font-weight-bold'><i className="fab fa-canadian-maple-leaf"></i> {this.props.lang === 'FR' ? 'Compagnie Canadienne':'Canadian Owned'}</h6>
            <h6> | </h6>
            <h6 className='ml-2 font-weight-bold'><i className="fas fa-shipping-fast"></i> {this.props.lang === 'FR' ? 'Expédié à partir du Canada' : 'Ships From Canada'}</h6>
            </div> : ''}
            <div className='row my-4'>
            <div className='col-md-4'>
            <ConsMenu {...this.state.consProducts} lang={this.props.lang}></ConsMenu>
            </div>
            <div className='col-md-8 px-0'>
              <ConsContent {...this.state.consProducts} lang={this.props.lang} country={this.props.country}></ConsContent>
            </div>
            </div>
          </div>
        </div>
      </div>
    )
    }
  };
  //export default AboutComponent;