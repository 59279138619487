import React from 'react';
// import { NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';
//import $ from 'jquery';

export default class ConsSKU extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      selected_var: 1,
      freeze: false,
      b1gen: true,
      b2gen: false
    };
    this._ChangeFunction = this._ChangeFunction.bind(this);
    this._setButtons = this._setButtons.bind(this);
  }

  
  componentDidUpdate(){
    forceCheck();
  }


  componentDidMount() {
    this._setButtons();

  }

  _setButtons(){
    var self = this;
    //  this._getFreezeMode();
    (function () {
      //var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          if ((typeof (self.props.sku.shopifybtn) === 'string') && (self.props.country === 'CA') && (self.props.sku.sale_ca)){
            if (self.state.selected_var === 1){
                ShopifyBuyInit();
            } else if (typeof (self.props.sku.shopifybtn_v) === 'string') {
             // console.log('Loading Alt button data');
              ShopifyBuyInitAlt();
            }
          } else {
          //  console.log('No button');
          }
        } else {
        //  loadScript();
        }
      } else {
      //  loadScript();
      }
     /* function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      } */
      function ShopifyBuyInit() {
        var client = window.ShopifyBuy.buildClient({
          domain: 'interstarmaterials.myshopify.com',
          storefrontAccessToken: '2762cfe15e6a5ad2942a96e4abca8489',
        });
        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('product', {
            id: self.props.sku.shopifybtn,//'4838036832388',
            node: document.getElementById('product-component-' + self.props.sku.shopifybtn),//('product-component-1587056269474'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
      "product": {
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "calc(25% - 20px)",
              "margin-left": "20px",
              "margin-bottom": "50px"
            },
            "text-align": "left"
          },
          "button": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "normal",
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px",
            "padding-left": "20px",
            "padding-right": "20px",
            ":hover": {
              "background-color": "#095fa7"
            },
            "background-color": "#0a69ba",
            ":focus": {
              "background-color": "#095fa7"
            },
            "border-radius": "0px"
          },
          "quantityInput": {
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px"
          }
        },
        "contents": {
          "img": false,
          "title": false,
          "price": false
        },
        "googleFonts": [
          "PT Sans"
        ]
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          },
          "button": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "normal",
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px",
            ":hover": {
              "background-color": "#095fa7"
            },
            "background-color": "#0a69ba",
            ":focus": {
              "background-color": "#095fa7"
            },
            "border-radius": "0px"
          },
          "quantityInput": {
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px"
          }
        },
        "googleFonts": [
          "PT Sans"
        ],
        "text": {
          "button": "Add to cart"
        }
      },
      "cart": {
        "styles": {
          "button": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "normal",
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px",
            ":hover": {
              "background-color": "#095fa7"
            },
            "background-color": "#0a69ba",
            ":focus": {
              "background-color": "#095fa7"
            },
            "border-radius": "0px"
          }
        },
        "text": {
          "total": "Subtotal",
          "button": "Checkout"
        },
        "googleFonts": [
          "PT Sans"
        ]
      },
      "toggle": {
        "styles": {
          "toggle": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "bold",
            "background-color": "#0a69ba",
            ":hover": {
              "background-color": "#095fa7"
            },
            ":focus": {
              "background-color": "#095fa7"
            }
          },
          "count": {
            "font-size": "17px"
          }
        },
        "googleFonts": [
          "PT Sans"
        ]
      }
    },
          });
        });
      }
  
      function ShopifyBuyInitAlt() {
        console.log('Alt Code');
        var client = window.ShopifyBuy.buildClient({
          domain: 'interstarmaterials.myshopify.com',
          storefrontAccessToken: '2762cfe15e6a5ad2942a96e4abca8489',
        });
        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('product', {
            id: self.props.sku.shopifybtn_v,//'4838036832388',
            node: document.getElementById('product-component-' + self.props.sku.shopifybtn_v),//('product-component-1587056269474'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
      "product": {
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "calc(25% - 20px)",
              "margin-left": "20px",
              "margin-bottom": "50px"
            },
            "text-align": "left"
          },
          "button": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "normal",
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px",
            "padding-left": "20px",
            "padding-right": "20px",
            ":hover": {
              "background-color": "#095fa7"
            },
            "background-color": "#0a69ba",
            ":focus": {
              "background-color": "#095fa7"
            },
            "border-radius": "0px"
          },
          "quantityInput": {
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px"
          }
        },
        "contents": {
          "img": false,
          "title": false,
          "price": false
        },
        "googleFonts": [
          "PT Sans"
        ]
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          },
          "button": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "normal",
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px",
            ":hover": {
              "background-color": "#095fa7"
            },
            "background-color": "#0a69ba",
            ":focus": {
              "background-color": "#095fa7"
            },
            "border-radius": "0px"
          },
          "quantityInput": {
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px"
          }
        },
        "googleFonts": [
          "PT Sans"
        ],
        "text": {
          "button": "Add to cart"
        }
      },
      "cart": {
        "styles": {
          "button": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "normal",
            "font-size": "17px",
            "padding-top": "12.5px",
            "padding-bottom": "12.5px",
            ":hover": {
              "background-color": "#095fa7"
            },
            "background-color": "#0a69ba",
            ":focus": {
              "background-color": "#095fa7"
            },
            "border-radius": "0px"
          }
        },
        "text": {
          "total": "Subtotal",
          "button": "Checkout"
        },
        "googleFonts": [
          "PT Sans"
        ]
      },
      "toggle": {
        "styles": {
          "toggle": {
            "font-family": "PT Sans, sans-serif",
            "font-weight": "bold",
            "background-color": "#0a69ba",
            ":hover": {
              "background-color": "#095fa7"
            },
            ":focus": {
              "background-color": "#095fa7"
            }
          },
          "count": {
            "font-size": "17px"
          }
        },
        "googleFonts": [
          "PT Sans"
        ]
      }
    },
          });
        });
      }
  
  
    })();
  }


  _ChangeFunction(){
    var self = this;
    var selectBox = document.getElementById('varientsel' + this.props.sku.id);
    var value = selectBox.options[selectBox.selectedIndex].value;
   // console.log('new Value: ' + value);
    self.setState({selected_var:(value * 1)});
    self.forceUpdate();

   setTimeout(function(){
     if (((self.state.b1gen === false) && (value === 1)) || ((self.state.b2gen === false) && (value === 2)))
    self._setButtons();
    if (value === 2){
      self.setState({b2gen:true});
    } else {
      self.setState({b1gen:true});
    }
   },200);
   
    /* if (value == 2) {

    } else {

    } */

  }

  render() {
  //  var self = this;
   // console.log('this.state.selected_var: '  + this.state.selected_var );
    //
    //<button className='mt-3 btn btn-large rounded-0 border-0 cons-button'>ADD TO CART 1234</button>
    return (
      <div key={this.props.sku.id} className='card border-0 border-bottom my-3'>
                    <div className='row'>
                        <div className='col-md-4' style={{maxWidth:'200px'}}><LazyLoad height={200} offset={100}><img className='img-fluid' src={'./img/consumer/' + this.props.sku.img} alt='sampletile'></img></LazyLoad></div>
                       <div className='col-md-8 ml-0 px-0'>
                        <h6 className='text-left card-title mb-2 mt-1 ml-3' style={{fontSize:'24px'}}>
                          {this.props.lang === 'EN' ? this.props.sku.name_en : this.props.sku.name_fr}
                          </h6>

                          

            { ((this.props.country === 'CA') && (this.props.sku.sale_ca)) ? <h6 className='mt-3 ml-3' style={{fontSize:'17px'}}>${this.state.selected_var === 1 ? this.props.sku.price_cad : this.props.sku.price_cad_v} CAD</h6> :
              ((this.props.country === 'US') && (this.props.sku.sale_us)) ? <h6 className='mt-3 ml-3' style={{fontSize:'17px'}}>${this.state.selected_var === 1 ? this.props.sku.price_usd : this.props.sku.price_usd_v} USD</h6> : '' }
                        <h6 className='mt-3 ml-3' style={{fontSize:'17px'}}>{ this.state.selected_var === 1 ? this.props.sku.id : this.props.sku.var_id}</h6>

                        { ((this.props.sku.var_opt) && (this.props.country === 'CA') && (this.props.sku.sale_ca)) ?
                        <div className="form-group row ml-auto">
                         {/* <label className='col-sm-4 col-form-label font-weight-bold'>{this.props.lang === 'EN' ? 'Option' : 'le choix'}</label> */}
                          <select id={'varientsel' + this.props.sku.id} name='varient' className="col-sm-6 mx-3 form-control form-control-sm" onChange={this._ChangeFunction}>
                              <option value={1} selected={this.state.selected_var === 1}>{this.props.lang === 'EN' ? this.props.sku.name_v1 : this.props.sku.name_v1_fr}</option>
                              <option value={2} selected={this.state.selected_var === 2}>{this.props.lang === 'EN' ? this.props.sku.name_v2 : this.props.sku.name_v2_fr}</option>
                            </select>
                        </div> : <div> </div> 
                        }
                        { ((this.props.country === 'CA') && (this.props.sku.sale_ca)) ? 
                        <div>
                          <div className={this.state.selected_var === 1 ? 'd-inline ml-3' : 'd-none'} id={'product-component-' + this.props.sku.shopifybtn}></div>
                          <div className={this.state.selected_var === 2 ? 'd-inline ml-3' : 'd-none'} id={'product-component-' + this.props.sku.shopifybtn_v}></div>
                        </div>
                        :''}
                        </div>

                    </div>
                    
                    <hr className='mb-0' style={{height:'1px', border:'none',color:'black','backgroundColor':'black'}}></hr>
                    </div>
    )
  }
};
