import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminConsCatCreate extends React.Component {
  componentDidMount() {
    
  }
  _onSubmit(e) {
    e.preventDefault();
    
    var form = $('#createconscat')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 20000,
        success: function (data) {
        // $("#result").text(data);
            alert("SUCCESS : " + data);
        //  $("#btnSubmit").prop("disabled", false);
        $('#createconscat')[0].reset();
        //$('#contactForm')[0].reset();
        },
        error: function (err) {
        // $("#result").text(e.responseText);
            alert("ERROR : " + JSON.stringify(err));
        //  $("#btnSubmit").prop("disabled", false);
        }
    });
    }
  render() {
    return (
      <div>
          <h5 className="card-title text-center">Consumer Category</h5>
        <form id="createconscat" action='/admin/createconscat' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control" id="InputID1cc" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>English Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_en' type="text" className="form-control" id="InputENcc" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>French Title</label>
            <div className='col-sm-10 col-lg-6'>
                <input name='name_fr' type="text" className="form-control" id="InputFRcc" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_ca' className="custom-control-input" id="visibleCANc"/>
            <label className="custom-control-label" htmlFor="visibleCANc">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_us' className="custom-control-input" id="visibleUSc"/>
            <label className="custom-control-label" htmlFor="visibleUSc">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='show_gl' className="custom-control-input" id="visibleGLc"/>
            <label className="custom-control-label" htmlFor="visibleGLc">Show Global</label>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control" id="Inputsortcc" aria-describedby="idHelp" placeholder='0'/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    )
    }
  };