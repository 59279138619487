import React from 'react';
import { NavLink } from 'react-router-dom'; 
import $ from 'jquery';
// import LazyLoad from 'react-lazyload';
// import { forceCheck } from 'react-lazyload';

export default class ProdInfoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
        //  proProducts: proProducts // Cookies.get('language') || 'EN'
            name_en: 'No Product Found',
            name_fr: 'Aucun produit trouvé',
            desc_en: ['Please check the Professional or Consumers page'],
            desc_fr: ['Veuillez consulter la page Professionnels ou Consommateurs'],
            img:'',
            show_colorchart: false,
            show_productinfo: false,
            show_brochure: false,
            show_instructions: false,
            show_overview: false,
            show_colorchartfr: false,
            show_productinfofr: false,
            show_brochurefr: false,
            show_instructionsfr: false,
            show_overviewfr: false,
            chart_en: '',
            productinfo_en: '',
            brochure_en:'',
            instructions_en: '',
            overview_en: '',
            chart_fr: '',
            productinfo_fr: '',
            brochure_fr:'',
            instructions_fr: '',
            overview_fr: '',
        };
      //  this._setLanguage = this._setLanguage.bind(this);
        this._getProductData = this._getProductData.bind(this);
      }
      componentDidMount() {
      //  window.scrollTo(0,0);
      //  this._getProductData();
      let url = window.location.href.replace(/\/$/, "");
      let breakup = url.split("/");
      let paramID = breakup[breakup.length -1];
    //  console.log('URL: ' + url);
    //  console.log('Props: ', this.props);
      console.log('ParamID: ' + paramID);

      if (paramID !== 'Info') {
          //lookup product
          this._getProductData(paramID);
      }
       /* if (this.props.match.params) {
            console.log('Match Params Avail.');
        } else {
            console.log('No Params to pass.');
        } */
    
      }
    
      _getProductData(paramID){
        var self = this;

     //   return function(){
          console.log('Getting Product Info');
          $.get('/data/productinfo', {product: paramID}, function(data, status, xhr) {
            console.log('Product Info Status: ' + status, data);
            console.log('Product Info data: ' + JSON.stringify(data));
            if (data) {
             /* if (data.categories.length > 0) {
                self.setState({proProducts: data});
              } */
              var description_en = [];
              var description_fr = [];
              if (typeof(data.desc_en) === 'object'){
               // console.log('1' + data.desc_en[0]);
                description_en = data.desc_en;
                description_fr = data.desc_fr;
              } else {
              //  console.log('2')
                description_en[0] = data.desc_en;
                description_fr[0] = data.desc_fr;
              }
              console.log(description_fr);
              console.log(description_en);
              self.setState({
                name_en: data.name_en,
                name_fr: data.name_fr,
                desc_en: description_en,
                desc_fr: description_fr,
                img:'',
                show_colorchart: data.show_colorchart || false,
                show_productinfo: data.show_productinfo || false,
                show_brochure: data.show_brochure || false,
                show_instructions: data.show_instructions || false,
                show_overview: data.show_overview || false,
                show_colorchartfr: data.show_colorchartfr || false,
                show_productinfofr: data.show_productinfofr || false,
                show_brochurefr: data.show_brochurefr || false,
                show_instructionsfr: data.show_colorchart || false,
                show_overviewfr: data.show_colorchart || false,
                chart_en: data.chart_en,
                productinfo_en: data.productinfo_en,
                brochure_en: data.brochure_en,
                instructions_en: data.instructions_en,
                overview_en: data.overview_en,
                chart_fr: data.chart_fr,
                productinfo_fr: data.productinfo_fr,
                brochure_fr: data.brochure_fr,
                instructions_fr: data.instructions_fr,
                overview_fr: data.overview_fr
              });
            }
          },'json');

     //   }


        //console.log('Getting Pro Data');
      /*  $.get('/data/prodata',function(data, status, xhr){
          //console.log('Pro Data Status: ' + status, data);
         // console.log('pro data: ' + JSON.stringify(data));
          if (data) {
            if (data.categories.length > 0) {
              self.setState({proProducts: data});
            }
          }
        },'json'); */
      }

      render() {
        var self = this;
          return(
            <div>
            <div style={{
              'background':"#0a69ba",
              'backgroundPosition':'center center',
              'backgroundRepeat':'no-repeat',
              'backgroundAttachment':'fixed',
              'backgroundSize': 'cover',
                minHeight:'230px',
               marginTop:'-170px'}}>
               <div style={{height: '60px'}}></div>
               <h1 className='text-white font-weight-bolder' style={{marginTop:'100px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? this.state.name_en:this.state.name_fr}</h1>
            </div>
            <div className='card bg-white border-0 rounded-0'>
             <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto text-left'>
            {// <div className='col-sm-11 ml-0 px-0'><LazyLoad height={200} once><img src={'./img/pro_banner/' + this.state.img} alt='bannerimage' className='img-fluid'></img></LazyLoad></div>
            }
            <h3>{this.props.lang === 'EN' ? this.state.name_en : this.state.name_fr}</h3>
             {this.props.lang === 'EN' ? this.state.desc_en.map(desc => <p className='my-3' style={{fontSize:'17px'}}>{desc}</p>) : this.state.desc_fr.map(desc => <p className='my-3' style={{fontSize:'17px'}}>{desc}</p>)}
             <div className='row mx-1 d-flex justify-content-between'>
                      {(self.state.show_colorchart && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/charts/en/' + self.state.chart_en} target='_blank' rel='noopener noreferrer'>COLOR CHART</a> : ''}
                      {(self.state.show_productinfo && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/productinfo/en/' + self.state.productinfo_en} target='_blank' rel='noopener noreferrer'>PRODUCT INFO SHEET </a> : ''}
                      {(self.state.show_brochure && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/brochures/en/' + self.state.brochure_en} target='_blank' rel='noopener noreferrer'>BROCHURE</a> : ''}
                      {(self.state.show_instructions && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/instructions/en/' + self.state.instructions_en} target='_blank' rel='noopener noreferrer'>INSTRUCTIONS</a> : ''}
                      {(self.state.show_overview && self.props.lang === 'EN') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/overview/en/' + self.state.overview_en} target='_blank' rel='noopener noreferrer'>OVERVIEW</a> : ''}

                      {(self.state.show_colorchartfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/charts/fr/' + self.state.chart_fr} target='_blank' rel='noopener noreferrer'>CHARTE DE COULEURS</a> : ''}
                      {(self.state.show_productinfofr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/productinfo/fr/' + self.state.productinfo_fr} target='_blank' rel='noopener noreferrer'>FICHE D'INFORMATION </a> : ''}
                      {(self.state.show_brochurefr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/brochures/fr/' + self.state.brochure_fr} target='_blank' rel='noopener noreferrer'>BROCHURE</a> : ''}
                      {(self.state.show_instructionsfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/instructions/fr/' + self.state.instructions_fr} target='_blank' rel='noopener noreferrer'>INSTRUCTIONS</a> : ''}
                      {(self.state.show_overviewfr && self.props.lang === 'FR') ? <a role='button' className='btn btn-large btn-info product-button my-3' href={'../downloads/overview/fr/' + self.state.overview_fr} target='_blank' rel='noopener noreferrer'>OVERVIEW</a> : ''}
              </div>
            </div>
            <hr className='my-2'/>
            {this.props.lang === 'EN' ? <p className='my-3' style={{fontSize:'17px'}}>Additional information and products may be found under the Professional or Consumers page</p> :
              <p className='my-3' style={{fontSize:'17px'}}>Des informations supplémentaires et des produits peuvent être trouvés sous la page Professionnels ou Consommateurs</p>
            }
            <hr className='my-2'/>
            <div className='col-lg-8 col-xl-5 mx-auto' style={{marginTop:'-25px'}}>
                <div className='row d-flex justify-content-between'>
                    <div className='col-sm-6 mb-2'>
                    <NavLink to='/professionals' style={{maxWidth:'275px'}} className='btn btn-lg btn-block bg-warning text-white rounded-0 mx-auto'><i className="fas fa-chevron-circle-left fa-lg mx-2"></i>{this.props.lang === 'EN' ? '  PROFESSIONALS':'  PROFESSIONNELS'}</NavLink>
                    </div>
                    <div className='col-sm-6 mb-2'>
                    <NavLink to='/consumers'  style={{maxWidth:'275px'}} className='btn btn-lg btn-block bg-success text-white rounded-0 mx-auto'>{this.props.lang === 'EN' ?'CONSUMERS  ' : 'CONSOMMATEURS'}<i className="fas fa-chevron-circle-right fa-lg mx-2"></i></NavLink>
                    </div>
                </div>
            </div>
            </div>
            </div>
          )
       }

}