import React from 'react';
import $ from 'jquery';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
//import AdminConsCatCreate from './AdminConsCatCreate';
//import AdminConsSubCatCreate from './AdminConsSubCatCreate';
//import AdminConsProductCreate from './AdminConsProductCreate';
//import AdminConsProdSKUCreate from './AdminConsProdSKUCreate';
export default class AdminGalleryCreate extends React.Component {
  componentDidMount() {
    
  }
  _onSubmit(e) {
    e.preventDefault();
    
    var form = $('#creategallery')[0];
    var data = new FormData(form);
    var actionurl = e.currentTarget.action;

    $.ajax({
        type: "POST",
        enctype: 'multipart/form-data',
        url: actionurl,
        data: data,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 20000,
        success: function (data) {
        // $("#result").text(data);
            alert("SUCCESS : " + data);
        //  $("#btnSubmit").prop("disabled", false);
        $('#creategallery')[0].reset();
        //$('#contactForm')[0].reset();
        },
        error: function (err) {
        // $("#result").text(e.responseText);
            alert("ERROR : " + JSON.stringify(err));
        //  $("#btnSubmit").prop("disabled", false);
        }
    });
    }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));
    /**
     * 'id': 'something',
      'visible':true,
      'thumb':'../img/downloads/en/EasySTAR dispenser_FR_web 2019.jpg',
      'image':'../img/downloads/en/EasySTAR dispenser_FR_web 2019.jpg',
     */
    return (
      <div>
        <h5>
          Create Gallery Item
        </h5>
        <form id="creategallery" action='/admin/creategallery' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Unique ID</label>
            <div className='col-sm-10 col-lg-3'>
            <input name='id' type="text" className="form-control" id="InputID1gal" aria-describedby="idHelp" placeholder="Enter ID" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label'>Sort</label>
            <div className='col-sm-10 col-lg-3'>
            <input name='sort' type="number" className="form-control" id="InputSRTgal" aria-describedby="idHelp" placeholder="sort" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='visible' className="custom-control-input" id="visibleGaL"/>
            <label className="custom-control-label" htmlFor="visibleGaL">Visible</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Thumbnail</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='thumb' className="form-control-file form-control-sm" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image' className="form-control-file form-control-sm" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit</button>
          </form>
      </div>
    )
    }
  };