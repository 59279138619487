import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';
import AdminChartsItem from './AdminChartsItem';

export default class AdminChartsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          colorCharts: [],//Cookies.get('language') || 'EN'
          filter: ''
        };
        this._getChartData = this._getChartData.bind(this);
        this._changeFilter = this._changeFilter.bind(this);
      }
    
      componentDidMount() {
        this._getChartData();
      }
    
      _getChartData(){
        var self = this;
       // console.log('Getting Chart Data');
        $.get('/data/charts',function(data,status,xhr){
        // console.log(' Chart Data status: ' + status + ' Data: ' + JSON.stringify(data));
          if (data.length > 0) {
            self.setState({colorCharts: data});
          }
        },'json')  
      }

      _changeFilter(e){
        let str = e.target.value;
        this.setState({filter:str});
      }
  render() {
    var self = this;
    return (
      <div>
        <div className="input-group mb-3 col-lg-5">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">Filter</span>
          </div>
          <input type="text" className="form-control" placeholder="Filter By Eng Name" aria-label="Username" aria-describedby="basic-addon1" onChange={this._changeFilter} value={this.state.filter}/>
        </div>
        <table class="table table-sm table-striped table-hover">
        <thead>
            <tr>
            <th scope="col">ID</th>
            <th scope="col">Sort</th>
            <th scope="col">English Name</th>
            <th scope="col">French Name</th>
            <th scope="col">English Image</th>
            <th scope="col">French Image</th>
            <th scope="col">English PDF</th>
            <th scope="col">French PDF</th>
            <th scope="col">Visible</th>
            <th scope="col">Visible</th>
            <th scope="col">Disc</th>
            <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            {this.state.colorCharts.map(chart =>
                {if (self.state.filter.length > 0) {
                  let lowfilter = self.state.filter.toLowerCase();
                  let lowID = chart.name_en.substring(0, lowfilter.length).toLowerCase();
                  if (lowID === lowfilter) {
                    return <AdminChartsItem chart={chart}/>
                  } else {
                    return ''
                  }
                } else {
                  return <AdminChartsItem chart={chart}/>
                }}
            )}
        </tbody>
        </table>
      </div>
    )
    }
  };