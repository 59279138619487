import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';

export default class ServicesPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div>
      <div className='servpagebg pagebgimg'>
          <div style={{height: '50px'}}></div>
         <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'50px', 'textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Services':'Services'}</h1>
         <div style={{height: '1px'}}></div>
      </div>
      <div className='card bg-white border-0 rounded-0'>
        <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto px-0 text-center'>
          <p className='my-4'>
            {
            this.props.lang === 'EN' ? 'We’ve leveraged our expertise in the concrete industry to offer you more than just products. The professional marketing services and the technical support we provide will help you grow your business. Our flexible approach allows us to tailor our products and services to your specific needs.' :
            "Nous avons peaufiné notre expertise dans l’industrie du béton pour vous offrir plus que des produits. Les services de marketing professionnel et le soutien technique que nous offrons vous aideront à développer votre entreprise. Notre approche flexible nous permet d'adapter nos produits et services à vos besoins."
            }
          </p>
          <p className='font-weight-bold my-4'>
          { this.props.lang === 'EN' ? 'Focus on what you do best, and we’ll provide you with the tools and support you need to get the job done.' :
            "Concentrez-vous sur ce que vous faites de mieux et nous vous fournirons les outils et l’appui nécessaire dont vous aurez besoin pour obtenir les meilleurs résultats."
          }
          </p>
          <div className='row my-4'>
            <div className='col-md-4 text-right'>
            <nav>
            <div className="nav flex-column border-right font-weight-bold" id="nav-tab" role="tablist">
              <a className="nav-link link_style my-2 active" id="nav-csss-tab" data-toggle="tab" href="#nav-csss" style={{color:'black'}}>
              {
                this.props.lang === 'EN' ? 'Customer Service and Sales Support' : "Service à la clientèle et soutien aux ventes"
              }
              </a>
              <a className="nav-link link_style my-2" id="nav-cl-tab" data-toggle="tab" href="#nav-cl" style={{color:'black'}}>
              {
                this.props.lang === 'EN' ? 'Color Lab' : "Laboratoire de couleur"
              }
              </a>
              <a className="nav-link link_style my-2" id="nav-rd-tab" data-toggle="tab" href="#nav-rd" style={{color:'black'}}>
              {
                this.props.lang === 'EN' ? 'Research and Development' : "Recherche et développement"
              }
              </a>
              <a className="nav-link link_style my-2" id="nav-ds-tab" data-toggle="tab" href="#nav-ds" style={{color:'black'}}>
              {
                this.props.lang === 'EN' ? 'Dispenser Services' : "Services de dispensateurs"
              }
              </a>
              <a className="nav-link link_style my-2" id="nav-marketing-tab" data-toggle="tab" href="#nav-marketing" style={{color:'black'}}>
              Marketing
              </a>
              <a className="nav-link link_style my-2" id="nav-sales-tab" data-toggle="tab" href="#nav-sales" style={{color:'black'}}>
              {
                this.props.lang === 'EN' ? 'Sales' : "Ventes"
              }
              </a>
            </div>
            </nav>
            </div>
            <div className="tab-content col-md-8 text-left" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-csss" role="tabpanel" aria-labelledby="nav-csss-tab">
              <h3 className='mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Customer Service and Sales Support' : 'Service à la clientèle et soutien aux ventes'}</h3>
              <p className='mt-md-4'>
                {
                 this.props.lang === 'EN' ?
                 'Customer Service is not just a department; it is fundamental to our business philosophy. At Interstar, we believe that the service we provide is as important as the products we manufacture. Our Customer Service and Sales Support departments treat our customers as we would want to be treated – with responsive, courteous and knowledgeable support. When customers call Interstar, they are greeted by a live person, not an automated answering system because we believe that technology should be used in our products, not on our customers. Great service begins with hello – literally.' :
                 "Le service à la clientèle n’est pas seulement un département, il est une partie intégrale de notre philosophie. Chez Interstar, nous croyons que le service est aussi essentiel que le produit. Nos départements du service à la clientèle et du soutien aux ventes traitent nos clients comme nos aimerions être traités; avec un soutien rapide, courtois et bien informé. Lorsque nos clients appellent chez Interstar, ils sont accueillis par une vraie personne et non par un système automatisé, car nous croyons que la technologie devrait être appliquée dans nos produits, et non avec nos clients. Un service exceptionnel commence toujours par « Bonjour! » - littéralement."
                }
              </p>
              </div>
              <div className="tab-pane fade" id="nav-cl" role="tabpanel" aria-labelledby="nav-cl-tab">
              <h3 className='mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Color Lab' : 'Laboratoire de couleur'}</h3>
              <p className='mt-md-4'>
                {
                 this.props.lang === 'EN' ?
                 'Interstar’s color laboratory offers customized color matching for the entire concrete industry - brick, block, paver, mortar and ready mix. Our services do not end when your matches are complete. Our color technicians are always available to assist you with questions about color formulas, mix design, curing or raw materials. Interstar’s lab staff is comprised of experienced color experts who have an average of eight years of experience at Interstar. Their know-how and expertise will help you succeed.' :
                 "Le laboratoire de couleurs chez Interstar offre des équivalences de couleurs spécifiques pour chaque secteur de l’industrie du béton – briques, blocs, pavés, mortier et béton préparé. Notre service ne se termine pas avec un rapport d’équivalence. Nos techniciens sont toujours disponibles pour répondre à vos questions, que ce soit pour la formulation d’une couleur, la cure ou les matières premières. Notre équipe du laboratoire de couleurs se constitue d'experts qui comptent en moyenne huit années d’expérience chez Interstar. Leurs connaissances et leur expertise contribueront grandement à votre réussite."
                }
              </p>
              </div>
              <div className="tab-pane fade" id="nav-rd" role="tabpanel" aria-labelledby="nav-rd-tab">
              <h3 className='mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Research and Development' : 'Recherche et développement'}</h3>
              <p className='mt-md-4'>
                {
                 this.props.lang === 'EN' ?
                 'Interstar is a product development company whose scientists aggressively develop cutting-edge products for the concrete industry. Interstar’s scientists work with industry leaders, clients, and our own sales and marketing teams to understand the needs of the industry, and develop products to meet those needs. Whether it is a special admixture for natural stone or a highly durable sealer for concrete, Interstar’s scientist capitalize on their years of experience to develop products that move the concrete industry forward.' :
                 "Interstar est une compagnie de développement où des scientifiques s’acharnent à développer des produits à la fine pointe pour l’industrie du béton. Les chercheurs chez Interstar travaillent conjointement avec les chefs de file dans l’industrie, les clients, et notre propre équipe de ventes et de marketing, afin de mieux comprendre les besoins de l’industrie et développer des produits qui combleront la demande. Que ce soit un adjuvant spécifique pour les pierres naturelles ou un scellant à haute résistance pour le béton, les chercheurs chez Interstar capitalise sur leurs années d’expériences afin de développer des produits qui feront avancer l’industrie du béton."
                }
              </p>
              </div>
              <div className="tab-pane fade" id="nav-ds" role="tabpanel" aria-labelledby="nav-ds-tab">
              <h3 className='mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Dispenser Services' : 'Services de dispensateurs'}</h3>
              <p className='mt-md-4'>
                {
                 this.props.lang === 'EN' ?
                 'Interstar’s Automation Technicians are skilled and experienced professionals who are dedicated to providing support for all of our industry-leading pigment dispensing systems. Our Automation Technicians are available to assist clients on-site or remotely by phone or email anywhere in the world.' :
                 "Les techniciens en automatisation d’Interstar sont des professionnels d’expérience dévoués à offrir un soutien de qualité pour l’ensemble de nos distributeurs granulaires. Notre équipe de techniciens en automatisation est disponible, pour assister nos clients dans leurs installations où qu'ils se trouvent dans le monde."
                }
              </p>
              </div>
              <div className="tab-pane fade" id="nav-marketing" role="tabpanel" aria-labelledby="nav-marketing-tab">
              <h3 className='mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Marketing' : 'Marketing'}</h3>
              <p className='mt-md-4'>
                {
                 this.props.lang === 'EN' ?
                 'Interstar’s experienced Marketing team is available to develop customized brochures, labels and other marketing materials that will help customers sell their products. Click here to visit the marketing website.' :
                 "Notre équipe marketing est expérimentée et disponible pour créer des pamphlets personnalisés, des étiquettes et d’autres articles promotionnels qui aideront nos clients à vendre leurs produits."
                }
              </p>
              {
                 this.props.lang === 'EN' ? <a href='http://www.interstar-marketing.com/'>www.interstar-marketing.com</a> : ''
              }
                 </div>
              <div className="tab-pane fade" id="nav-sales" role="tabpanel" aria-labelledby="nav-sales-tab">
              <h3 className='mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Sales' : 'Ventes'}</h3>
              <p className='mt-md-4'>
                {
                 this.props.lang === 'EN' ?
                 'Interstar’s Sales Team is comprised of a diverse group of accomplished professionals with training and experience in all facets of the concrete industry. Their success has not been a result of their art of persuasion or dazzling sales tools, but rather, in their no-nonsense approach to getting clients the right product and providing unparalleled technical support. We believe a good sales team is defined by the satisfaction of our clients, and that quality service begins with hello.' :
                 "L’équipe des ventes chez Interstar est composée de divers professionnels accomplis qui ont la formation et l’expérience nécessaires dans tous les domaines de l’industrie. Leur succès ne réside dans la force de persuasion ou les outils de ventes inutiles, mais dans leur approche directe pour fournir à nos clients le bon produit et dans le soutien sans équivoqué. Nous sommes persuadés qu’une bonne équipe de vente se reflète dans la satisfaction de nos clients, et qu’un service de qualité débute toujours par « Bonjour! »."
                }
              </p>
              </div>
            </div>
          </div>  
        </div>
      </div>
      </div>
    )
    }
  };
  //export default AboutComponent;