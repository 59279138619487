import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import colorCharts from '../data/colorCharts';
import ChartComponent from './ChartComponent.js';
import $ from 'jquery';

export default class ColorCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      colorCharts: colorCharts//Cookies.get('language') || 'EN'
    };
    this._getChartData = this._getChartData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this._getChartData();
  }

  _getChartData(){
    var self = this;
   // console.log('Getting Chart Data');
    $.get('/data/charts',function(data,status,xhr){
    //  console.log('Chart Data Status: ' + status + ' Data: ' + JSON.stringify(data));
      if (data.length > 0) {
        self.setState({colorCharts: data});
      }
    },'json')  
  }
  render() {
    var self = this;
    return (
      <div>
      <div className='chartpagebg pagebgimg'>
         <div style={{height: '50px'}}></div>
         <h1 className='titleblock-header text-white font-weight-bolder' style={{marginBottom:'50px','textShadow': '1px 1px black', fontSize:'60px'}}>{this.props.lang === 'EN' ? 'Color Charts':'Chartes de couleurs'}</h1>
        <div style={{height: '1px'}}></div>
        </div>
        <div className='card text-black border-0 rounded-0' style={{background:'#f0f0f0'}}>
            <div className='card-body col-sm-10 col-lg-9 col-xl-6 mx-auto'>
              <h3 className='font-weight-bold mt-4' style={{fontSize:'30px'}}>{this.props.lang === 'EN' ? 'Disclaimer' : 'Important'}</h3>
              <p style={{fontSize:'17px'}}>
              {this.props.lang === 'EN' ? ' These color charts are presented only as a guideline, and should not be used to make your final color selection. Computer screens and printers vary greatly and cannot accurately show the colors that will be obtained. The samples pictured here were developed in a controlled environment using materials that vary in different geographic locations. A mockup is highly recommended to make sure the colors you choose meet your expectations. To obtain a color chart or samples, please contact our Customer Service Department at ' : " Ces chartes de couleurs sont présentés à titre indicatif seulement et ne doivent pas être utilisés pour faire votre sélection finale. Les écrans de couleurs et les imprimantes varient considérablement et ne peuvent pas démontrés précisément les couleurs qui seront obtenues. Les échantillons représentés ont été développés dans un environnement contrôlé en utilisant des matériaux pouvant varier selon la région. Un essai préliminaire avec la couleur est fortement recommandé afin de s’assurer que le résultat obtenu corresponde à vos attentes. Veuillez contacter notre service à la clientèle à "}<a href='mailto:info@interstar-paf.com'>info@interstar-paf.com</a>{this.props.lang === 'EN' ? ' or contact an Interstar sales representative.' : " ou votre représentant des ventes afin d’obtenir une charte de couleur ou des échantillons."}
              </p>
              <div className='row'>
                <LazyLoad>
                {this.state.colorCharts.map(itemData => <ChartComponent key={itemData.id} {...itemData} lang={self.props.lang}/>)}
                </LazyLoad>
              </div>
            </div>
          </div>
      </div>
    )
    }
  };
  //export default AboutComponent;