import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminConsProductEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          categories: [{id:'test1'},{id:'test2'}], //Cookies.get('language') || 'EN'
          cat_sel: '',

          /**
           *  
              memberof: fields.group === 'subcat' ? fields.subcat : fields.category,
           */

          prod_sel: '',

          prod_id:'',
          prod_name_en: '',
          prod_name_fr: '',
          prod_desc_en: '',
          prod_desc_fr: '',
          prod_membertype: '',
          prod_memberof: '',
          prod_show_en: false,
          prod_show_fr: false,
          prod_show_ca: false,
          prod_show_us: false,
          prod_show_gl: false,
          prod_freeze: false,
          prod_hazard: false,
          prod_eco: false,
          prod_order: 0,
          prod_show_f1: false,
          prod_show_f2: false,
          prod_show_f3: false,
          prod_name_f1: '',
          prod_name_f2: '',
          prod_name_f3: '',
          prod_file1: '',
          prod_file2: '',
          prod_file3: '',
          prod_show_f1_fr: false,
          prod_show_f2_fr: false,
          prod_show_f3_fr: false,
          prod_name_f1_fr: '',
          prod_name_f2_fr: '',
          prod_name_f3_fr: '',
          prod_file1_fr: '',
          prod_file2_fr: '',
          prod_file3_fr: ''

        };
        this._getProCats = this._getProCats.bind(this);
        this._onClickSel = this._onClickSel.bind(this);
        this._onChangeNameEn = this._onChangeNameEn.bind(this);
        this._onChangeNameFr = this._onChangeNameFr.bind(this);
        this._onChangeDescEn = this._onChangeDescEn.bind(this);
        this._onChangeDescFr = this._onChangeDescFr.bind(this);
        this._setmembertype = this._setmembertype.bind(this);
        this._onClickDispEN = this._onClickDispEN.bind(this);
        this._onClickDispFR = this._onClickDispFR.bind(this);
        this._onClickDispCA = this._onClickDispCA.bind(this);
        this._onClickDispUS = this._onClickDispUS.bind(this);
        this._onClickDispGL = this._onClickDispGL.bind(this);
        this._onClickFreeze = this._onClickFreeze.bind(this);
        this._onClickHazard = this._onClickHazard.bind(this);
        this._onClickEco = this._onClickEco.bind(this);
        this._onClickShowF1 = this._onClickShowF1.bind(this);
        this._onClickShowF2 = this._onClickShowF2.bind(this);
        this._onClickShowF3 = this._onClickShowF3.bind(this);
        this._onChangeNameF1 = this._onChangeNameF1.bind(this);
        this._onChangeNameF2 = this._onChangeNameF2.bind(this);
        this._onChangeNameF3 = this._onChangeNameF3.bind(this);
        this._onClickShowF1_fr = this._onClickShowF1_fr.bind(this);
        this._onClickShowF2_fr = this._onClickShowF2_fr.bind(this);
        this._onClickShowF3_fr = this._onClickShowF3_fr.bind(this);
        this._onChangeNameF1_fr = this._onChangeNameF1_fr.bind(this);
        this._onChangeNameF2_fr = this._onChangeNameF2_fr.bind(this);
        this._onChangeNameF3_fr = this._onChangeNameF3_fr.bind(this);
      }
    
      componentDidMount() {
        this._getProCats();
      }
    
      _getProCats(){
     /*   var self = this;
        console.log('Pro Category List');
        $.get('/data/procats',function(data,status,xhr){
          console.log('status: ' + status);
          if (data.length > 0) {
            self.setState({categories: data});
          }
        },'json'); */
      }

      _onClickSel(product){
        var self = this;
        return function(){
         // console.log('On Click Product: ' + product);
          var selIdx = document.getElementById("cprod_select").selectedIndex;
         // console.log('On Click ProductIdx: ' + selIdx);
          if ((selIdx !== undefined) && ( selIdx > 0 )){
            self.setState({
              prod_id: self.props.products[selIdx -1].id,
              prod_name_en: self.props.products[selIdx -1].name_en,
              prod_name_fr: self.props.products[selIdx -1].name_fr,
              prod_desc_en: self.props.products[selIdx -1].desc_en,
              prod_desc_fr: self.props.products[selIdx -1].desc_fr,
              prod_membertype: self.props.products[selIdx -1].membertype,
              prod_memberof: self.props.products[selIdx -1].memberof,
              prod_show_en: self.props.products[selIdx -1].show_en,
              prod_show_fr: self.props.products[selIdx -1].show_fr,
              prod_show_ca: self.props.products[selIdx -1].show_ca,
              prod_show_us: self.props.products[selIdx -1].show_us,
              prod_show_gl: self.props.products[selIdx -1].show_gl,
              prod_freeze: self.props.products[selIdx -1].freeze,
              prod_hazard: self.props.products[selIdx -1].hazard,
              prod_eco: self.props.products[selIdx -1].eco,
              prod_order: self.props.products[selIdx -1].order,
              prod_show_f1: self.props.products[selIdx -1].show_f1,
              prod_show_f2: self.props.products[selIdx -1].show_f2,
              prod_show_f3: self.props.products[selIdx -1].show_f3,
              prod_name_f1: self.props.products[selIdx -1].name_f1,
              prod_name_f2: self.props.products[selIdx -1].name_f2,
              prod_name_f3: self.props.products[selIdx -1].name_f3,
              prod_file1: self.props.products[selIdx -1].file1,
              prod_file2: self.props.products[selIdx -1].file2,
              prod_file3: self.props.products[selIdx -1].file3,
              prod_show_f1_fr: self.props.products[selIdx -1].show_f1_fr,
              prod_show_f2_fr: self.props.products[selIdx -1].show_f2_fr,
              prod_show_f3_fr: self.props.products[selIdx -1].show_f3_fr,
              prod_name_f1_fr: self.props.products[selIdx -1].name_f1_fr,
              prod_name_f2_fr: self.props.products[selIdx -1].name_f2_fr,
              prod_name_f3_fr: self.props.products[selIdx -1].name_f3_fr,
              prod_file1_fr: self.props.products[selIdx -1].file1_fr,
              prod_file2_fr: self.props.products[selIdx -1].file2_fr,
              prod_file3_fr: self.props.products[selIdx -1].file3_fr,
            });
          }
          
        }
      }

      _onChangeNameEn(e){
        var str = e.target.value;
        this.setState({prod_name_en: str});
      }

      _onChangeNameFr(e){
        var str = e.target.value;
        this.setState({prod_name_fr: str});
      }
      _onChangeDescEn(e){
        var str = e.target.value;
        this.setState({prod_desc_en: str});
      }

      _onChangeDescFr(e){
        var str = e.target.value;
        this.setState({prod_desc_fr: str});
      }
      _setmembertype(type) {
        var self = this;
      //  console.log('Set membertype: ' + type);
        return function(){
          self.setState({prod_membertype: type});
        }
      }
      _onClickDispEN(){
        var newstate = !this.state.prod_show_en;
        this.setState({prod_show_en:newstate});
      }
      _onClickDispFR(){
        var newstate = !this.state.prod_show_fr;
        this.setState({prod_show_fr:newstate});
      }
      _onClickDispCA(){
        var newstate = !this.state.prod_show_ca;
        this.setState({prod_show_ca:newstate});
      }
      _onClickDispUS(){
        var newstate = !this.state.prod_show_us;
        this.setState({prod_show_us:newstate});
      }
      _onClickDispGL(){
        var newstate = !this.state.prod_show_gl;
        this.setState({prod_show_gl:newstate});
      }
      _onClickFreeze(){
        var newstate = !this.state.prod_freeze;
        this.setState({prod_freeze:newstate});
      }
      _onClickHazard(){
        var newstate = !this.state.prod_hazard;
        this.setState({prod_hazard:newstate});
      }
      _onClickEco(){
        var newstate = !this.state.prod_eco;
        this.setState({prod_eco:newstate});
      }
      _onClickShowF1(){
        var newstate = !this.state.prod_show_f1;
        this.setState({prod_show_f1:newstate});
      }
      _onClickShowF2(){
        var newstate = !this.state.prod_show_f2;
        this.setState({prod_show_f2:newstate});
      }
      _onClickShowF3(){
        var newstate = !this.state.prod_show_f3;
        this.setState({prod_show_f3:newstate});
      }
      _onChangeNameF1(e){
        var str = e.target.value;
        this.setState({prod_name_f1: str});
      }
      _onChangeNameF2(e){
        var str = e.target.value;
        this.setState({prod_name_f2: str});
      }
      _onChangeNameF3(e){
        var str = e.target.value;
        this.setState({prod_name_f3: str});
      }
      _onClickShowF1_fr(){
        var newstate = !this.state.prod_show_f1_fr;
        this.setState({prod_show_f1_fr:newstate});
      }
      _onClickShowF2_fr(){
        var newstate = !this.state.prod_show_f2_fr;
        this.setState({prod_show_f2_fr:newstate});
      }
      _onClickShowF3_fr(){
        var newstate = !this.state.prod_show_f3_fr;
        this.setState({prod_show_f3_fr:newstate});
      }
      _onChangeNameF1_fr(e){
        var str = e.target.value;
        this.setState({prod_name_f1_fr: str});
      }
      _onChangeNameF2_fr(e){
        var str = e.target.value;
        this.setState({prod_name_f2_fr: str});
      }
      _onChangeNameF3_fr(e){
        var str = e.target.value;
        this.setState({prod_name_f3_fr: str});
      }
      _onSubmit(e) {
        e.preventDefault();
        
        var form = $('#editconsproduct')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#editconsproduct')[0].reset();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
      }

      _onSubmitF1Form(e) {
        e.preventDefault();
        var form = $('#editconsproductF1')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF2Form(e) {
        e.preventDefault();
        var form = $('#editconsproductF2')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF3Form(e) {
        e.preventDefault();
        var form = $('#editconsproductF3')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF1Form_fr(e) {
        e.preventDefault();
        var form = $('#editconsproductF1_fr')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF2Form_fr(e) {
        e.preventDefault();
        var form = $('#editconsproductF2_fr')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
      _onSubmitF3Form_fr(e) {
        e.preventDefault();
        var form = $('#editconsproductF3_fr')[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
                alert("SUCCESS : " + data);
            },
            error: function (err) {
                alert("ERROR : " + JSON.stringify(err));
            }
        });
      }
  render() {
    var self = this;
    return (
      <div>
          <h5 className="card-title text-center font-weight-bold">Consumer Product Edit</h5>
          <div className="form-group row">
          <label className='col-sm-2 col-form-label font-weight-bold'>Select</label>
          <div className='col-sm-10 col-lg-6'>
          <select id='cprod_select' name='product' className="col-sm-10 col-lg-6 form-control form-control-sm" onClick={self._onClickSel(this.value)}>
            <option>None</option>
            {
              self.props.products.map(thisprod=>{
                return <option>{thisprod.id}</option>
              })
            }
          </select>
          </div>
          </div>

        <form id='editconsproduct' action='/admin/update_consproduct' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1cpe" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameEn} value={self.state.prod_name_en} name='name_en' type="text" className="form-control form-control-sm" id="InputENcpe" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameFr} value={self.state.prod_name_fr} name='name_fr' type="text" className="form-control form-control-sm" id="InputFRcpe" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescEn} value={self.state.prod_desc_en} name='desc_en' className="form-control form-control-sm" id="InputDescENce"  placeholder="Enter English Description" rows="3" required></textarea>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Description</label>
            <div className='col-sm-10 col-lg-6'>
                <textarea onChange={self._onChangeDescFr} value={self.state.prod_desc_fr} name='desc_fr' className="form-control form-control-sm" id="InputDescFRce"  placeholder="Enter French Description" rows="3" required></textarea>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('category')} className="form-check-input" type="radio" name="group" id="exampleRadios1ce" value="category" checked={this.state.prod_membertype === 'category' ? true : ''}/> {/**={this.state.prod_membertype === 'category' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios1ce">
            Category
          </label>
          
        </div>
        <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.categories.map(thiscat=>{
                return <option selected={thiscat.id === self.state.prod_memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <div className="form-group row">
        <div className="form-check col-sm-2">
          <input onClick={self._setmembertype('subcat')} className="form-check-input" type="radio" name="group" id="exampleRadios2ce" value="subcat" checked={this.state.prod_membertype === 'subcat' ? true : ''}/> {/**checked={this.state.prod_membertype === 'subcat' ? true : ''} */}
          <label className="form-check-label font-weight-bold" for="exampleRadios2ce">
            Sub-Category
          </label>
          
        </div>
        <select name='subcat' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.subcategories.map(thiscat=>{
                return <option  selected={thiscat.id === self.state.prod_memberof ? true:false}>{thiscat.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispEN} type="checkbox" name='show_en' className="custom-control-input" id="visiblepENce" checked={this.state.prod_show_en === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepENce">Show Eng</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispFR}  type="checkbox" name='show_fr' className="custom-control-input" id="visiblepFRce" checked={this.state.prod_show_fr === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepFRce">Show Frn</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispCA} type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANce" checked={this.state.prod_show_ca === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepCANce">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispUS}  type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSce" checked={this.state.prod_show_us === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepUSce">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickDispGL}  type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLce" checked={this.state.prod_show_gl === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepGLce">Show Global</label>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickFreeze}  type="checkbox" name='freeze' className="custom-control-input" id="freeze" checked={this.state.prod_freeze === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="freeze">Freeze</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickHazard}  type="checkbox" name='hazard' className="custom-control-input" id="hazard" checked={this.state.prod_hazard === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="hazard">Hazardous</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._onClickEco}  type="checkbox" name='eco' className="custom-control-input" id="eco" checked={this.state.prod_eco === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="eco">Eco Friendly</label>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input type="number" name='sort' className="form-control form-control-sm" id="InputID2ce" aria-describedby="idHelp" placeholder='0' value={this.state.prod_order}/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit Updates</button>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF1' action='/admin/updateConsProdFile1' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF1Form}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef1" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF1}  type="checkbox" name='show_f1' className="custom-control-input" id="showf1e" checked={this.state.prod_show_f1 === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf1e">Show File1</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f1' type="text" className="form-control form-control-sm" id="InputF1NameE" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f1} onChange={this._onChangeNameF1}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file1' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file1}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF2' action='/admin/updateConsProdFile2' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF2Form}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef2" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF2}  type="checkbox" name='show_f2' className="custom-control-input" id="showf2e" checked={this.state.prod_show_f2 === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf2e">Show File2</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f2' type="text" className="form-control form-control-sm" id="InputF2NameE" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f2} onChange={this._onChangeNameF2}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file2' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file2}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF3' action='/admin/updateConsProdFile3' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF3Form}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef3" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF3}  type="checkbox" name='show_f3' className="custom-control-input" id="showf3e" checked={this.state.prod_show_f3 === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf3e">Show File3</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f3' type="text" className="form-control form-control-sm" id="InputF3NameE" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f3} onChange={this._onChangeNameF3}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file3' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file3}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF1_fr' action='/admin/updateConsProdFile1_fr' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF1Form_fr}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef1_fr" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF1_fr}  type="checkbox" name='show_f1_fr' className="custom-control-input" id="showf1e_fr" checked={this.state.prod_show_f1_fr === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf1e_fr">Show File1 Fr</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f1_fr' type="text" className="form-control form-control-sm" id="InputF1NameE_fr" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f1_fr} onChange={this._onChangeNameF1_fr}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file1_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file1_fr}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF2_fr' action='/admin/updateConsProdFile2_fr' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF2Form_fr}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef2_fr" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF2_fr}  type="checkbox" name='show_f2_fr' className="custom-control-input" id="showf2e_fr" checked={this.state.prod_show_f2_fr === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf2e_fr">Show File2 Fr</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f2_fr' type="text" className="form-control form-control-sm" id="InputF2NameE_fr" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f2_fr} onChange={this._onChangeNameF2_fr}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file2_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file2_fr}</div>

        </div>
        </form>
        <hr className='my-1'></hr>
        <form id='editconsproductF3_fr' action='/admin/updateConsProdFile3_fr' enctype="multipart/form-data" method="post" onSubmit={this._onSubmitF3Form_fr}>
        <div className="form-group row">
            <div className='d-none'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1pef3_fr" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly required/>
            </div>
            <div className="custom-control custom-switch custom-control-inline col-lg-2 ml-3 mr-2">
              <input onClick={this._onClickShowF3_fr}  type="checkbox" name='show_f3_fr' className="custom-control-input" id="showf3e_fr" checked={this.state.prod_show_f3_fr === true ? 'checked' : ''}/>
              <label className="custom-control-label" htmlFor="showf3e_fr">Show File3 Fr</label>
            </div>
            <div className='col-lg-2 ml-3 mr-2'>
            <input name='name_f3_fr' type="text" className="form-control form-control-sm" id="InputF3NameE_fr" aria-describedby="frHelp" placeholder="Enter Button Name" value={this.state.prod_name_f3_fr} onChange={this._onChangeNameF3_fr}/>
            </div>
            <div className='col-sm-10 col-lg-4 ml-3 mr-2'>
                <input type="file" name='file3_fr' className="form-control-file form-control-sm" required/>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">Update File</button>
            <div className='col-12 text-bold'>{this.state.prod_file3_fr}</div>

        </div>
        </form>
      </div>
    )
    }
  };