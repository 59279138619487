import React from 'react';
import $ from 'jquery';

export default class ConsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { //set initial states
      active_cat: '',
      active_sub: '',
      active_prod: ''
    };
    this._setActiveCat = this._setActiveCat.bind(this);
    this._setActiveSub = this._setActiveSub.bind(this);
    this._setActiveProd = this._setActiveProd.bind(this);
  }

  _setActiveCat(cat){
    var self = this;
    return function(){

      self.setState({active_cat: cat, active_prod: '', active_sub:''});
      
      $(".prod_link").removeClass("active");
      $(".cprod_panel_cat").removeClass("active");
      $(".csubprod_panel").removeClass("active");

      var temp = $("#pronav" + cat);
      temp.addClass('show active');

      var catindex = self.props.categories.map(function(e) { return e.id; }).indexOf(cat);
      if (self.props.categories[catindex].products.length > 0) {
      if (self.state.active_prod === '') {
        //id={'pronav' + product.id}
        
        if (catindex > -1){
          $('#pronav' + self.props.categories[catindex].products[0].id).addClass('show active');
          self.setState({active_prod: self.props.categories[catindex].products[0].id, active_cat: cat, active_sub: '' });
        } else {
          self.setState({active_prod: '', active_cat: cat, active_sub: '' });
        }
        //$('#pronav' + )

      } else {
        if (catindex > -1){
        var prodindex = self.props.categories[catindex].products.map(function(e) { return e.id; }).indexOf(self.state.active_prod);

        if (prodindex < 0) {
          $(".cprod_panel").removeClass("active");
          $('#pronav' + self.props.categories[catindex].products[0].id).addClass('show active');
          self.setState({active_prod: self.props.categories[catindex].products[0].id, active_cat: cat, active_sub: '' });
        } else {
          self.setState({active_cat: cat, active_sub: '' });
        }
        } else {
          self.setState({active_cat: cat, active_sub: '' });
        }
      }
      } else {
        self.setState({active_cat: cat, active_sub: '' });
      }

      /* setTimeout(function(){
        window.scrollBy(0, -5);
        setTimeout(function(){
          window.scrollBy(0, 4);
        },10); 
    },50);*/

    }
  }
  _setActiveSub(cat){
    var self = this;
    return function(){
      if (self.state.active_sub === cat){

      } else {
        $(".prod_link").removeClass("active");
        $(".cprod_panel").removeClass("active");
        //$(".subprod_panel").removeClass("active");
        self.setState({active_sub: cat, active_prod: ''});

        var catindex = self.props.categories.map(function(e) { return e.id; }).indexOf(self.state.active_cat);
        var subindex = self.props.categories[catindex].subcategorie.map(function(e) { return e.id; }).indexOf(cat);
        
        if (self.state.active_prod === '') {
          $('#pronav' + self.props.categories[catindex].subcategorie[subindex].products[0].id).addClass('show active');
          self.setState({active_prod: self.props.categories[catindex].subcategorie[subindex].products[0].id, active_sub: cat });
        } else {
          var prodindex = self.props.categories[catindex].subcategorie[subindex].products.map(function(e) { return e.id; }).indexOf(self.state.active_prod);
          if (prodindex < 0) {
            $(".csubprod_panel").removeClass("active");
            $('#pronav' + self.props.categories[catindex].subcategorie[subindex].products[0].id).addClass('show active');
            self.setState({active_prod: self.props.categories[catindex].subcategorie[subindex].products[0].id, active_sub: cat });
          } else {

          }
        }

      }
      
      
      /*
      self.setState({active_sub: cat, active_prod: ''});
      $(".prod_link").removeClass("active");
      setTimeout(function(){
        window.scrollBy(0, -5);
        setTimeout(function(){
          window.scrollBy(0, 4);
        },10);
    },50);
    */
    }
  }
  _setActiveProd(prod){
    var self = this;
    return function(){
      self.setState({active_prod: prod});
    /*  
      setTimeout(function(){
        window.scrollBy(0, -5);
        setTimeout(function(){
          window.scrollBy(0, 4);
        },10);
    },50); */
    }
  }
  render() {
    var self = this;
    return (
      <div className='nav flex-column border-right pr-0 font-weight-bold text-right'>
          <div className='accordion' id='consMenuAccordion'>
              {
              this.props.categories.map(categorie =>
              <div key={categorie.id}>
                <a className={"border-bottom nav-link link_style " + (self.state.active_cat === categorie.id ? 'active': '')} onClick={self._setActiveCat(categorie.id)} data-toggle="collapse" data-target={'#' + categorie.id + 'col0'} href={"#pronav" + categorie.id} style={{color:'black',fontSize:'18px'}}>{this.props.lang === 'EN' ? categorie.name_en : categorie.name_fr}</a>
               {// <div className={'border-bottom collapse ' + (self.state.active_cat === categorie.id ? 'show': '')} id={categorie.id + 'col0'} data-parent='#consMenuAccordion'>
               } {
                  categorie.products.length > 0 ? 
                  <div className='border-bottom collapse' id={categorie.id + 'col0'} data-parent='#consMenuAccordion'>
                      <div className='nav flex-column pr-0 text-right font-weight-normal'>
                      {
                      categorie.products.map(product => 
                        ((self.props.lang === 'EN') && (product.show_en)) ?
                      <a key={product.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>
                      : ((self.props.lang === 'FR') && (product.show_fr)) ? <a key={product.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>
                      : <div></div>
                      )
                      }
                      </div>
                    </div> : <div></div>
                  }
                  {
                    categorie.subcategorie.length > 0 ? 
                    <div className={'border-bottom collapse'} id={categorie.id + 'col0'} data-parent='#consMenuAccordion'>
                      <div className='accordion' id={'consSubcatAccordion' + categorie.id}>
                        {
                          categorie.subcategorie.map(subcat => <div key={subcat.id}>
                            <a className={"border-bottom pr-2 nav-link link_style " + (self.state.active_sub === subcat.id ? 'active': '')} onClick={self._setActiveSub(subcat.id)} data-toggle="collapse" data-target={'#' + subcat.id + 'col3'} href={"#pronav" + categorie.id} style={{color:'black', fontSize:'15px'}}><span className='pr-2'>{this.props.lang === 'EN' ? subcat.name_en : subcat.name_fr}</span></a>
                            {
                              subcat.products.length > 0 ? 
                              <div className={'border-bottom collapse ' + (self.state.active_sub === subcat.id ? 'show': '')} data-parent={'#consSubcatAccordion' + categorie.id} id={subcat.id + 'col3'}>
                                <div className='nav flex-column pr-0 text-right font-weight-normal'>
                                {
                                subcat.products.map(product => 
                                  ((self.props.lang === 'EN') && (product.show_en)) ?
                                <a key={product.id + ' ' + subcat.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>
                                : ((self.props.lang === 'FR') && (product.show_fr)) ?  <a key={product.id + ' ' + subcat.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>
                                : <div></div>
                                )
                                }
                                </div>
                              </div>  : <div></div>
                            }
                          </div>)
                        }
                      </div>
                    </div> : <div></div>



                    /*
                  categorie.subcategorie.length > 0 ? <div className='accordion' id={'consSubcatAccordion' + categorie.id}>
                    {
                    categorie.subcategorie.map(subcat =>
                       <div key={subcat.id} className={'border-bottom collapse ' + (self.state.active_cat === categorie.id ? 'show': '')}> 
                        <a className={"border-bottom pr-2 nav-link link_style " + (self.state.active_sub === subcat.id ? 'active': '')} onClick={self._setActiveSub(subcat.id)} data-toggle="collapse" data-target={'#' + subcat.id + 'col3'} href={"#pronav" + categorie.id} style={{color:'black', fontSize:'15px'}}><span className='pr-2'>{this.props.lang === 'EN' ? subcat.name_en : subcat.name_fr}</span></a>
                        {
                        subcat.products.length > 0 ? 
                        <div className={'border-bottom collapse ' + (self.state.active_sub === subcat.id ? 'show': '')} data-parent={'#consSubcatAccordion' + categorie.id} id={subcat.id + 'col3'}>
                            <div className='nav flex-column pr-0 text-right font-weight-normal'>
                            {
                            subcat.products.map(product => <a key={product.id + ' ' + subcat.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>)
                            }
                            </div>
                          </div> : <div></div>
                        }
                       </div>
                      )
                    }
                  </div> : <div></div>
                  */
                  }
                {//  </div>
                }
              </div>)
              }

            </div>
         {   /*
        this.props.categories.map(categorie => 
          <div key={categorie.id}>
          <a className={"border-bottom nav-link link_style " + (self.state.active_cat === categorie.id ? 'active': '')} onClick={self._setActiveCat(categorie.id)} data-toggle="tab" href={"#pronav" + categorie.id} style={{color:'black',fontSize:'18px'}}>{this.props.lang === 'EN' ? categorie.name_en : categorie.name_fr}</a>
          {categorie.products.length > 0 ? 
          <div className={'border-bottom collapse' + (self.state.active_cat === categorie.id ? 'show': '')} >
              <div className='nav flex-column px-0 text-right font-weight-normal'>
              {
              categorie.products.map(product => <a key={product.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_cat === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>)
              }
              </div>
            </div> : <div></div>
          }
          {
            categorie.subcategorie.length > 0 ? categorie.subcategorie.map(subcat => <div key={subcat.id} className={'border-bottom collapse' + (self.state.active_cat === categorie.id ? 'show': '')} >
                <a className={"border-bottom nav-link link_style " + (self.state.active_sub === subcat.id ? 'active': '')} onClick={self._setActiveSub(subcat.id)} data-toggle="tab" href={"#pronav" + categorie.id} style={{color:'black'}}>{this.props.lang === 'EN' ? subcat.name_en : subcat.name_fr}</a>
                {subcat.products.length > 0 ? 
          <div className={'border-bottom collapse' + (self.state.active_sub === subcat.id ? 'show': '')} >
              <div className='nav flex-column pr-0 text-right font-weight-normal'>
              {
             subcat.products.map(product => <a key={product.id + ' ' + subcat.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_cat === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>)
              }
              </div>
            </div> : <div></div>
          }
            </div>) : <div></div>
          }
          </div>
        )
          */
        }
      </div>
    )
  }
};


/**
 * BACKUP FROM APR 28, 2020
 * 
{
              this.props.categories.map(categorie => 
              <div key={categorie.id}>
                <a className={"border-bottom nav-link link_style " + (self.state.active_cat === categorie.id ? 'active': '')} onClick={self._setActiveCat(categorie.id)} data-toggle="collapse" data-target={'#' + categorie.id + 'col0'} href={"#pronav" + categorie.id} style={{color:'black',fontSize:'18px'}}>{this.props.lang === 'EN' ? categorie.name_en : categorie.name_fr}</a>
                <div className={'border-bottom collapse ' + (self.state.active_cat === categorie.id ? 'show': '')} id={categorie.id + 'col0'} data-parent='#consMenuAccordion'>
                {
                  categorie.products.length > 0 ? 
                  <div className={'border-bottom collapse ' + (self.state.active_cat === categorie.id ? 'show': '')}>
                      <div className='nav flex-column pr-0 text-right font-weight-normal'>
                      {
                      categorie.products.map(product => <a key={product.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>)
                      }
                      </div>
                    </div> : <div></div>
                  }
                  {
                  categorie.subcategorie.length > 0 ? <div className='accordion' id={'consSubcatAccordion' + categorie.id}>
                    {
                    categorie.subcategorie.map(subcat =>
                       <div key={subcat.id} className={'border-bottom collapse ' + (self.state.active_cat === categorie.id ? 'show': '')}> 
                        <a className={"border-bottom pr-2 nav-link link_style " + (self.state.active_sub === subcat.id ? 'active': '')} onClick={self._setActiveSub(subcat.id)} data-toggle="collapse" data-target={'#' + subcat.id + 'col3'} href={"#pronav" + categorie.id} style={{color:'black', fontSize:'15px'}}><span className='pr-2'>{this.props.lang === 'EN' ? subcat.name_en : subcat.name_fr}</span></a>
                        {
                        subcat.products.length > 0 ? 
                        <div className={'border-bottom collapse ' + (self.state.active_sub === subcat.id ? 'show': '')} data-parent={'#consSubcatAccordion' + categorie.id} id={subcat.id + 'col3'}>
                            <div className='nav flex-column pr-0 text-right font-weight-normal'>
                            {
                            subcat.products.map(product => <a key={product.id + ' ' + subcat.id} id={product.id} className={'nav-link prod_link link_style ' + (self.state.active_prod === product.id ? 'active': '')} onClick={self._setActiveProd(product.id)} data-toggle="tab" href={'#pronav' + product.id} style={{color:'black'}}>{this.props.lang === 'EN' ? product.name_en : product.name_fr}</a>)
                            }
                            </div>
                          </div> : <div></div>
                        }
                       </div>
                      )
                    }
                  </div> : <div></div>
                  }
                  </div>
              </div>)
              }


 * 
 */