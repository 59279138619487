import React from 'react';

import AdminGalleryItem from './AdminGalleryItem';

import $ from 'jquery';

export default class AdminGalleryEdit extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      galleryImages:[{id: 'none'}]
    }
    this._getGalleryImages = this._getGalleryImages.bind(this);
  }
  componentDidMount() {
    this._getGalleryImages();
  }
  _getGalleryImages() {
    var self = this;
    console.log('Getting Gallery List');
    $.get('/data/gallery',function(data, status, xhr){
      console.log('Get Gallery status: ' + status);
      console.log('Gallery Data: ' + JSON.stringify(data));
      if (data.length > 0) {
        console.log('Update Gallery Image List');
        self.setState({galleryImages: data});
      }
    },'json');
  }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));
    var self = this;
    return (
      <div>
        <h5 className="card-title text-center font-weight-bold">Gallery Edit</h5>
        <hr className='my-1'></hr>
        <table class="table table-sm table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Sort</th>
                    <th scope="col">Thumb</th>
                    <th scope="col">Image</th>
                    <th scope="col">Visible</th>
                </tr>
                </thead>
                <tbody>
                    {
                        this.state.galleryImages.map(thisimage => 
                            <AdminGalleryItem image={thisimage} _getGalleryImages={self._getGalleryImages}/>    
                        )
                    }
                </tbody>
            </table>

        <hr className='my-1'></hr>
      </div>
    )
    }
  };