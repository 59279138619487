import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import $ from 'jquery';

export default class AdminChartsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          sort: 0,
          name_en: '',
          name_fr: '',
          display_en: 'on',
          display_fr: '',
          disclaimer: false
        };
        this._getChartData = this._getChartData.bind(this);
        this._unlockItem = this._unlockItem.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);
        this._updateSort = this._updateSort.bind(this);
        this._updateNameEn = this._updateNameEn.bind(this);
        this._updateNameFr = this._updateNameFr.bind(this);
        this._updateDispEn = this._updateDispEn.bind(this);
        this._updateDispFr = this._updateDispFr.bind(this);
        this._updateDisclaimer = this._updateDisclaimer.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
      }
    
      componentDidMount() {
      //  this._getChartData();
      }
    
      _getChartData(){
        var self = this;
      //  console.log('Getting Chart Data');
        $.get('/data/charts',function(data,status,xhr){
      //    console.log('Chart Data Status: ' + status + ' data: ' + JSON.stringify(data));
          if (data.length > 0) {
            self.setState({colorCharts: data});
          }
        },'json')  
      }
      _unlockItem(){
        this.setState({
          locked:false,
          sort: this.props.chart.sort,
          name_en: this.props.chart.name_en,
          name_fr: this.props.chart.name_fr,
          display_en: this.props.chart.display_en,
          display_fr: this.props.chart.display_fr,
          disclaimer: this.props.chart.disclaimer
        });
      }
      _lockItem(){
        this.setState({locked:true});
      }
      _saveItem(){
        this.setState({locked:true});
      }
      _updateSort(e){
        var str = e.target.value;
        this.setState({sort:str});
      }
      _updateNameEn(e){
        var str = e.target.value;
        this.setState({name_en:str});
      }
      _updateNameFr(e){
        var str = e.target.value;
        this.setState({name_fr:str});
      }
      _updateDispEn(){
       // var str;// = e.target.value;
       // console.log('str en: ' + str);
        if (this.state.display_en === true) {
          this.setState({display_en: false});
        } else {
          this.setState({display_en: true});
        }
      }
      _updateDispFr(){
       // var str = e.target.value;
      //  console.log('str fr: ' + str);
        if (this.state.display_fr === true) {
          this.setState({display_fr: false});
        } else {
          this.setState({display_fr: true});
        }
      }
      _updateDisclaimer(){
        if (this.state.disclaimer === true) {
          this.setState({disclaimer: false});
        } else {
          this.setState({disclaimer: true});
        }
      }
      _onSubmit(e) {
        e.preventDefault();
        
        var self = this;
        var form = $("#form" + this.props.chart.id)[0];  //
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
              self.setState({locked:true});
            // $('#chartcreate')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
    }
      
  render() {
    return (
        this.state.locked ? <tr key={this.props.chart.id}>
              <td>{this.props.chart.id}</td>
              <td>{this.props.chart.sort}</td>
              <td>{this.props.chart.name_en}</td>
              <td>{this.props.chart.name_fr}</td>
              <td>{this.props.chart.image_en}</td>
              <td>{this.props.chart.image_fr}</td>
              <td>{this.props.chart.pdf_en}</td>
              <td>{this.props.chart.pdf_fr}</td>
              <td>{this.props.chart.display_en ? 'true':'false'}</td>
              <td>{this.props.chart.display_fr ? 'true':'false'}</td>
              <td>{this.props.chart.disclaimer ? 'true':'false'}</td>
              <td>{this.props.chart.author}</td>
              <td>{this.props.chart.created_on}</td>
              <td>{this.props.chart.edited_on}</td>
              <td className='d-flex justify-content-between'>
              <button className="btn btn-sm btn-outline-success" onClick={this._unlockItem}><i className='fas fa-lock' style={{color:'green', cursor:'pointer'}}></i></button>
              <button className="btn btn-sm btn-outline-danger" ><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
          </tr> : <tr>
            
              <td><input type="text" name="id" form={"form" + this.props.chart.id} readonly class="form-control-plaintext" value={this.props.chart.id}/></td>
              <td><input type="number" name="sort" form={"form" + this.props.chart.id} className='form-control px-1' value={this.state.sort} onChange={this._updateSort}/></td>
              <td><input type="text" name="name_en" form={"form" + this.props.chart.id} className='form-control px-1' value={this.state.name_en} onChange={this._updateNameEn}/></td>
              <td><input type="text" name="name_fr" form={"form" + this.props.chart.id} className='form-control px-1' value={this.state.name_fr} onChange={this._updateNameFr}/></td>
              <td><input type="file" name='image_en' form={"form" + this.props.chart.id} className="form-control-file"/></td>
              <td><input type="file" name='image_fr' form={"form" + this.props.chart.id} className="form-control-file"/></td>
              <td><input type="file" name='pdf_en' form={"form" + this.props.chart.id} className="form-control-file"/></td>
              <td><input type="file" name='pdf_fr' form={"form" + this.props.chart.id} className="form-control-file"/></td>
              <td>
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='display_en' className="custom-control-input" id="visibleEng2" form={"form" + this.props.chart.id} onClick={this._updateDispEn} checked={this.state.display_en === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleEng2">Eng</label>
                </div>
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='display_fr' className="custom-control-input" id="visibleFr2" form={"form" + this.props.chart.id} onClick={this._updateDispFr} checked={this.state.display_fr === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="visibleFr2">Fr</label>
              </div>
              
              </td>
              <td>
                
              <div className="custom-control custom-switch custom-control-inline">
                  <input type="checkbox" name='disclaimer' className="custom-control-input" id="disclaimer2" form={"form" + this.props.chart.id} onClick={this._updateDisclaimer} checked={this.state.disclaimer === true ? 'checked' : ''}/>
                  <label className="custom-control-label" htmlFor="disclaimer2">Dis</label>
              </div>
              
              </td>
              <td>{this.props.chart.author}</td>
              <td>{this.props.chart.created_on}</td>
              <td>{this.props.chart.edited_on}</td>
              <td className='d-flex justify-content-between'>
                <button className="btn btn-sm btn-outline-success" onClick={this._lockItem}><i className='fas fa-lock-open' style={{color:'green', cursor:'pointer'}}></i></button>
                <form id={"form" + this.props.chart.id} action='/admin/update_chart' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
                  <button type="submit" className="btn btn-sm btn-outline-success"><i className='fas fa-save' style={{color:'green'}}></i></button>
                </form>
                <button className="btn btn-sm btn-outline-danger"><i className='fas fa-trash-alt' style={{color:'red', cursor:'pointer'}}></i></button>
              </td>
            
          </tr>
    )
    }
  };