import React from 'react';
//import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
import AdminProCatEdit from './AdminProCatEdit';
import AdminProSubCatEdit from './AdminProSubCatEdit';
import AdminProProductEdit from './AdminProProductEdit';

export default class AdminProEdit extends React.Component {
  componentDidMount() {
    
  }
  render() {
    //console.log('Render Pro Create: Props: ' + JSON.stringify(this.props));

    return (
      <div>
            <ul className="nav nav-pills mb-3" id="pills-tabC" role="tablist">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#dropdown" role="button" aria-haspopup="true" aria-expanded="false">Select Type</a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" id="pills-category-tab-e" data-toggle="pill" href="#pills-category-e" role="tab" aria-controls="pills-category" aria-selected="false">Category</a>
                    <a className="dropdown-item" id="pills-subcat-tab-e" data-toggle="pill" href="#pills-subcat-e" role="tab" aria-controls="pills-subcat" aria-selected="false">Sub Category</a>
                    <a className="dropdown-item" id="pills-product-tab-e" data-toggle="pill" href="#pills-product-e" role="tab" aria-controls="pills-product" aria-selected="false">Product</a>
                  </div>
                </li>
            </ul>
            <div className="tab-content" id="pills-tabCContent">
                <div className="tab-pane fade" id="pills-category-e" role="tabpanel" aria-labelledby="pills-category-tab"><AdminProCatEdit categories={this.props.categories}/></div>
                <div className="tab-pane fade" id="pills-subcat-e" role="tabpanel" aria-labelledby="pills-subcat-tab"><AdminProSubCatEdit {...this.props}/></div>
                <div className="tab-pane fade" id="pills-product-e" role="tabpanel" aria-labelledby="pills-product-tab"><AdminProProductEdit {...this.props}/></div>
              
            </div>
      </div>
    )
    }
  };