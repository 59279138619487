const consProducts = {
    categories:[ 
      /*  {
            id:1,
            name_en:'Pigments',
            name_fr:'Pigmentsfr',
            active_ca: true,
            subcategorie:[
            ],
            products:[
                {
                    id:'prod1',
                    order: 1,
                    name_en:'Ready Mix Color',
                    name_fr:'Ready Mix Color FR',
                    desc_en:"Interstar Ready Mix Colors are pure iron oxide pigments designed specifically for the integral coloring of cementitious materials. Interstar's pigments are certified ASTM C979 for integral coloring. The swatches shown here represent a 1-bag mix (1 bag of pigment per cubic yard/meter).",
                    desc_fr:'french description',
                    show_ca: true,
                    show_us: true,
                    show_gl: true,
                    skus:[
                        {
                            id:'BL3212R',
                            name_en:'Silver Diamond',
                            name_fr:'Silver Diamond',
                            weight_m: 7.8,
                            weight_i: 17,
                            price_cad: 262.45,
                            price_usd: 190.00,
                            show_ca:true,
                            show_us:true,
                            sale_ca:true,
                            sale_us:false,
                            sale_gl:false,
                            img: 'BL3212R.jpg'
                        },
                        {
                            id:'BN1616R',
                            name_en:'Ready Mix Color - Plum RM Color',
                            name_fr:'Ready Mix Color - Plum RM Color',
                            weight_m: 7.8,
                            weight_i: 17,
                            price_cad: 92.95,
                            price_usd: 80,
                            show_ca:true,
                            show_us:true,
                            sale_ca:true,
                            sale_us:false,
                            sale_gl:false,
                            img: 'BN1616R.jpg'
                        },
                        {
                            id:'BN1701R',
                            name_en:'Taupe',
                            name_fr:'Taupe',
                            weight_m: 7.8,
                            weight_i: 17,
                            price_cad: 92.95,
                            price_usd: 80,
                            show_ca:true,
                            show_us:true,
                            sale_ca:false,
                            sale_us:false,
                            sale_gl:false,
                            img: 'BN1701R.jpg'
                        }
                    ]
                   },
                   {id:'prod2', name_en:'prod2',name_fr:'prod2fr',desc_en:'',desc_fr:'',skus:[]}
            ]
        },
        {
            id:2,
            name_en:'categorie2',
            name_fr:'categorie2fr',
            active_ca: true,
            subcategorie:[
                 {
                    id:'1',
                    name_en:'subcat_name',
                    name_fr:'subcat_namefr',
                    order: 1,
                    show_ca: true,
                    show_us: true,
                    show_gl: true,
                    products: [{id:'prod3', name_en:'prod3',name_fr:'prod3fr',desc_en:'eng desc',desc_fr:'french desc',skus:[]},{skus:[],id:'prod4', name_en:'prod4',name_fr:'prod4fr',desc_en:'',desc_fr:''}]
                  },
                  {
                    id:'2',
                    name_en:'subcat_name2',
                    name_fr:'subcat_name2fr',
                    order: 2,
                    show_ca: true,
                    show_us: true,
                    show_gl: true,
                    products: [{id:'prod5', name_en:'prod5',name_fr:'prod5fr',desc_en:'eng desc',desc_fr:'french desc',skus:[]},{skus:[],id:'prod6', name_en:'prod6',name_fr:'prod6fr',desc_en:'',desc_fr:''}]
                  }
            ],
            products: [ ]
        },
        {
            id:3,
            name_en:'categorie3',
            name_fr:'categorie3fr',
            active_ca: true,
            subcategorie:[
                {
                    id:'1',
                    name_en:'subcat_name',
                    name_fr:'subcat_namefr',
                    order: 1,
                    show_ca: true,
                    show_us: true,
                    show_gl: true,
                    products: [{id:'prod7', name_en:'prod7',name_fr:'prod7fr',desc_en:'eng desc',desc_fr:'french desc',skus:[]},{skus:[],id:'prod8', name_en:'prod8',name_fr:'prod8fr',desc_en:'',desc_fr:''}]
                  },
                  {
                    id:'2',
                    name_en:'subcat_name2',
                    name_fr:'subcat_name2fr',
                    order: 2,
                    show_ca: true,
                    show_us: true,
                    show_gl: true,
                    products: [{id:'prod9', name_en:'prod9',name_fr:'prod9fr',desc_en:'eng desc',desc_fr:'french desc',skus:[]},{skus:[],id:'prod10', name_en:'prod10',name_fr:'prod10fr',desc_en:'',desc_fr:''}]
                  }
            ],
            products:[
                {
                    id:'prod11',
                    order: 1,
                    name_en:'Granastar Granular Pigment',
                    name_fr:'prod11fr',
                    desc_en:'brief english description of the product and what it does. Adding some more text to make the line wrap so can see what a full paragraph will look like.',
                    desc_fr:'french description',
                    show_ca: true,
                    show_us: true,
                    show_gl: true,
                    show_colorchart: true,
                    show_brochure: false,
                    show_instructions: false,
                    show_productinfo: true,
                    show_overview: false,
                    chart_en: '',
                    chart_fr: '',
                    brochure_en: '',
                    brochure_fr: '',
                    instructions_en: '',
                    instructions_fr: '',
                    productinfo_en: '',
                    productinfo_fr: '',
                    overview_en: '',
                    overview_fr: '',
                    skus:[]
                   },
                   {id:'prod12', name_en:'prod12',name_fr:'prod12fr',desc_en:'',desc_fr:'',skus:[]}
            ]
        } */
    ]
}

export default consProducts;