import React from 'react';
import $ from 'jquery';

export default class AdminConsSkuModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { //set initial states
          locked: true,
          prod_id: '',
            prod_name_en: '',
            prod_name_fr: '',
            prod_product: '',
            prod_shopifybtn: '',
            prod_show_ca: true,
            prod_show_us: true,
            prod_show_gl: true,
            prod_sale_ca: true,
            prod_sale_us: true,
            prod_sale_gl: true,
            prod_price_cad:0,
            prod_price_usd:0,
            prod_ship_weight:0,
            prod_order: 0,
            prod_var_opt: false,
            prod_name_v1: '',
            prod_name_v2: '',
            prod_name_v1_fr: '',
            prod_name_v2_fr: '',
            prod_var_id: '',
            prod_shopifybtn_v: '',
            prod_price_cad_v: 0,
            prod_price_usd_v: 0,
            prod_ship_weight_v: 0

        };
        this._toggleLock = this._toggleLock.bind(this);
        this._lockItem = this._lockItem.bind(this);
        this._saveItem = this._saveItem.bind(this);
        this._onChangeNameEn = this._onChangeNameEn.bind(this);
        this._onChangeNameFr = this._onChangeNameFr.bind(this);
        this._onChangeshopify = this._onChangeshopify.bind(this);
        this._ChangeOrder = this._ChangeOrder.bind(this);
        this._toggle_show_ca = this._toggle_show_ca.bind(this);
        this._toggle_show_us = this._toggle_show_us.bind(this);
        this._toggle_show_gl = this._toggle_show_gl.bind(this);
        this._toggle_sale_ca = this._toggle_sale_ca.bind(this);
        this._toggle_sale_us = this._toggle_sale_us.bind(this);
        this._toggle_sale_gl = this._toggle_sale_gl.bind(this);
        this._onChangePriceC = this._onChangePriceC.bind(this);
        this._onChangePriceU = this._onChangePriceU.bind(this);
        this._onChangeShip = this._onChangeShip.bind(this);
        this._toggle_var_opt = this._toggle_var_opt.bind(this);
        this._onChangeNameV1 = this._onChangeNameV1.bind(this);
        this._onChangeNameV2 = this._onChangeNameV2.bind(this);
        this._onChangeNameV1fr = this._onChangeNameV1fr.bind(this);
        this._onChangeNameV2fr = this._onChangeNameV2fr.bind(this);
        this._onChangeVSKU = this._onChangeVSKU.bind(this);
        this._onChangeshopifyV = this._onChangeshopifyV.bind(this);
        this._onChangePriceCV = this._onChangePriceCV.bind(this);
        this._onChangePriceUV = this._onChangePriceUV.bind(this);
        this._onChangeShipV = this._onChangeShipV.bind(this);
        this._onSubmit = this._onSubmit.bind(this);


      }
      componentDidMount(){
     /*   var idsplice = this.props.thisprod.id.split(' ');
        $(document).ready(function(){
            $(".show").click(function(){
                $("ProProdModal" + idsplice[0]).modal({
                    backdrop: 'static',
                    keyboard: false
                });
            });
        }); */
      }
      _toggleLock(){
        var self = this;
        let newlock = !this.state.locked;
        if (this.state.locked) {
            self.setState({
                locked: newlock,
                prod_id: self.props.thisprod.id,
                prod_name_en: self.props.thisprod.name_en,
                prod_name_fr: self.props.thisprod.name_fr,
                prod_product: self.props.thisprod.product,
                prod_shopifybtn: self.props.thisprod.shopifybtn,
                prod_show_ca: self.props.thisprod.show_ca,
                prod_show_us: self.props.thisprod.show_us,
                prod_show_gl: self.props.thisprod.show_gl,
                prod_sale_ca: self.props.thisprod.sale_ca,
                prod_sale_us: self.props.thisprod.sale_us,
                prod_sale_gl: self.props.thisprod.sale_gl,
                prod_order: self.props.thisprod.order,
                prod_price_cad: self.props.thisprod.price_cad,
                prod_price_usd: self.props.thisprod.price_usd,
                prod_ship_weight: self.props.thisprod.ship_weight,
                prod_var_opt: self.props.thisprod.var_opt,
                prod_name_v1: self.props.thisprod.name_v1,
                prod_name_v2: self.props.thisprod.name_v2,
                prod_name_v1_fr: self.props.thisprod.name_v1_fr,
                prod_name_v2_fr: self.props.thisprod.name_v2_fr,
                prod_var_id: self.props.thisprod.var_id,
                prod_shopifybtn_v: self.props.thisprod.shopifybtn_v,
                prod_price_cad_v: self.props.thisprod.price_cad_v,
                prod_price_usd_v: self.props.thisprod.price_usd_v,
                prod_ship_weight_v: self.props.thisprod.ship_weight_v
            });
        } else {
            this.setState({locked:newlock});
        }
      }
      _lockItem(){

      }
      _saveItem(){

      }
      _ChangeOrder(e){
        var str = e.target.value;
        this.setState({prod_order: str});
      }

      _onChangeNameEn(e){
        var str = e.target.value;
        this.setState({prod_name_en: str});
      }

      _onChangeNameFr(e){
        var str = e.target.value;
        this.setState({prod_name_fr: str});
      }
      _onChangeshopify(e){
        var str = e.target.value;
        this.setState({prod_shopifybtn: str});
      }
      _onChangePriceC(e){
        var str = e.target.value;
        this.setState({prod_price_cad: str});
      }

      _onChangePriceU(e){
        var str = e.target.value;
        this.setState({prod_price_usd: str});
      }

      _onChangeShip(e){
        var str = e.target.value;
        this.setState({prod_ship_weight: str});
      }

      _setmembertype(type) {
        var self = this;
        return function(){
          console.log('Set membertype: ' + type);
          self.setState({prod_membertype: type});
        }
      }

      _toggle_show_ca(){
        let newstate = !this.state.prod_show_ca;
        this.setState({prod_show_ca: newstate});
      }
      _toggle_show_us(){
        let newstate = !this.state.prod_show_us;
        this.setState({prod_show_us: newstate});
      }
      _toggle_show_gl(){
        let newstate = !this.state.prod_show_gl;
        this.setState({prod_show_gl: newstate});
      }
      _toggle_sale_ca(){
        let newstate = !this.state.prod_sale_ca;
        this.setState({prod_sale_ca: newstate});
      }
      _toggle_sale_us(){
        let newstate = !this.state.prod_sale_us;
        this.setState({prod_sale_us: newstate});
      }
      _toggle_sale_gl(){
        let newstate = !this.state.prod_sale_gl;
        this.setState({prod_sale_gl: newstate});
      }
      _toggle_var_opt(){
        let newstate = !this.state.prod_var_opt;
        this.setState({prod_var_opt: newstate});
      }
      _onChangeNameV1(e){
        var str = e.target.value;
        this.setState({prod_name_v1: str});
      }
      _onChangeNameV2(e){
        var str = e.target.value;
        this.setState({prod_name_v2: str});
      }
      _onChangeNameV1fr(e){
        var str = e.target.value;
        this.setState({prod_name_v1_fr: str});
      }
      _onChangeNameV2fr(e){
        var str = e.target.value;
        this.setState({prod_name_v2_fr: str});
      }
      _onChangeVSKU(e){
        var str = e.target.value;
        this.setState({prod_var_id: str});
      }
      _onChangeshopifyV(e){
        var str = e.target.value;
        this.setState({prod_shopifybtn_v: str});
      }
      _onChangePriceCV(e){
        var str = e.target.value;
        this.setState({prod_price_cad_v: str});
      }

      _onChangePriceUV(e){
        var str = e.target.value;
        this.setState({prod_price_usd_v: str});
      }

      _onChangeShipV(e){
        var str = e.target.value;
        this.setState({prod_ship_weight_v: str});
      }
      _onSubmit(e) {
        var self = this;
        e.preventDefault();
        
        var form = $('#update_consskum' + self.props.thisprod.id)[0];
        var data = new FormData(form);
        var actionurl = e.currentTarget.action;
    
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: actionurl,
            data: data,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 30000,
            success: function (data) {
            // $("#result").text(data);
                alert("SUCCESS : " + data);
            //  $("#btnSubmit").prop("disabled", false);
            $('#update_consskum' + self.props.thisprod.id)[0].reset();
            self._toggleLock();
            //$('#contactForm')[0].reset();
            },
            error: function (err) {
            // $("#result").text(e.responseText);
                alert("ERROR : " + JSON.stringify(err));
            //  $("#btnSubmit").prop("disabled", false);
            }
        });
      }


    render(){
        var idsplice = this.props.thisprod.id.split(' ');
        var self = this;
        return (
                <div className="modal fade" id={"ConsSkuModal" + idsplice[0]} tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.thisprod.id}</h5> 
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-control custom-switch custom-control-inline">
                                <input onClick={this._toggleLock} type="checkbox" className="custom-control-input" id={"prod_lockS"+ idsplice[0]} checked={this.state.locked === true ? 'checked' : ''}/>
                                <label className="custom-control-label" htmlFor={"prod_lockS"+ idsplice[0]}>{this.state.locked ? 'Unlock' : 'Lock'}</label>
                            </div>
                            {this.state.locked ? <div>
                                <form>
                                    <div className="form-group row mb-1">
                                        <label for={"nameEnS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">English Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"nameEnS" + idsplice[0]} value={this.props.thisprod.name_en}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"nameFrS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">French Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"nameFrS" + idsplice[0]} value={this.props.thisprod.name_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"shopifyBtn" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Shopify Button: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"shopifyBtn" + idsplice[0]} value={this.props.thisprod.shopifybtn}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"prodPS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Product: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"prodPS" + idsplice[0]} value={this.props.thisprod.product}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showCAppeS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible Can: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showCAppeS" + idsplice[0]} value={this.props.thisprod.show_ca ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showUSppeS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible US: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showUSppeS" + idsplice[0]} value={this.props.thisprod.show_us ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"showGLppeS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Visible GL: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"showGLppeS" + idsplice[0]} value={this.props.thisprod.show_gl ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"saleppeS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Sale CAN: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"saleppeS" + idsplice[0]} value={this.props.thisprod.sale_ca ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"saleuppeS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Sale USA: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"saleuppeS" + idsplice[0]} value={this.props.thisprod.sale_us ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"salegppeS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Sale Global: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"salegppeS" + idsplice[0]} value={this.props.thisprod.sale_gl ? 'True': 'False'}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"priceCPS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Price CAN: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"priceCPS" + idsplice[0]} value={'$' + this.props.thisprod.price_cad}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"priceUSPS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Price USD: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"priceUSPS" + idsplice[0]} value={ '$' + this.props.thisprod.price_usd}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"shipPS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Ship Weight: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"shipPS" + idsplice[0]} value={this.props.thisprod.ship_weight + ' lbs'}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"imgPS" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Image: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"imgPS" + idsplice[0]} value={this.props.thisprod.img}/>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-1">
                                        <label for={"VarienteSm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Varient: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"VarienteSm" + idsplice[0]} value={this.props.thisprod.var_opt ? 'True': 'False'}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"name_v1Sm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Primary Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"name_v1Sm" + idsplice[0]} value={this.props.thisprod.name_v1}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"name_v2Sm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Varient Name: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"name_v2Sm" + idsplice[0]} value={this.props.thisprod.name_v2}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"name_v1Smf" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Primary Name FR: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"name_v1Smf" + idsplice[0]} value={this.props.thisprod.name_v1_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"name_v2Smf" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Varient Name FR: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"name_v2Smf" + idsplice[0]} value={this.props.thisprod.name_v2_fr}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"VarienteSKUSm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Varient SKU: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"VarienteSKUSm" + idsplice[0]} value={this.props.thisprod.var_id}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"shopifyBtnm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Shopify ButtonV: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"shopifyBtnm" + idsplice[0]} value={this.props.thisprod.shopifybtn_v}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"VarientePriceCSm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">V Price CAD: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"VarientePriceCSm" + idsplice[0]} value={this.props.thisprod.price_cad_v}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"VarientePriceUSm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">V Price USD: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"VarientePriceUSm" + idsplice[0]} value={this.props.thisprod.price_usd_v}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"VarienteWeightSm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Weight: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"VarienteWeightSm" + idsplice[0]} value={this.props.thisprod.ship_weight_v}/>
                                        </div>
                                    </div>
                                    <div className="form-group row mb-1">
                                        <label for={"VarienteImgSm" + idsplice[0]} className="col-sm-3 col-form-label font-weight-bold">Image: </label>
                                        <div className="col-sm-9">
                                        <input type="text" readonly className="form-control-plaintext" id={"VarienteImgSm" + idsplice[0]} value={this.props.thisprod.img_v}/>
                                        </div>
                                    </div>

                                    </form>
                            </div> : <div>
                            <form id={'update_consskum' + self.props.thisprod.id} action='/admin/update_conssku' enctype="multipart/form-data" method="post" onSubmit={this._onSubmit}>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Unique ID</label>
            <div className='col-sm-10 col-lg-6'>
            <input name='id' type="text" className="form-control form-control-sm" id="InputID1peS" aria-describedby="idHelp" placeholder="Enter ID" value={this.state.prod_id} readonly/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>English Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameEn} value={self.state.prod_name_en} name='name_en' type="text" className="form-control form-control-sm" id="InputENpepS" aria-describedby="enHelp" placeholder="Enter English Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>French Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameFr} value={self.state.prod_name_fr} name='name_fr' type="text" className="form-control form-control-sm" id="InputFRpepS" aria-describedby="frHelp" placeholder="Enter French Name" required/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Shopify Button</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeshopify} value={self.state.prod_shopifybtn} name='shopifybtn' type="text" className="form-control form-control-sm" id="InputShopifyBtn" aria-describedby="InputShopifyBtn" placeholder="Enter Button ID" required/>
            </div>
        </div>
        <hr className='my-1'></hr>
        <div className="form-group row">
          <label className='col-sm-2 col-form-label font-weight-bold'>Product</label>
          <select name='category' className="col-sm-10 col-lg-6 form-control form-control-sm">
            <option>None</option>
            {
              self.props.products.map(thisprod=>{
                return <option selected={thisprod.id === self.props.thisprod.product ? true:false}>{thisprod.id}</option>
              })
            }
          </select>
        </div>
        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_ca} type="checkbox" name='show_ca' className="custom-control-input" id="visiblepCANepS" checked={this.state.prod_show_ca === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepCANepS">Show Can</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_us} type="checkbox" name='show_us' className="custom-control-input" id="visiblepUSepS" checked={this.state.prod_show_us === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepUSepS">Show US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_show_gl} type="checkbox" name='show_gl' className="custom-control-input" id="visiblepGLepS" checked={this.state.prod_show_gl === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="visiblepGLepS">Show Global</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_sale_ca} type="checkbox" name='sale_ca' className="custom-control-input" id="saleepS" checked={this.state.prod_sale_ca === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="saleepS">Sale CAN</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_sale_us} type="checkbox" name='sale_us' className="custom-control-input" id="saleUepS" checked={this.state.prod_sale_us === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="saleUepS">Sale US</label>
        </div>
        <div className="custom-control custom-switch custom-control-inline">
            <input onClick={this._toggle_sale_gl} type="checkbox" name='sale_gl' className="custom-control-input" id="saleGepS" checked={this.state.prod_sale_gl === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="saleGepS">Sale Global</label>
        </div>
        <hr className='my-1'></hr>

        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Sort Order</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={this._ChangeOrder}type="number" name='sort' className="form-control form-control-sm" id="InputID2epS" aria-describedby="idHelp" placeholder='0' value={this.state.prod_order}/>
            </div>
        </div>

        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Price CAD</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangePriceC} value={self.state.prod_price_cad} name='price_cad' type="number" min='0' step='0.01' className="form-control form-control-sm" id="InputPRCpepS" aria-describedby="frHelp" placeholder="Enter Price CAD" required/>
            </div>
        </div>

        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Price USD</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangePriceU} value={self.state.prod_price_usd} name='price_usd' type="number" min='0' step='0.01' className="form-control form-control-sm" id="InputPRUpepS" aria-describedby="frHelp" placeholder="Enter Price USD" required/>
            </div>
        </div>

        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Shipping Weight (lbs)</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeShip} value={self.state.prod_ship_weight} name='ship_weight' type="number" min='0' step='0.01' className="form-control form-control-sm" id="InputSWpepS" aria-describedby="frHelp" placeholder="Enter Shipping Weight" required/>
            </div>
        </div>
        
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='image' className="form-control-file form-control-sm"/>
            </div>
            <div className='col-12 text-bold'>{this.props.thisprod.img}</div>
        </div>

        <hr className='my-1'></hr>
        <div className="custom-control custom-switch custom-control-inline">
            <input type="checkbox" name='var_opt' className="custom-control-input" id="varientm" onClick={self._toggle_var_opt} checked={this.state.prod_var_opt === true ? 'checked' : ''}/>
            <label className="custom-control-label" htmlFor="varientm">Varient</label>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Primary Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameV1} name='name_v1' type="text" className="form-control form-control-sm" id="Inputv1m" aria-describedby="enHelp" placeholder="Dropdown Option1" value={this.state.prod_name_v1} />
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient Name</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameV2} name='name_v2' type="text" className="form-control form-control-sm" id="Inputv2m" aria-describedby="enHelp" placeholder="Dropdown Option2" value={this.state.prod_name_v2}/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Primary Name FR</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameV1fr} name='name_v1_fr' type="text" className="form-control form-control-sm" id="Inputv1mf" aria-describedby="enHelp" placeholder="Dropdown Option1" value={this.state.prod_name_v1_fr} />
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient Name FR</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeNameV2fr} name='name_v2_fr' type="text" className="form-control form-control-sm" id="Inputv2mf" aria-describedby="enHelp" placeholder="Dropdown Option2" value={this.state.prod_name_v2_fr}/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient SKU</label>
            <div className='col-sm-10 col-lg-6'>
                <input  onChange={self._onChangeVSKU} name='var_id' type="text" className="form-control form-control-sm" id="Inputvsidm" aria-describedby="enHelp" placeholder="Input Varient SKU" value={this.state.prod_var_id}/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>V Shopify Button</label>
            <div className='col-sm-10 col-lg-6'>
                <input onChange={self._onChangeshopifyV} value={self.state.prod_shopifybtn_v } name='shopifybtn_v' type="text" className="form-control form-control-sm" id="InputShopifyBtn" aria-describedby="InputShopifyBtn" placeholder="Enter Button ID" required/>
            </div>
        </div>

        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>V Price CAD</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={self._onChangePriceCV} name='price_cad_v' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputSKUPriceCAvm" aria-describedby="idHelp" placeholder="Enter CAD" value={this.state.prod_price_cad_v}/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>V Price USD</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={self._onChangePriceUV} name='price_usd_v' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputSKUPriceUSvm" aria-describedby="idHelp" placeholder="Enter USD" value={this.state.prod_price_usd_v}/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Shipping Weight (lbs)</label>
            <div className='col-sm-10 col-lg-6'>
            <input onChange={self._onChangeShipV} name='ship_weight_v' type="number" min="0" step="0.01"  className="form-control form-control-sm" id="InputShipWeightvm" aria-describedby="idHelp" placeholder="Enter LBS"  value={this.state.prod_ship_weight_v}/>
            </div>
        </div>
        <div className="form-group row">
            <label className='col-sm-2 col-form-label font-weight-bold'>Varient Image</label>
            <div className='col-sm-10 col-lg-6'>
                <input type="file" name='img_v' className="form-control-file form-control-sm"/>
            </div>
            <div className='col-12 text-bold'>{this.props.thisprod.img_v}</div>
        </div>    

        <hr className='my-1'></hr>
        <button type="submit" className="btn btn-primary">Submit Updates</button>
        </form>
        <hr className='my-1'></hr>

                            </div>
                              }
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
        )
    }
}